import { useEffect } from 'react';
import { ReactElement } from 'react';
import PropTypes from 'prop-types';

import { FormControl } from '@mui/material';
import Icon from '@mui/icons-material/FileUpload';

import {
    Button,
    useNotify,
    useTranslate
} from 'react-admin';

import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Utils } from 'admin/core';

interface Props {
    icon?: ReactElement;
    label?: string;
}

export type SimpleFileUploadButtonProps = Props & DropzoneOptions;

const SimpleFileUploadButton = (
    props: SimpleFileUploadButtonProps
) => {
    const {
        icon = defaultIcon,
        label = 'ra.action.upload',
        ...rest
    } = props;

    const notify = useNotify();
    const translate = useTranslate();

    const acceptedExtensions = ['text/csv'];
    const maxSize = 1048576;

    const { fileRejections, getInputProps, open } = useDropzone({
        accept: acceptedExtensions,
        maxSize,
        noClick: true,
        noKeyboard: true,
        ...rest
    });

    useEffect(() => {
        if (fileRejections.length > 0) {
            let message: string;

            switch (fileRejections[0]?.errors[0]?.code) {
                case "file-invalid-type":
                    message = translate(`pos.fileupload.errors.${fileRejections[0]?.errors[0]?.code}`, { type: acceptedExtensions.join('|') })
                    break;
                case "file-too-large":
                    message = translate(`pos.fileupload.errors.${fileRejections[0]?.errors[0]?.code}`, { size: Utils.FormatBytes(maxSize) })
                    break;
                default:
                    message = fileRejections[0]?.errors[0]?.message;
                    break;
            }

            notify(message, { type: 'warning' })
        }
    }, [fileRejections]);

    return (
        <FormControl sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
            <input {...getInputProps()} />
            <Button startIcon={<Icon />} label={label} onClick={open} />
        </FormControl>
    );
};

const defaultIcon = <Icon />;

SimpleFileUploadButton.propTypes = {
    icon: PropTypes.element,
    label: PropTypes.string,
};

export default SimpleFileUploadButton;
