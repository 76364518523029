import { Utils } from 'admin/core';

export const transformData = async data => {
    if (data) {
        delete data.miurSchoolType;
        delete data.miurSchoolsId;

        if (data.fiscalCodeUrl && Utils.IsObject(data.fiscalCodeUrl)) {
            if (data.fiscalCodeUrl.rawFile instanceof File) {
                let res = await Utils.ConvertFileToBase64({ contentData: { ...data.profilePhotoNavigation } });
                data.fiscalCodeUrl = res.src;
            }
            else if (Utils.IsObject(data.fiscalCodeUrl)) {
                data.fiscalCodeUrl = data.fiscalCodeUrl.src;
            }
        }

        if (data.fiscalCodeUrl === null) {
            data.fiscalCodeUrl = "";
        }
    }

    return { ...data }
};