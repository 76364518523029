import { ChangeEvent, memo, useMemo } from 'react';
import { SxProps } from '@mui/system';
import { useTranslate, useListFilterContext, OptionText, RaRecord } from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';

import { ReferenceInput, ReferenceInputProps, SelectInput } from 'react-admin';

export const ReferenceSelectInput = memo((props: ReferenceSelectInputProps) => {
    const { filterValues, setFilters } = useListFilterContext();
    const translate = useTranslate();

    const {
        source = 'q',
        reference,
        filter,
        sort,
        page,
        perPage,
        label = translate('ra.action.search'),
        placeholder,
        optionValue,
        optionText,
        translateChoice,
        ...rest
    } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement> | RaRecord) => {
        if (event && event.target) {
            setFilters({ ...filterValues, [source]: event.target.value }, null);
        } else {
            const { [source]: _, ...filters } = filterValues;
            setFilters(filters, null, false);
        }
    };

    const initialValues = useMemo(
        () => ({
            [source]: filterValues[source],
        }),
        [filterValues, source]
    );

    const form = useForm({ defaultValues: initialValues });

    const onSubmit = e => {
        e.preventDefault();
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit}>
                <ReferenceInput source={source} reference={reference} filter={filter} sort={sort} page={page} perPage={perPage}>
                    <SelectInput
                        label={rest.hiddenLabel ? false : label}
                        optionValue={optionValue}
                        optionText={optionText}
                        translateChoice={translateChoice}
                        fullWidth
                        onChange={handleChange}
                        helperText={false}
                        placeholder={
                            placeholder ?? (rest.hiddenLabel ? label : undefined)
                        }
                    />
                </ReferenceInput>
            </form>
        </FormProvider>
    );
});

export interface ReferenceSelectInputProps extends Omit<ReferenceInputProps, 'source'> {
    source?: string;
    sx?: SxProps;
    label?: string;
    fullWidth?: boolean;
    variant?: 'filled' | 'outlined';
    optionValue?: string;
    optionText?: OptionText;
    translateChoice?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement> | RaRecord) => void;
}
