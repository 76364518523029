import { Grid, Divider, Stack, Typography } from '@mui/material';

import {
    Edit,
    FunctionField,
    NumberInput,
    ReferenceField,
    SimpleForm,
    TextInput,
    WithRecord,
} from 'react-admin';

import { CheckboxInput, EditActions, EditToolbar } from 'admin/components';

import { BookingItemsDataGrid } from 'admin/views/Common';

import CustomTitle from './CustomTitle';

import { transformData } from './transformData';

import AddBookingItem from './AddBookingItem';
import CloseableButton from './CloseableButton';
import { BookingType } from 'admin/types';
import { Utils } from 'admin/core';
import { mapStudentDisplayName } from 'admin/mappers';

const CustomEdit = () => {
    return (
        <Edit redirect="list" mutationMode="pessimistic" actions={<EditActions />} title={<CustomTitle />} transform={transformData}>
            <SimpleForm toolbar={<EditToolbar saveAlwaysEnable />} >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={0}>
                            <ReferenceField source="studentMiurYearCourseId" reference="studentmiuryearcourses">
                                <ReferenceField source="studentId" reference="students">
                                    <FunctionField render={record => <Typography variant="h3" textAlign='center' gutterBottom>{mapStudentDisplayName(record)}</Typography>} />
                                </ReferenceField>
                                <FunctionField render={record => <Typography variant="h5" textAlign='center' gutterBottom>{record.code}</Typography>} />
                            </ReferenceField>
                            <FunctionField render={record => <Typography variant="h5" textAlign='center' gutterBottom>{Utils.FormatToDate(record.orderDate)}</Typography>} />
                            <CloseableButton iconProps={{ fontSize: 'large' }} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={2} />
                    <Grid container item xs={12} sm={4} spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <TextInput source="note" multiline rows={5} maxRows={5} fullWidth />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Stack direction="column" justifyContent="flex-start" alignItems="flex-end" spacing={0} >
                                <WithRecord label="" render={record => <NumberInput source="advanceAmount" disabled={record.cannotRemove || record.closedAt} min={0} />} />
                                <WithRecord label="" render={record => <CheckboxInput labelOptions={{ labelPlacement: "start" }} source="religion" disabled={record.cannotRemove || record.closedAt} fullWidth />} />
                                <WithRecord label="" render={record => <CheckboxInput labelOptions={{ labelPlacement: "start" }} source="covered" disabled={record.cannotRemove || record.closedAt} fullWidth />} />
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: theme => theme.spacing(2), paddingRight: 0 }}>
                        <Divider flexItem textAlign='center'>
                            <WithRecord render={record => <AddBookingItem bookingsId={record.id} bookingType={BookingType.Scolastica} />} />
                        </Divider>
                        <Divider flexItem textAlign='center'>
                            <WithRecord render={record => <AddBookingItem bookingsId={record.id} bookingType={BookingType.Varia} />} />
                        </Divider>
                    </Grid>

                    <Grid item xs={12} sx={{ width: 200 }}>
                        <WithRecord render={record => (
                            <BookingItemsDataGrid buildFilter={(record) => { return { bookingsId: record.id } }} allowEditing={!record?.closedAt} />
                        )} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit >
    )
};

export default CustomEdit;
