import { Box, ButtonProps, lighten, SvgIconProps, Typography, useTheme } from '@mui/material';

import CloseableIcon from '@mui/icons-material/ThumbUpOffAlt';
import ReOpenIcon from '@mui/icons-material/Restore';

import {
    useDataProvider,
    useNotify,
    useRefresh,
    useTranslate,
    useRecordContext,
} from 'react-admin';
import { CustomConfirmButton } from 'admin/components';
import { Utils } from 'admin/core';
import { mapStudentDisplayName } from 'admin/mappers';


const CloseableButton = (props: CloseableButtonProps) => {
    const {
        iconProps
    } = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext(props);
    const translate = useTranslate();
    const theme = useTheme();

    const handleConfirm = () => {
        dataProvider
            .update('bookings', {
                id: record.id,
                data: {
                    closedAt: record?.closedAt ? new Date(2000, 0, 1).toISOString() : new Date().toISOString()
                },
                previousData: record
            })
            .then(response => {
                const { data } = response || { data: null };
                if (data) {
                    refresh();
                }
            })
            .catch(error => {
                console.error(error);
                notify('Error', { type: 'warning' })
            });
    };

    let canChangeStatus: boolean = false;
    let icon: JSX.Element = <></>;
    let color: string = '';
    let label: string = '';
    let confirmLabel: string = '';
    let confirmContent: string = '';
    let statusText: string = '';

    if (record?.closeable) {
        icon = <CloseableIcon color='success' fontSize='small' {...iconProps} />;
        color = lighten(theme.palette.success.light, 0.5);
        label = translate('bookingitems.action.closeable');
        confirmLabel = 'bookingitems.action.confirmDialogClose.title';
        confirmContent = 'bookingitems.action.confirmDialogClose.content';
        statusText = translate('bookings.other.closeable');
        canChangeStatus = true;
    }
    else if (record?.closedAt) {
        icon = <ReOpenIcon color='info' fontSize='small' {...iconProps} />;
        color = lighten(theme.palette.info.light, 0.7);
        label = translate('bookingitems.action.reopen');
        confirmLabel = 'bookingitems.action.confirmDialogReopen.title';
        confirmContent = 'bookingitems.action.confirmDialogReopen.content';
        statusText = translate('bookings.other.closedAt', { date: Utils.FormatToDate(record.closedAt, 'it', true) });
        canChangeStatus = true;
    }
    else {
        statusText = translate('bookingitems.action.notCloseable');
    }

    return (
        <Box display='flex' justifyContent="center" alignItems="center" gap={2}>
            <Typography variant="h6" textAlign='center'>
                {statusText}
            </Typography>
            {
                canChangeStatus &&
                <CustomConfirmButton
                    label={label}
                    icon={icon}
                    color={color}
                    title={confirmLabel}
                    content={confirmContent}
                    translateOptions={{
                        bookingCode: record?.studentMiurYearCourse?.code,
                        studentName: mapStudentDisplayName(record?.studentMiurYearCourse?.student)
                    }}
                    onConfirm={handleConfirm}
                />
            }
        </Box>
    );
};

interface Props {
    iconProps?: SvgIconProps;
}

export type CloseableButtonProps = Props & ButtonProps;

export default CloseableButton;
