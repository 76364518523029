import { Utils } from 'admin/core';

//import __Base__ from './__Base__';
import Students from './Students';
import Bookings from './Bookings';
import BookingItems from './BookingItems';
import Books from './Books';
import EmailBooks from './EmailBooks';

import * as system from './System';
import * as MIUR from './MIUR';

const routes = {
    // Base: {
    //     name: "base", permission: "*", ...__Base__
    // },
    // GROUPED RESOURCES
    //...Utils.BuildResources(Polizze, true, 'tablesPolizze'),

    Bookings: {
        name: "bookings", permission: "admin", ...Bookings
    },

    BookingItems: {
        name: "bookingitems", permission: "admin", ...BookingItems
    },

    EmailBooks: {
        name: "emailbooks", permission: "admin", ...EmailBooks
    },

    Students: {
        name: "students", permission: "admin", ...Students
    },

    Books: {
        name: "books", permission: "admin", ...Books
    },
    
    ...Utils.BuildResources(MIUR, false, 'tablesMIUR'),

    ...Utils.BuildResources(system, false, 'system'),
};

export default routes;