import Book from './Book';

export interface BookAPIConstructor {
    new(key?: string): BookAPI;
}

//search parameters
export enum SearchParameters {
    Title = "intitle:",
    Author = "inauthor:",
    Publisher = "inpublisher:",
    Category = "subject:",
    Isbn = "isbn:",
    LibraryCongressControlNumber = "lccn:",
    OnlineComputerLibraryCenter = "oclc:",
};

//filters
export enum SearchFilters {
    All = " ",
    Partial_preview = "&filter=partial",
    Full_preview = "&filter=full",
    Free = "&filter=free-ebooks",
    Paid = "&filter=paid-ebooks",
    Ebooks = "&filter=ebooks",
};

// order
export enum SearchOrders {
    Relevance = "&orderBy=relevance",
    Newest = "&orderBy=newest",
};

export interface SearchOptions {
    maxResults?: number,
    parameter?: SearchParameters,
    filter?: SearchFilters,
    order?: SearchOrders
}

export default interface BookAPI {
    search(query: string, searchOptions?: SearchOptions): Promise<Book[]>;
}