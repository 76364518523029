import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AdminApp from "./admin";

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/*" element={<AdminApp />} />
        </Routes>
    </BrowserRouter>
);

export default App;
