import { useEffect, useState } from 'react';

import { Typography, Box } from '@mui/material';

import { green } from '@mui/material/colors';

import {
    DataGrid,
    GridColDef,
    GridSortDirection,
    GridSlotsComponentsProps,
    GridSlotsComponent,
    useGridApiRef,
    GridRowSelectionCheckboxParams,
    GridHeaderSelectionCheckboxParams,
} from '@mui/x-data-grid';

import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

import {
    Button,
    Confirm,
    useDataProvider,
    useTranslate
} from 'react-admin';

import { BookingTypeIcon } from 'admin/views/Common';
import { UncapitalizeObjectKeys } from '@mui/x-data-grid/internals';
import { BookingStatusType, BookingType } from 'admin/types';
import { mapStudentDisplayName } from 'admin/mappers';

interface Props {
    sortField?: string;
    sortDirection?: GridSortDirection;
    rows: any[];
    bookingInfos: any;
    reloadDashboard?: () => void;
}

export type ReadyBookingsDataGridProps = Props;

const ReadyBookingsDataGrid = (props: ReadyBookingsDataGridProps) => {
    const {
        sortField = 'titolo',
        sortDirection = 'asc',
        rows = [],
        bookingInfos = {},
        reloadDashboard = () => { },
    } = props;

    const dataProvider = useDataProvider();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [selectedRowsCount, setSelectedRowsCount] = useState<number>(0);
    const [dataGridOptions, setDataGridOptions] = useState<any>({});
    const translate = useTranslate();

    const apiRef = useGridApiRef();

    const handleRowSelectionCheckboxChange = (params: GridRowSelectionCheckboxParams, event) => {
        var items = apiRef.current.getSelectedRows();
        setSelectedRowsCount(items.size);
    }

    const handleHeaderSelectionCheckboxChange = (params: GridHeaderSelectionCheckboxParams) => {
        var items = apiRef.current.getSelectedRows();
        setSelectedRowsCount(items.size);
    }

    useEffect(() => {
        apiRef.current.subscribeEvent('rowSelectionCheckboxChange', handleRowSelectionCheckboxChange);
        apiRef.current.subscribeEvent('headerSelectionCheckboxChange', handleHeaderSelectionCheckboxChange);
    }, [apiRef]);


    const getTotalPrice = () => rows.reduce((total, row) => total + (apiRef.current.isRowSelected(row?.id) ? row?.prezzo : 0), 0);

    useEffect(() => {
        let initialState: GridInitialStateCommunity = {
            sorting: {
                sortModel: [{ field: sortField, sort: sortDirection }],
            },
        };

        let slots: UncapitalizeObjectKeys<Partial<GridSlotsComponent>> = {
            noRowsOverlay: () => (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}>
                    <Typography variant="h6" gutterBottom>
                        {translate("bookings.edit.noBookingitems")}
                    </Typography>
                </Box>
            )
        }

        let slotProps: GridSlotsComponentsProps = {};

        slots.footer = () => {
            return (
                <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }} gutterBottom>
                        {`${getTotalPrice().toFixed(2)} €`}
                    </Typography>
                </Box>
            )
        };

        initialState.filter = {
            filterModel: {
                items: [],
                quickFilterValues: [],
            },
        }

        slots.toolbar = () => (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            </Box>
        );

        slotProps.toolbar = {
            showQuickFilter: true,
        };

        let _dataGridOptions = {
            autoHeight: true,
            checkboxSelection: true,
            disableColumnMenu: true,
            disableColumnFilter: true,
            disableColumnSelector: true,
            disableDensitySelector: true,
            initialState: initialState,
            slots: slots,
            slotProps: slotProps
        }

        setDataGridOptions(_dataGridOptions);
    }, []);

    const handleConfirm = async () => {
        var items = Array.from(apiRef.current.getSelectedRows().values());

        if (items) {
            const updatedItems: any[] = [];

            for await (const item of items) {
                var response = await dataProvider
                    .create('bookingitemhistories', {
                        data: {
                            bookingItemId: item.id,
                            status: BookingStatusType.Completed,
                            dateTime: new Date().toISOString()
                        },
                    });

                if (response) {
                    const { data } = response || { data: null };
                    if (data) {
                        updatedItems.push(data);
                    }
                }
            }

            if (updatedItems.length === items.length) {
                reloadDashboard();
            }
        }
    };

    const getHeaderName = (fieldName, resource = 'bookingitems') => translate(`resources.${resource}.fields.${fieldName}`);

    const columns: GridColDef[] = [
        {
            field: 'codiceIsbn',
            width: 160,
            headerName: getHeaderName('codiceIsbn'),
            valueGetter: (params) => params.row?.codiceIsbn
        },
        {
            field: 'titolo',
            flex: 2,
            headerName: getHeaderName('titolo'),
            valueGetter: (params) => params.row?.titolo
        },
        {
            field: 'sottotitolo',
            flex: 1,
            headerName: getHeaderName('sottotitolo'),
            valueGetter: (params) => params.row?.sottotitolo
        },
        {
            field: 'autori',
            flex: 1,
            headerName: getHeaderName('autori'),
            valueGetter: (params) => params.row?.autori
        },
        {
            field: 'editore',
            flex: 1,
            headerName: getHeaderName('editore'),
            valueGetter: (params) => params.row?.editore
        },
        {
            field: 'otherInfo',
            flex: 1,
            headerName: getHeaderName('otherInfo'),
            valueGetter: (params) => params.row?.bookingType === BookingType.Scolastica ? params.row?.disciplina : params.row?.categorie
        },
        {
            field: 'bookingType',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            headerName: getHeaderName('bookingType'),
            renderCell: (params) => <BookingTypeIcon value={params.row?.bookingType} />
        },
        {
            field: 'statusDateTime',
            type: 'dateTime',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerName: getHeaderName('statusDateTime'),
            valueGetter: (params) => params.row?.statusDateTime ? new Date(params.row?.statusDateTime) : null
        },
        {
            field: 'prezzo',
            width: 100,
            headerAlign: 'right',
            align: 'right',
            headerName: getHeaderName('prezzo'),
            valueGetter: (params) => `${params.row?.prezzo.toFixed(2)} €`
        },
    ];

    return (
        <>
            <DataGrid
                apiRef={apiRef}
                rows={rows}
                columns={columns}
                density={'compact'}
                hideFooterPagination={true}
                {...dataGridOptions}
                getRowClassName={(params) => `my-datagrid`}
                sx={{
                    minWidth: 800,
                    "& .my-datagrid.Mui-selected": {
                        backgroundColor: green[200],
                        color: theme => theme.palette.getContrastText(green[200]),
                        "&:hover": {
                            backgroundColor: green[300]
                        },
                        "& .MuiCheckbox-root.Mui-checked": {
                            color: '#fff'
                        }
                    },
                    '& .my-datagrid .MuiDataGrid-cell:focus': {
                        outline: 'none !important'
                    }
                }}
            />

            <Box mt={1} textAlign="center">
                <Button variant='contained' color='success' disabled={selectedRowsCount === 0} label="ra.action.confirm" onClick={() => setShowConfirm(true)} />
            </Box>

            <Confirm
                isOpen={showConfirm}
                title={translate('bookingitems.action.confirmDialogReady.title')}
                content={translate('bookingitems.action.confirmDialogReady.content', {
                    smart_count: selectedRowsCount,
                    code: `[${bookingInfos.studentMiurYearCourse.code}] - ${mapStudentDisplayName(bookingInfos.studentMiurYearCourse.student)}`
                })}
                onConfirm={handleConfirm}
                onClose={() => setShowConfirm(false)}
            />
        </>
    );
};

export default ReadyBookingsDataGrid;
