import { ButtonProps, Box, Tooltip, alpha, useTheme, darken } from '@mui/material';

import { green, blue, red, orange, grey, yellow } from '@mui/material/colors';

import ArrowBack from '@mui/icons-material/ArrowBack';
import AppsIcon from '@mui/icons-material/Apps';

import { Utils } from 'admin/core';

import {
    CreateButton as RaCreateButton,
    CreateButtonProps as RaCreateButtonProps,
    EditButton as RaEditButton,
    EditButtonProps as RaEditButtonProps,
    ShowButton as RaShowButton,
    ShowButtonProps as RaShowButtonProps,
    SaveButton as RaSaveButton,
    SaveButtonProps as RaSaveButtonProps,
    DeleteWithConfirmButton as RaDeleteWithConfirmButton,
    DeleteWithConfirmButtonProps as RaDeleteWithConfirmButtonProps,
    ListButton as RaListButton,
    ListButtonProps as RaListButtonProps,
    CloneButton as RaCloneButton,
    CloneButtonProps as RaCloneButtonProps,
    ExportButton as RaExportButton,
    ExportButtonProps as RaExportButtonProps,
    usePermissions,
    useRecordContext,
    useTranslate,
    useResourceContext,
    Button,
} from 'react-admin';

import {
    BackButton as MyBackButton,
    BackButtonProps as MyBackButtonProps
} from "./BackButton";
import {
    SoftDeleteWithConfirmButton as MySoftDeleteWithConfirmButton,
    SoftDeleteWithConfirmButtonProps as MySoftDeleteWithConfirmButtonProps
} from "./SoftDeleteWithConfirmButton";
import { ActionRBAC, useCanAccess, useIsCreateOrEdit } from '../rbac';
import { useCallback } from 'react';
import ConfirmButton, { ConfirmButtonProps } from './ConfirmButton';

interface Props {
    label?: string;
    color?: string;
    withText?: boolean;
    disabled?: boolean;
    disabledFn?: ((record: any, permission: string) => boolean) | null;
}

const TooltipButton = (
    props: Props & Omit<ButtonProps, "color">
) => {
    const theme = useTheme();
    const { permissions } = usePermissions();
    const record = useRecordContext();
    const translate = useTranslate();

    const {
        label = "",
        color = theme.palette.primary.main,
        withText = false,
        sx: sxProps,
        children,
        disabled = false,
        disabledFn = null,
    } = props;

    let isDisabled: boolean = disabledFn !== null ? disabledFn(record, permissions) : disabled;

    const finalProps = {
        disabled: isDisabled,
        label: withText ? label : "",
        sx: {
            ...sxProps,
            color: withText ? theme.palette.getContrastText(color) : color,
            "&.Mui-disabled .MuiSvgIcon-root": {
                color: alpha(theme.palette.common.black, 0.26)
            },
            px: withText ? theme.spacing(1) : 0,
            minWidth: theme.spacing(4)
        }
    } as ButtonProps

    if (!withText) {
        return (
            <Tooltip title={translate(label)}>
                <Box>
                    {Utils.CreateOrCloneElement(children, finalProps)}
                </Box>
            </Tooltip>
        );
    }
    else {
        finalProps.variant = 'contained';
        finalProps.sx = {
            ...finalProps.sx,
            color: theme.palette.getContrastText(color),
            backgroundColor: color,
            '&:hover': {
                backgroundColor: darken(color, 0.2),
            },
            "&.Mui-disabled .MuiSvgIcon-root": {
                color: alpha(theme.palette.common.black, 0.26)
            }
        };

        return (
            <Box>
                {Utils.CreateOrCloneElement(children, finalProps)}
            </Box>
        )
    }
};

export const CreateButton = (
    props: Props & Omit<RaCreateButtonProps, "color">
) => {
    const {
        label = "ra.action.create",
        color = green[700],
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        ...rest
    } = props;

    const { loaded, canAccess } = useCanAccess();
    const resource = useResourceContext();

    if (!loaded || !canAccess(resource, ActionRBAC.Write) || !canAccess(resource, ActionRBAC.Create)) {
        return null;
    }

    return (
        <TooltipButton
            label={label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <RaCreateButton {...rest} />
        </TooltipButton>
    );
};

export const EditButton = (
    props: Props & Omit<RaEditButtonProps, "color">
) => {
    const {
        label = "ra.action.edit",
        color = '#FFC83D',
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        ...rest
    } = props;

    const { loaded, canAccess } = useCanAccess();
    const resource = useResourceContext();

    if (!loaded || !canAccess(resource, ActionRBAC.Write) || !canAccess(resource, ActionRBAC.Edit)) {
        return null;
    }

    return (
        <TooltipButton
            label={label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <RaEditButton {...rest} />
        </TooltipButton>
    );
};

export const ShowButton = (
    props: Props & Omit<RaShowButtonProps, "color">
) => {
    const {
        label = "ra.action.show",
        color = '#0078D4',
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        ...rest
    } = props;

    const { loaded, canAccess } = useCanAccess();
    const resource = useResourceContext();

    if (!loaded || !canAccess(resource, ActionRBAC.Read) || !canAccess(resource, ActionRBAC.Show)) {
        return null;
    }

    return (
        <TooltipButton
            label={label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <RaShowButton {...rest} />
        </TooltipButton>
    );
};

export const SaveButton = (
    props: Props & Omit<RaSaveButtonProps, "color">
) => {
    const {
        label = "ra.action.save",
        color = blue[600],
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        ...rest
    } = props;

    const { loaded, canAccess } = useCanAccess();
    const { action } = useIsCreateOrEdit();
    const resource = useResourceContext();

    if (!loaded || !canAccess(resource, ActionRBAC.Write) || (action && !canAccess(resource, action))) {
        return null;
    }

    return (
        <TooltipButton
            label={label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <RaSaveButton {...rest} />
        </TooltipButton>
    );
};

export const DeleteWithConfirmButton = (
    props: Props & Omit<RaDeleteWithConfirmButtonProps, "color">
) => {
    const {
        label = "ra.action.delete",
        color = red[500],
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        ...rest
    } = props;

    const { loaded, canAccess } = useCanAccess();
    const resource = useResourceContext();

    if (!loaded || !canAccess(resource, ActionRBAC.Write)) {
        return null;
    }

    return (
        <TooltipButton
            label={label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <RaDeleteWithConfirmButton {...rest} />
        </TooltipButton>
    );
};

export const SoftDeleteWithConfirmButton = (
    props: Props & Omit<MySoftDeleteWithConfirmButtonProps, "color">
) => {
    const {
        label = 'ra.action.soft_delete',
        labelRestore = 'ra.action.restore',
        color = red[500],
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        toRestore = false,
        toRestoreFn = null,
        ...rest
    } = props;

    const { loaded, canAccess } = useCanAccess();
    const resource = useResourceContext();
    const record = useRecordContext();

    if (!loaded || !canAccess(resource, ActionRBAC.Write)) {
        return null;
    }

    const isToRestore: boolean = toRestoreFn !== null ? toRestoreFn(record) : toRestore;

    return (
        <TooltipButton
            label={isToRestore ? labelRestore : label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <MySoftDeleteWithConfirmButton {...rest} toRestore={toRestore} toRestoreFn={toRestoreFn} />
        </TooltipButton>
    );
};

export const BackButton = (
    props: Props & Omit<MyBackButtonProps, "color">
) => {
    const {
        label = "ra.action.back",
        color = grey[700],
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        ...rest
    } = props;

    return (
        <TooltipButton
            label={label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <MyBackButton {...rest} />
        </TooltipButton>
    );
};

export const ListButton = (
    props: Props & Omit<RaListButtonProps, "color">
) => {
    const {
        label = "ra.action.list",
        color = yellow['800'],
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        ...rest
    } = props;

    const { loaded, canAccess } = useCanAccess();
    const resource = useResourceContext();

    if (!loaded || !canAccess(resource, ActionRBAC.Read) || !canAccess(resource, ActionRBAC.List)) {
        return null;
    }

    return (
        <TooltipButton
            label={label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <RaListButton {...rest} icon={<ArrowBack />} />
        </TooltipButton>
    );
};

export const CloneButton = (
    props: Props & Omit<RaCloneButtonProps, "color">
) => {
    const {
        label = "ra.action.clone",
        color = orange[500],
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        ...rest
    } = props;

    const { loaded, canAccess } = useCanAccess();
    const { action } = useIsCreateOrEdit();
    const resource = useResourceContext();

    if (!loaded || !canAccess(resource, ActionRBAC.Write) || (action && !canAccess(resource, action))) {
        return null;
    }

    return (
        <TooltipButton
            label={label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <RaCloneButton {...rest} />
        </TooltipButton>
    );
};

export const ExportButton = (
    props: Props & Omit<RaExportButtonProps, "color">
) => {
    const {
        label = "ra.action.export",
        color = orange[700],
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        ...rest
    } = props;

    const { loaded, canAccess } = useCanAccess();
    const resource = useResourceContext();

    if (!loaded || !canAccess(resource, ActionRBAC.Export)) {
        return null;
    }

    return (
        <TooltipButton
            label={label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <RaExportButton {...rest} />
        </TooltipButton>
    );
};

export const CustomButton = (
    props: Props & { onClick?: (record: any) => void; icon?: JSX.Element; iconFn?: (record: any) => JSX.Element; } & Omit<ButtonProps, "color">
) => {
    const {
        label = "ra.action.export",
        color = grey[700],
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        onClick,
        icon = <AppsIcon />,
        iconFn = null,
        ...rest
    } = props;

    const { loaded, canAccess } = useCanAccess();
    const resource = useResourceContext();
    const record = useRecordContext();

    let finalIcon: JSX.Element = iconFn !== null ? iconFn(record) : icon;

    const handleClick = useCallback(
        event => {
            if (typeof onClick === 'function') {
                event.stopPropagation();
                onClick(record);
            }
        },
        [onClick, record]
    );

    if (!loaded || !canAccess(resource, ActionRBAC.Export)) {
        return null;
    }

    return (
        <TooltipButton
            label={label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <Button
                onClick={handleClick}
                label={label}
                {...rest}
            >
                {finalIcon}
            </Button>
        </TooltipButton>
    );
};

export const CustomConfirmButton = (
    props: Props & ConfirmButtonProps & { icon?: JSX.Element; iconFn?: (record: any) => JSX.Element; } & Omit<ButtonProps, "color">
) => {
    const {
        label = "ra.action.export",
        color = grey[700],
        withText = false,
        sx,
        disabled = false,
        disabledFn = null,
        icon = <AppsIcon />,
        iconFn = null,
        ...rest
    } = props;

    const { loaded, canAccess } = useCanAccess();
    const resource = useResourceContext();
    const record = useRecordContext();

    let finalIcon: JSX.Element = iconFn !== null ? iconFn(record) : icon;

    if (!loaded || !canAccess(resource, ActionRBAC.Export)) {
        return null;
    }

    return (
        <TooltipButton
            label={label}
            color={color}
            withText={withText}
            sx={sx}
            disabled={disabled}
            disabledFn={disabledFn}
        >
            <ConfirmButton
                label={label}
                icon={finalIcon}
                color={color}
                {...rest}
            />
        </TooltipButton>
    );
};