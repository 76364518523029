import { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { Divider, Typography, Grid, Box, Tooltip, IconButton, useTheme } from '@mui/material';

import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SetAmountIcon from '@mui/icons-material/CurrencyExchange';

import {
    DataGrid,
    GridActionsCellItem,
    GridColDef,
    GridSortDirection,
    GridToolbarQuickFilter,
    GridSlotsComponentsProps,
    GridSlotsComponent,
    GridRowSelectionModel,
} from '@mui/x-data-grid';

import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

import {
    Loading,
    useDataProvider,
    useDelete,
    useLoading,
    useRecordContext,
    useRefresh,
    useTranslate
} from 'react-admin';

import { BookingStatusTypeIcon, BookingTypeIcon } from 'admin/views/Common';
import { UncapitalizeObjectKeys } from '@mui/x-data-grid/internals';
import { CustomConfirmButton, Dialog } from 'admin/components';
import { BookingStatusType, BookingType } from 'admin/types';
import { mapStudentDisplayName } from 'admin/mappers';

interface Props {
    source?: string;
    filter?: any;
    sortField?: string;
    sortDirection?: GridSortDirection;
    buildFilter?: (record: any) => any,
    hideTotal?: boolean;
    showFilter?: boolean;
    showStudentCode?: boolean;
    allowEditing?: boolean;
}

export type BookingItemsDataGridProps = Props;

const getAllowedNewStatus = (row) => ({
    ToBook: [BookingStatusType.NotToBook],
    NotToBook: [BookingStatusType.ToBook],
    Booked: [BookingStatusType.PickedUp],
    PickedUp: row?.bookings?.bookingType === BookingType.Varia ? [BookingStatusType.ToCover, BookingStatusType.Ready] : row?.bookings?.covered ? [BookingStatusType.ToCover] : [BookingStatusType.Ready],
    ToCover: [BookingStatusType.PickedUp, BookingStatusType.Ready],
    Ready: [BookingStatusType.PickedUp, BookingStatusType.ToCover, BookingStatusType.Completed],
    Completed: [BookingStatusType.Ready],
})

const getNextNewStatus = (row) => ({
    ToBook: [BookingStatusType.NotToBook],
    NotToBook: [BookingStatusType.ToBook],
    Booked: [BookingStatusType.PickedUp],
    PickedUp: row?.bookings?.bookingType === BookingType.Varia ? [BookingStatusType.Ready] : row?.bookings?.covered ? [BookingStatusType.ToCover] : [BookingStatusType.Ready],
    ToCover: [BookingStatusType.Ready],
    Ready: [BookingStatusType.Completed],
    Completed: [],
})

const BookingItemsDataGrid = (props: BookingItemsDataGridProps) => {
    const {
        source,
        filter = {},
        sortField = 'disciplina',
        sortDirection = 'asc',
        buildFilter,
        hideTotal = false,
        showFilter = false,
        showStudentCode = false,
        allowEditing = false
    } = props;

    const [rows, setRows] = useState<any>([]);

    const record = useRecordContext();
    const formContext = useFormContext();
    const dataProvider = useDataProvider();
    const [deleteOne, { isSuccess }] = useDelete();
    const refresh = useRefresh();
    const isLoading = useLoading();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [newStatus, setNewStatus] = useState<any>(null);
    const [dataGridOptions, setDataGridOptions] = useState<any>({});
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [filterModel, setFilterModel] = useState({
        items: [],
        quickFilterValues: [],
    });

    const translate = useTranslate();
    const theme = useTheme();

    useEffect(() => {
        const getTotalPrice = () => rows.reduce((total, row) => total + (row?.currentStatus !== BookingStatusType.NotToBook ? row?.prezzo : 0), 0);

        let initialState: GridInitialStateCommunity = {
            sorting: {
                sortModel: [{ field: sortField, sort: sortDirection }],
            },
        };

        let slots: UncapitalizeObjectKeys<Partial<GridSlotsComponent>> = {
            noRowsOverlay: () => (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}>
                    <Typography variant="h6" gutterBottom>
                        {translate("bookings.edit.noBookingitems")}
                    </Typography>
                </Box>
            )
        }

        let slotProps: GridSlotsComponentsProps = {};

        if (!hideTotal) {
            slots.footer = () => {
                const total = getTotalPrice().toFixed(2);
                return (
                    <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }} gutterBottom>
                            {`${total} €`}
                        </Typography>
                        {
                            (!record.cannotRemove && !record.closedAt) &&
                            <Tooltip title={translate('resources.bookings.fields.advanceAmount')}>
                                <IconButton onClick={() => formContext?.setValue('advanceAmount', total)}>
                                    <SetAmountIcon color='success' />
                                </IconButton>
                            </Tooltip>
                        }

                    </Box>
                )
            };
        }

        if (showFilter) {
            initialState.filter = {
                filterModel: {
                    items: [],
                    quickFilterValues: [],
                },
            }

            slots.toolbar = () => (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GridToolbarQuickFilter placeholder={translate("ra.action.search")} />
                </Box>
            );

            slotProps.toolbar = {
                showQuickFilter: true,
            };
        }

        let _dataGridOptions = {
            autoHeight: true,
            disableRowSelectionOnClick: true,
            disableColumnMenu: true,
            disableColumnFilter: true,
            disableColumnSelector: true,
            disableDensitySelector: true,
            initialState: initialState,
            slots: slots,
            slotProps: slotProps,
            // checkboxSelection: !readonly,
            rowSelectionModel: rowSelectionModel,
            onRowSelectionModelChange: (newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
            },
            filterModel: filterModel,
            onFilterModelChange: setFilterModel
        }

        setDataGridOptions(_dataGridOptions);
    }, [hideTotal, rows, rowSelectionModel, showFilter, sortDirection, sortField, translate, formContext, filterModel, record?.cannotRemove, record?.closedAt]);

    const getData = () => {
        let _filter = filter;

        if (buildFilter) {
            _filter = buildFilter(record);
        }
        else if (record && source) {
            _filter = { [source]: record[source] };
        }

        if (_filter) {
            const expand = [
                'bookings.studentMiurYearCourse.miurYearCourses.miurSchools',
                'bookings.studentMiurYearCourse.student'
            ];
            dataProvider.getList(
                'bookingitems',
                {
                    pagination: { page: 1, perPage: 2000 },
                    sort: { field: 'updatedAt', order: 'DESC' },
                    filter: _filter,
                    meta: { expand: expand }
                })
                .then(({ data }) => {
                    setRows(data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setError(error);
                    setLoading(false);
                })
        }
        else
            setLoading(false);
    }

    useEffect(() => {
        if (!isLoading || (isLoading && !loading)) {
            getData();
        }
    }, [isLoading]);

    useEffect(() => {
        if (isSuccess)
            getData();
    }, [isSuccess]);

    const cleanUp = () => {
        setSelectedRow(null);
        setNewStatus(null);
    }

    useEffect(() => {
        const {
            quickFilterValues
        } = filterModel;

        if (quickFilterValues.length === 1) {
            const _rows = rows.filter(x => x.codiceIsbn === quickFilterValues[0]);
            if (_rows.length > 0) {
                setTimeout(() => {
                    var validRow = _rows.sort((a, b) => a.statusDateTime - b.statusDateTime)[0];
                    const allowedNewStatus = getNextNewStatus(validRow);
                    let nextStatus = allowedNewStatus[validRow?.currentStatus];

                    setSelectedRow(validRow);

                    if (nextStatus.length > 0 && nextStatus[0] === BookingStatusType.ToCover) {
                        nextStatus = allowedNewStatus[nextStatus[0]];
                    }

                    if (nextStatus.length > 0 && nextStatus[0] !== BookingStatusType.ToCover) {
                        setNewStatus(nextStatus[0]);
                        setShowDetails(true);
                    }
                }, 1000);
            }
            else {
                setSelectedRow(null);
                setNewStatus(null);
            }
        }
    }, [filterModel])

    const moveToNewStatus = (id, status) => {
        if (id && status) {
            dataProvider
                .create('bookingitemhistories', {
                    data: {
                        bookingItemId: id,
                        status: status,
                        dateTime: new Date().toISOString()
                    },
                })
                .then(response => {
                    const { data } = response || { data: null };
                    if (data) {
                        getData();
                    }
                })
                .finally(() => {
                    refresh();
                });
        }
        else
            cleanUp();
    };

    const getHeaderName = (fieldName, resource = 'bookingitems') => translate(`resources.${resource}.fields.${fieldName}`);

    const handleDetailsOpen = () => {
        if (!showDetails) {
            setShowDetails(true);
        }
    };

    const handleDetailsClose = () => {
        if (showDetails) {
            setShowDetails(false);
            cleanUp();
            setFilterModel({
                items: [],
                quickFilterValues: [],
            })
        }
    };

    const handleConfirm = () => {
        if (selectedRow?.id !== null && newStatus !== null) {
            moveToNewStatus(selectedRow.id, BookingStatusType.Ready);
        }
        handleDetailsClose();
    };

    const handleDelete = (row: any) => {
        if (row) {
            deleteOne('bookingitems', { id: row?.id }).then(getData).finally(refresh)
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'codiceIsbn',
            width: 160,
            headerName: getHeaderName('codiceIsbn'),
            valueGetter: (params) => params.row?.codiceIsbn,
            renderCell: (params) => {
                let deleteBook: JSX.Element = <></>;

                if (!params.row?.daLista && allowEditing && [BookingStatusType.NotToBook].includes(params.row?.currentStatus)) {
                    deleteBook = <CustomConfirmButton
                        label='ra.action.delete'
                        icon={<DeleteIcon fontSize='small' />}
                        color={theme.palette.error.main}
                        title='bookings.other.confirmDialogDeleteBook.title'
                        content='bookings.other.confirmDialogDeleteBook.content'
                        translateOptions={{ codiceIsbn: params.row?.codiceIsbn, titolo: params.row?.titolo }}
                        onConfirm={() => handleDelete(params.row)}
                    />;
                }
                return (
                    <Typography variant="body2" component="span" display="flex" alignItems="center" justifyContent="start" gutterBottom>
                        {params.row?.codiceIsbn}
                        {deleteBook}
                    </Typography>
                )
            }
        },
        {
            field: 'titolo',
            flex: 2,
            headerName: getHeaderName('titolo'),
            valueGetter: (params) => params.row?.titolo
        },
        {
            field: 'sottotitolo',
            flex: 1,
            headerName: getHeaderName('sottotitolo'),
            valueGetter: (params) => params.row?.sottotitolo
        },
        {
            field: 'autori',
            flex: 1,
            headerName: getHeaderName('autori'),
            valueGetter: (params) => params.row?.autori
        },
        {
            field: 'editore',
            flex: 1,
            headerName: getHeaderName('editore'),
            valueGetter: (params) => params.row?.editore
        },
        {
            field: 'otherInfo',
            flex: 1,
            headerName: getHeaderName('otherInfo'),
            valueGetter: (params) => params.row?.bookingType === BookingType.Scolastica ? params.row?.disciplina : params.row?.categorie
        },
        {
            field: 'bookingType',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            headerName: getHeaderName('bookingType'),
            renderCell: (params) => <BookingTypeIcon value={params.row?.bookingType} />
        },
        {
            field: 'currentStatus',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            headerName: getHeaderName('currentStatus'),
            renderCell: (params) => <BookingStatusTypeIcon value={params.row?.currentStatus} />
        },
        {
            field: 'statusDateTime',
            type: 'dateTime',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerName: getHeaderName('statusDateTime'),
            valueGetter: (params) => params.row?.statusDateTime ? new Date(params.row?.statusDateTime) : null
        },
        {
            field: 'prezzo',
            width: 100,
            headerAlign: 'right',
            align: 'right',
            headerName: getHeaderName('prezzo'),
            valueGetter: (params) => `${params.row?.prezzo.toFixed(2)} €`
        },
    ];

    // if (allowEditing) {
    //     columns.splice(0, 1);
    // }

    if (showStudentCode) {
        columns.splice(0, 0, {
            field: 'studentCode',
            flex: 1,
            headerName: getHeaderName('studentName', 'bookings'),
            valueGetter: (params) => `${params.row?.bookings.studentMiurYearCourse.code} - ${mapStudentDisplayName(params.row?.bookings.studentMiurYearCourse.student)}`
        })
    }

    if (allowEditing) {
        columns.push({
            field: 'actions',
            type: 'actions',
            width: 50,
            getActions: (params) => {
                const allowedNewStatus = getAllowedNewStatus(params.row?.bookingType);
                if (params.row?.currentStatus && allowedNewStatus[params.row?.currentStatus]) {
                    let allowedByStatus = allowedNewStatus[params.row?.currentStatus];
                    return allowedByStatus.map(status => {
                        return (
                            <GridActionsCellItem
                                icon={<BookingStatusTypeIcon value={status} />}
                                label={translate(`pos.enums.BookingStatusType.${status}`, { smart_count: 1 })}
                                onClick={() => {
                                    moveToNewStatus(params.row.id, status);
                                }}
                                showInMenu
                            />
                        )
                    })
                }
                else {
                    return []
                }
            },
        })
    }

    if (loading) return <Loading />;
    if (error) { return <p>ERROR</p>; }

    return (
        <>
            <DataGrid
                rows={rows}
                columns={columns}
                density={'compact'}
                hideFooterPagination={true}
                loading={loading}
                {...dataGridOptions}
                sx={{
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none !important'
                    }
                }}
            />
            {
                selectedRow && showDetails &&
                <Dialog open={showDetails} onOpen={handleDetailsOpen} hideClose preventEscapeKeyDown preventBackdropClick title={translate('ra.notification.updated', { smart_count: 1 })}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h3" textAlign='center' gutterBottom>
                                {selectedRow.bookings.studentMiurYearCourse.code}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h5" textAlign='center' gutterBottom>
                                {mapStudentDisplayName(selectedRow.bookings.studentMiurYearCourse.student)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h6" textAlign='center' gutterBottom sx={{ fontStyle: 'italic' }}>
                                {`"${selectedRow.bookings.studentMiurYearCourse.miurYearCourses.miurSchools.denominazioneScuola}"`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ padding: theme => theme.spacing(2), paddingRight: 0 }}>
                            <Divider flexItem />
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h6" textAlign='center' gutterBottom>
                                {`"${selectedRow.titolo}"`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h6" textAlign='center' gutterBottom>
                                {selectedRow.codiceIsbn}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center" direction='column' alignItems="center">
                            {selectedRow?.bookings?.covered &&
                                <Typography variant="h5" textAlign='center' gutterBottom>
                                    {translate(`pos.enums.BookingStatusType.${BookingStatusType.ToCover}`, { smart_count: 1 })}
                                </Typography>
                            }
                            <Typography variant="h4" textAlign='center' gutterBottom>
                                {translate(`pos.enums.BookingStatusType.${newStatus}`, { smart_count: 1 })}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Tooltip title={translate('ra.action.confirm')}>
                                <IconButton onClick={handleConfirm}>
                                    <CheckIcon sx={{ fontSize: theme => theme.spacing(10) }} color='success' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={translate('ra.action.cancel')}>
                                <IconButton onClick={handleDetailsClose}>
                                    <CloseIcon sx={{ fontSize: theme => theme.spacing(10) }} color='error' />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Dialog>
            }
        </>
    );
};

export default BookingItemsDataGrid;
