import { Box, Divider, Grid, Stack } from '@mui/material';

import ImageIcon from '@mui/icons-material/Image';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    FunctionField,
    Link,
    useTranslate,
    ReferenceOneField
} from 'react-admin';

import { DialogButton, ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';
import { mapSchoolClassInfo } from 'admin/mappers';

const CustomShow = props => {
    const translate = useTranslate();
    return (
        <Show {...props} actions={<ShowActions hasClone={false} />} title={<CustomTitle />}>
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="familyName">
                            <TextField source="familyName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="givenName">
                            <TextField source="givenName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="fiscalCode">
                            <FunctionField source="fiscalCode" render={
                                record =>
                                    <Box display='flex'>
                                        <TextField source="fiscalCode" />
                                        {
                                            record.fiscalCodeUrl &&
                                            <DialogButton
                                                icon={<ImageIcon sx={{ marginLeft: theme => theme.spacing(2) }} />}
                                                label={translate("resources.students.fields.fiscalCode")}
                                                dialogTitle={translate("resources.students.fields.fiscalCode")}
                                            >
                                                <img src={record.fiscalCodeUrl} alt="" style={{ maxHeight: '100%', objectFit: 'contain' }} />
                                            </DialogButton>
                                        }
                                    </Box>
                            } />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="phoneNumber">
                            <FunctionField source="phoneNumber" textAlign='center' render={
                                record => record.phoneNumber && <Link to={`tel:${record.phoneNumber}`}>
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <PhoneIcon />&nbsp;&nbsp;{record.phoneNumber}
                                    </Stack>
                                </Link>
                            } />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="email">
                            <FunctionField source="email" textAlign='center' render={
                                record => record.email && <Link to={`mailto:${record.email}`}>
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <EmailIcon />&nbsp;&nbsp;{record.email}
                                    </Stack>
                                </Link>
                            } />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Labeled source="parentReference">
                            <TextField source="parentReference" />
                        </Labeled>
                    </Grid>

                    <Grid item xs={12}>
                        <ReferenceOneField
                            reference="studentmiuryearcourses"
                            filter={{ active: true }}
                            queryOptions={{
                                meta: {
                                    expand: [
                                        'miurYearCourses.miurSchools'
                                    ]
                                }
                            }}
                            target="studentId"
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Labeled label="resources.miurschools.fields.denominazioneScuola">
                                        <TextField source="miurYearCourses.miurSchools.denominazioneScuola" />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Labeled>
                                        <FunctionField label="resources.miuryearcourses.fields.tipoGradoScuola" render={
                                            record => translate(`pos.enums.TipoGradoScuolaType.${record.miurYearCourses.tipoGradoScuola}`, { smart_count: 1 })
                                        } />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Labeled>
                                        <FunctionField label="resources.miuryearcourses.fields.annoCorso" render={
                                            record => `${mapSchoolClassInfo(record.miurYearCourses)}`
                                        } />
                                    </Labeled>
                                </Grid>
                            </Grid>
                        </ReferenceOneField>
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: theme => theme.spacing(2), paddingRight: 0 }}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12}>
                        <Labeled>
                            <TextField source="note" />
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;