import { Grid } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    DateField,
    BooleanField,
    EmailField,
    ImageField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    FunctionField,
} from 'react-admin';

import { ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';

const CustomShow = () => {
    return (
        <Show actions={<ShowActions />} title={<CustomTitle />}>
            <SimpleShowLayout>
                <Grid container justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={3} sx={{ padding: 0 }}>
                        <ImageField source="profilePhotoUrl" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', '& img': { width: 'auto !important', height: '100px !important', margin: ' 0 !important' } }} />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                        <Labeled>
                            <TextField source="displayName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled>
                            <TextField source="userName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled>
                            <EmailField source="email" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled>
                            <DateField source="phoneNumber" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="providerType">
                            <ReferenceField source="providerType" reference="api/enums/providerTypes" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="changePassword">
                            <BooleanField source="changePassword" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="defaultCulture">
                            <TextField source="defaultCulture" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Labeled>
                            <ReferenceField source="status" reference="api/enums/userStatus" />
                        </Labeled>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Labeled>
                            <ReferenceArrayField source="userPermissionsIds" reference="userpermissions" >
                                <SingleFieldList linkType={false}>
                                    <FunctionField render={record => <ChipField source="planningItemTypeName" variant='filled' color={record.enabled ? 'success' : 'error'} />} />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </Labeled>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Labeled>
                            <ReferenceArrayField source="rolesIds" reference="roles" >
                                <SingleFieldList>
                                    <ChipField source="name" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;
