import { useState } from 'react';

import { Card, CardActionArea, CardContent, CardMedia, Grid, Paper, Typography } from '@mui/material';

import {
    useTranslate,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';

import { GoogleBookSearch, SearchParameters } from 'admin/components/services';

const SearchGoogleBookInput = () => {
    const translate = useTranslate();
    const [books, setBooks] = useState([] as any[]);
    const { setValue } = useFormContext();

    const onSearched = (data) => {
        if (data) {
            setBooks(data);
        }
    };

    const onSelected = (selectedBook) => {
        if (selectedBook) {
            setValue('codiceIsbn', selectedBook.isbn);
            setValue('titolo', selectedBook.title);
            setValue('sottotitolo', selectedBook.subtitle);
            setValue('autori', selectedBook.authors.join(', '));
            setValue('editore', selectedBook.publisher);
            setValue('categorie', selectedBook.categories.join(', '));
            setValue('dataPubblicazione', selectedBook.publishedDate ? new Date(selectedBook.publishedDate) : null);
            setValue('lingua', selectedBook.language);
            setValue('image', selectedBook.image);
            setValue('prezzo', selectedBook.price);

            setBooks([]);
        }
    };

    const generateSubtitle = (book) => {
        let subtitle = book.authors.join(', ');
        subtitle += subtitle.length > 0 ? `, ${book.publisher}` : book.publisher;
        subtitle += subtitle.length > 0 ? `, ${book.publishedDate}` : book.publishedDate;

        if (book.price > 0)
            subtitle += subtitle.length > 0 ? `, ${book.price}` : book.price;

        return subtitle ? <span>{subtitle}</span> : '';
    }

    const label = `${translate('ra.action.search')} ${translate('resources.books.name', { smart_count: 1 })}`;
    const isbnLabel = `${label} - ${translate('resources.books.fields.codiceIsbn')}`;
    const titleLabel = `${label} - ${translate('resources.books.fields.titolo')}`;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <GoogleBookSearch label={isbnLabel} searchOption={{ parameter: SearchParameters.Isbn }} onSearched={onSearched} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <GoogleBookSearch label={titleLabel} searchOption={{ parameter: SearchParameters.Title }} onSearched={onSearched} />
            </Grid>
            {
                books.length > 0 &&
                <Grid item xs={12} >
                    <Paper sx={{ p: 2, mt: 1 }} elevation={3}>
                        <Grid container spacing={2}>
                            {
                                books.map((book, key) => (
                                    <Grid key={key} item xs={12} sm={2}>
                                        <Card sx={{ minHeight: 200, height: '100%' }} >
                                            <CardActionArea onClick={() => onSelected(book)}>
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    image={book.image}
                                                    alt={book.title}
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="div">
                                                        {book.title}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {generateSubtitle(book)}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Paper>
                </Grid>
            }
        </Grid>
    );
};

export default SearchGoogleBookInput;