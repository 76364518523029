import { useMediaQuery, Theme } from '@mui/material';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    DateField,
    NumberField,
} from 'react-admin';

import {
    ShowButton,
    ListRowActions,
} from 'admin/components';

import FilterSidebar from './FilterSidebar';
import { BookingStatusTypeField, BookingTypeField } from 'admin/views/Common';
import { BookingStatusType } from 'admin/types';
import { mapStudentDisplayName } from 'admin/mappers';

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const expand = [
        'bookings.studentMiurYearCourse.miurYearCourses.miurSchools',
        'bookings.studentMiurYearCourse.student'
    ];
    return (
        <List
            hasCreate={false}
            actions={false}
            filterDefaultValues={{ currentStatus: BookingStatusType.ToBook }}
            sort={{ field: 'statusDateTime', order: 'ASC' }}
            queryOptions={{
                meta: { expand: expand }
            }}
            perPage={25}
            storeKey={false}
            aside={<FilterSidebar />}
            sx={{ marginTop: 2 }}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => mapStudentDisplayName(record.bookings.studentMiurYearCourse.student)}
                    secondaryText={record => `${record.bookings.studentMiurYearCourse.code}`}
                    linkType={false}
                />
            ) : (
                <Datagrid bulkActionButtons={false} optimized>
                    <TextField label="resources.studentmiuryearcourse.fields.code" source="bookings.studentMiurYearCourse.code" />
                    <TextField label="resources.students.fields.familyName" source="bookings.studentMiurYearCourse.student.familyName" />
                    <TextField label="resources.students.fields.givenName" source="bookings.studentMiurYearCourse.student.givenName" />
                    <TextField source="codiceIsbn" />
                    <TextField source="titolo" />
                    <NumberField source="prezzo" options={{
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2
                    }} />
                    <BookingStatusTypeField textAlign="center" source="currentStatus" />
                    <BookingTypeField textAlign="center" source="bookingType" />
                    <DateField source="statusDateTime" />
                    <ListRowActions>
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
