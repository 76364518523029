import { cloneElement } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';

import ToBookIcon from '@mui/icons-material/Book';
import NotBookedIcon from '@mui/icons-material/Clear';
import BookedIcon from '@mui/icons-material/LocalLibrary';
import PickedUpIcon from '@mui/icons-material/LocalShipping';
import ToCoverIcon from '@mui/icons-material/AdfScanner';
import ReadyIcon from '@mui/icons-material/HailOutlined';
import CompletedIcon from '@mui/icons-material/DoneAll';
import Icon from '@mui/icons-material/Apps';

import { Box, Tooltip, alpha } from '@mui/material';
import { lightGreen, green, yellow, blueGrey, indigo, orange, lightBlue, red } from '@mui/material/colors';

import { BookingStatusType } from 'admin/types';

export const GetBookingStatusTypeColor = (value: BookingStatusType | string) => {
    let iconColor: any = null;

    switch (value) {
        case BookingStatusType.ToBook:
            iconColor = yellow[700];
            break;
        case BookingStatusType.NotToBook:
            iconColor = red[500];
            break;
        case BookingStatusType.Booked:
            iconColor = lightGreen[500];
            break;
        case BookingStatusType.PickedUp:
            iconColor = orange[600];
            break;
        case BookingStatusType.ToCover:
            iconColor = blueGrey[600];
            break;
        case BookingStatusType.Ready:
            iconColor = indigo[600];
            break;
        case BookingStatusType.Completed:
            iconColor = green[800];
            break;
        default:
            iconColor = lightBlue[500];
            break;
    }

    return {
        iconColor,
        color: alpha(iconColor, 0.3)
    }
}

export const BookingStatusTypeIcon = ({ value, fontSize = 'medium' }) => {
    const translate = useTranslate();

    let statusIcon: any = null;

    switch (value) {
        case BookingStatusType.ToBook:
            statusIcon = <ToBookIcon />;
            break;
        case BookingStatusType.NotToBook:
            statusIcon = <NotBookedIcon />;
            break;
        case BookingStatusType.Booked:
            statusIcon = <BookedIcon />;
            break;
        case BookingStatusType.PickedUp:
            statusIcon = <PickedUpIcon />;
            break;
        case BookingStatusType.ToCover:
            statusIcon = <ToCoverIcon />;
            break;
        case BookingStatusType.Ready:
            statusIcon = <ReadyIcon />;
            break;
        case BookingStatusType.Completed:
            statusIcon = <CompletedIcon />;
            break;
        default:
            statusIcon = <Icon />;
            break;
    }

    return (
        <Tooltip title={translate(`pos.enums.BookingStatusType.${value}`, { smart_count: 1 })}>
            {cloneElement(statusIcon, { fontSize: fontSize, sx: { color: GetBookingStatusTypeColor(value).iconColor, } })}
        </Tooltip>
    )
}

export const BookingStatusTypeField = (props) => {
    const record = useRecordContext(props);
    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <BookingStatusTypeIcon value={record[props.source]} />
        </Box>
    )
}