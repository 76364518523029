import { Theme, useMediaQuery } from '@mui/material';

import { Link } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PublicIcon from '@mui/icons-material/Public';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    FunctionField,
    TextInput,
    NullableBooleanInput,
} from 'react-admin';

import {
    ShowButton,
    ListActions,
    ListRowActions,
} from 'admin/components';

import CustomShowExpand from './CustomShowExpand';
import ImportSchoolsButton from './ImportSchoolsButton';
import FavoriteButton from './FavoriteButton';

const filters = [
    <TextInput source="codiceScuola" alwaysOn />,
    <TextInput source="denominazioneScuola" alwaysOn />,
    <TextInput source="descrizioneTipologiaGradoIstruzioneScuola" alwaysOn />,
    <TextInput source="descrizioneComune" alwaysOn />,
    <NullableBooleanInput source="favorite" alwaysOn />
];

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            actions={
                <ListActions hasExport={false}>
                    <ImportSchoolsButton />
                </ListActions>
            }
            filters={filters}
            filterDefaultValues={{ descrizioneComune: 'TORINO' }}
            sort={{ field: 'favorite', order: 'DESC' }}
            storeKey={false}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.codiceScuola} - ${record.denominazioneScuola}`}
                    linkType={false}
                />
            ) : (
                <Datagrid bulkActionButtons={false} expand={<CustomShowExpand />} expandSingle optimized>
                    <TextField source="codiceScuola" />
                    <TextField source="denominazioneScuola" />
                    <TextField source="indirizzoScuola" />
                    <TextField source="descrizioneComune" />
                    <TextField source="descrizioneTipologiaGradoIstruzioneScuola" />
                    <FunctionField source="indirizzoEmailScuola" textAlign='center' render={record => record.indirizzoEmailScuola && <Link href={`mailto:${record.indirizzoEmailScuola}`}><EmailIcon /></Link>} />
                    <FunctionField source="indirizzoPecScuola" textAlign='center' render={record => record.indirizzoPecScuola && <Link href={`mailto:${record.indirizzoPecScuola}`}><MarkEmailReadIcon /></Link>} />
                    <FunctionField source="sitoWebScuola" textAlign='center' render={record => record.sitoWebScuola && <Link href={`//${record.sitoWebScuola}`} target='_blank' rel="noreferrer"><PublicIcon /></Link>} />
                    <FunctionField source="favorite" textAlign='center' render={record => <FavoriteButton record={record} />} />
                    <ListRowActions>
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )
            }
        </List >
    );
};

export default CustomList;
