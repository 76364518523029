import { forwardRef, useImperativeHandle, ReactNode, useState } from 'react';
import { ReactElement } from 'react';

import { Box, Tooltip, alpha, useTheme } from '@mui/material';

import Icon from '@mui/icons-material/Add';

import {
    Button,
    ButtonProps,
    usePermissions,
    useRecordContext,
    useTranslate,
} from 'react-admin';

import { Dialog } from 'admin/components';

export interface DialogButtonActions {
    open(): void;
    close(): void;
    isOpen?: boolean;
}

interface Props {
    color?: string;
    icon?: ReactElement;
    label?: string;
    withText?: boolean;
    dialogTitle?: string;
    onOpen?: () => void;
    onClosed?: () => void;
    children?: any | null;
    fullScreen?: boolean;
    fullWidth?: boolean;
    scroll?: 'body' | 'paper';
    hideClose?: boolean;
    open?: boolean;
    preventEscapeKeyDown?: boolean;
    preventBackdropClick?: boolean;
    dialogActions?: ReactNode | ReactNode[] | null;
    disabled?: boolean;
    disabledFn?: ((record: any, permission: string) => boolean) | null;
}

export type DialogButtonProps = Props & Omit<ButtonProps, "color">;

const DialogButton = forwardRef<DialogButtonActions, DialogButtonProps>((props: DialogButtonProps, ref) => {
    const theme = useTheme();

    const {
        color = theme.palette.primary.main,
        icon = defaultIcon,
        label = 'ra.action.open',
        withText = false,
        dialogTitle = '',
        onOpen = () => { },
        onClosed = () => { },
        children,
        fullScreen,
        fullWidth,
        scroll,
        hideClose = false,
        preventEscapeKeyDown = false,
        preventBackdropClick = false,
        dialogActions,
        open = false,
        disabled = false,
        disabledFn = null,
        variant,
    } = props;

    const { permissions } = usePermissions();
    const translate = useTranslate();
    const record = useRecordContext();
    const [innerOpen, setInnerOpen] = useState<boolean>(open);

    useImperativeHandle(
        ref,
        () => ({
            open() {
                setInnerOpen(true);
            },
            close() {
                setInnerOpen(false);
            },
            isOpen: innerOpen
        }));

    const handleOpen = () => {
        if (!innerOpen) {
            setInnerOpen(true);
            onOpen();
        }
    };

    const handleClose = () => {
        if (innerOpen) {
            setInnerOpen(false);
            onClosed();
        }
    };

    const dialogOptions = {
        actions: dialogActions ? (Array.isArray(dialogActions) ? dialogActions : [dialogActions]) : null,
        fullScreen,
        fullWidth,
        scroll,
        hideClose,
        preventEscapeKeyDown,
        preventBackdropClick,
    };

    let isDisabled: boolean = disabledFn !== null ? disabledFn(record, permissions) : disabled;

    const finalProps = {
        disabled: isDisabled,
        label: withText ? label : "",
        variant,
        sx: {
            color: withText ? color : color,
            "&.Mui-disabled .MuiSvgIcon-root": {
                color: alpha(theme.palette.common.black, 0.26)
            },
            px: withText ? theme.spacing(1) : 0,
            minWidth: theme.spacing(4)
        }
    } as ButtonProps

    return (
        <>
            {
                !withText ?
                    <Tooltip title={translate(label)}>
                        <Box>
                            <Button label={label} onClick={handleOpen} {...(finalProps as any)}>
                                {icon}
                            </Button>
                        </Box>
                    </Tooltip>
                    :
                    <Button label={label} onClick={handleOpen} {...(finalProps as any)}>
                        {icon}
                    </Button>
            }
            <Dialog open={innerOpen} onOpen={handleOpen} onClosed={handleClose} title={translate(dialogTitle)} {...dialogOptions}>
                {children ? children : <></>}
            </Dialog>
        </>
    );
});

const defaultIcon = <Icon />;

export default DialogButton;
