export const messages = {
    name: "bookingitems",
    resources: {
        name: 'Ordine |||| Ordini',
        forcedCaseName: 'Ordine |||| Ordini',
        fields: {
            bookingsId: 'Prenotazione',
            bookingType: 'Tipologia',
            codiceIsbn: "Codice Isbn",
            autori: "Autori",
            titolo: "Titolo",
            sottotitolo: "Sottotitolo",
            disciplina: "Disciplina",
            volume: "Volume",
            editore: "Editore",
            prezzo: "Prezzo",
            sconto: "Sconto",
            categorie: "Categorie",
            dataPubblicazione: "Data Pubblicazione",
            image: "Image",
            lingua: "Lingua",
            quantity: 'Quantità',

            otherInfo: 'Altre info',

            currentStatus: 'Stato',
            statusDateTime: "Data"
        },
    },
    global: {
        menu: {
            name: 'Ordini',
        },
        list: {
            search: 'Cerca',
        },
        form: {
        },
        edit: {
            title: 'Ordine di %{student} effettuato il %{date} - %{status}',
        },
        filters: {
            status: 'Stato',
            type: 'Tipologia',
            info: "Info prenotazione"
        },
        action: {
            closeable: 'Chiudi prenotazione',
            notCloseable: 'Presenti libri da gestire',
            reopen: 'Riapri prenotazione',
            generateFileButton: 'Centro Libri',
            generateFileTitle: 'Creazione prenotazione Centro Libri',
            generateFileContent: 'Genera il file per %{smart_count} prenotazione |||| Genera il file per %{smart_count} prenotazioni',
            importFileButton: 'Centro Libri',
            confirmDialog: {
                title: 'Aggiornamento stato prenotazione',
                content: 'Aggiorna lo stato da \'%{status}\' a \'%{newStatus}\'?'
            },
            confirmUnmatchedDialog: {
                title: 'Assegnazione libro',
                content: 'Assegna il libro \'%{title}\' alla prenotazione \'%{code}\'?'
            },
            confirmDialogReady: {
                title: 'Consegna',
                content: 'Consegna %{smart_count} libro a \'%{code}\'? |||| Consegna %{smart_count} libri a \'%{code}\'?'
            },
            confirmDialogClose: {
                title: 'Chiusura prenotazione',
                content: 'Chiudi la prenotazione di %{studentName} (%{bookingCode})?'
            },
            confirmDialogReopen: {
                title: 'Riapri prenotazione',
                content: 'Riapri la prenotazione di %{studentName} (%{bookingCode})?'
            },
            showEmail: 'Vedi Email',
            noEmail: 'No Email'
        },
        other: {
            count: '%{smart_count} da prenotare',
            showEmail: {
                dialogTitle: "Email",
                sentFrom: "Inviata da:",
                sentDate: "Inviata in data:",
                attachments: "Allegati:",
                noAttachments: "Nessun allegato",
                html: "Contenuto",
            },
            pickedUpDialogTitle: "Gestisci ritiri",
            addToBooking: 'Aggiungi %{type}',
            addToBookingDialogTitle: 'Aggiungi libro alla prenotazione'
        }
    },
};

export default messages;