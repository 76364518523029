import { useState } from 'react';

import { Box, Divider } from '@mui/material';
import Icon from '@mui/icons-material/OpenInNew';

import {
    Button,
    AutocompleteInput,
    SimpleForm,
    useRedirect,
    ReferenceInput,
} from 'react-admin';

import { DashboardCard } from 'admin/components';
import { mapStudentDisplayName } from 'admin/mappers';

export interface SearchFormProps {
    handleChange: (value: any, record: any) => void;
}

const SearchForm = ({ handleChange }: SearchFormProps) => {
    const expand = [
        'studentMiurYearCourse.miurYearCourses.miurSchools',
        'studentMiurYearCourse.student'
    ];
    const optionText = choice => `${choice.studentMiurYearCourse.code} - ${mapStudentDisplayName(choice.studentMiurYearCourse.student)}`;
    const filterToQuery = searchText => ({ "[manual]": `(Contains(studentMiurYearCourse/code, '${searchText}') or Contains(studentMiurYearCourse/student/givenName, '${searchText}') or Contains(studentMiurYearCourse/student/familyName, '${searchText}'))` });

    return (
        <SimpleForm onSubmit={() => { }} toolbar={false} sx={{ p: 1, pl: 2, pr: 2, '&:last-child': { pb: 1 } }} >
            <ReferenceInput
                source="bookingsId"
                reference="bookings"
                filter={{ "closedAt_eq": null }}
                sort={{
                    field: "studentMiurYearCourse.code",
                    order: 'ASC'
                }}
                queryOptions={{
                    meta: {
                        expand: expand
                    }
                }}
            >
                <AutocompleteInput
                    source="bookingsId"
                    helperText={false}
                    label="ra.action.search"
                    optionText={optionText}
                    onChange={handleChange}
                    filterToQuery={filterToQuery}
                    fullWidth
                />
            </ReferenceInput>
        </SimpleForm >
    )
}

const SearchCard = () => {
    const redirect = useRedirect();
    const [searchValue, setSearchValue] = useState<any>(null);

    const handleChange = (value, record) => {
        setSearchValue(value);
    };

    return (
        <DashboardCard useChildrenAsContent>
            <Box>
                <SearchForm handleChange={handleChange} />
            </Box>

            <Divider flexItem />

            <Box sx={{ p: 0 }} display='flex' justifyContent='center'>
                <Button disabled={!searchValue} alignIcon='left' label="ra.action.open" onClick={() => redirect('show', 'bookings', searchValue)}>
                    <Icon />
                </Button>
            </Box>
        </DashboardCard>
    )
};

export default SearchCard;