const italianMessages = {
    ra: {
        action: {
            add_filter: 'Aggiungi un filtro',
            add: 'Aggiungi',
            add_custom: 'Aggiungi %{name}',
            back: 'Indietro',
            bulk_actions: '%{smart_count} selezionati',
            cancel: 'Annulla',
            clear_filter: 'Pulisci filtro',
            clear_array_input: 'Svuota l\'elenco',
            clear_input_value: 'Svuota il modulo',
            clone: 'Duplica',
            confirm: 'Conferma',
            create: 'Crea',
            create_item: 'Crea %{item}',
            delete: 'Cancella',
            soft_delete: 'Cancella Temporaneamente',
            edit: 'Modifica',
            export: 'Esporta',
            import: 'Importa',
            download: 'Scarica file',
            upload: 'Carica file',
            list: 'Elenco',
            refresh: 'Aggiorna',
            remove_filter: 'Rimuovi questo filtro',
            remove_all_filters: 'Rimuovi tutti i filtri',
            remove: 'Elimina',
            remove_custom: 'Elimina %{name}',
            save: 'Salva',
            save_and_edit: "Salva e modifica",
            save_and_show: "Salva e apri",
            save_and_add: "Salva e aggiungi nuovo",
            bulkEnabled: 'Abilita tutti',
            search: 'Ricerca',
            select_all: 'Seleziona tutto',
            select_row: 'Seleziona riga',
            show: 'Mostra',
            sort: 'Ordina',
            undo: 'Annulla',
            unselect: 'Deseleziona',
            expand: 'Espandi',
            close: 'Chiudi',
            close_all: 'Chiudi Tutto',
            open_menu: 'Apri menu',
            close_menu: 'Chiudi menu',
            update: 'Aggiorna',
            move_up: 'Sposta su',
            move_down: 'Sposta giù',
            scroll_to_top: 'Torna su',
            mode: {
                sortableList: 'Ordina Elenco',
            },
            showHtmlPreview: 'Mostra HTML',
            getFile: 'Scarica',
            sync: 'Sync',
            manage: 'Gestisci',
            restore: 'Ripristina',
            open: 'Apri',
            toggle_theme: 'Alterna la modalità chiaro/scuro',
            select_columns: 'Seleziona colonne',
            update_application: 'Ricarica l\'applicazione',
            load_more: 'Carica altro',
        },
        boolean: {
            true: 'Si',
            false: 'No',
            null: 'Tutto',
        },
        page: {
            create: 'Aggiungi %{name}',
            dashboard: 'Cruscotto',
            edit: '%{name}',
            error: 'Qualcosa non ha funzionato',
            list: '%{name}',
            loading: 'Caricamento in corso',
            not_found: 'Non trovato',
            show: '%{name}',
            empty: 'Non ci sono dati',
            invite: 'Vuoi aggiungere un nuovo record?',
            invite_more: 'Vuoi aggiungere nuovi records?',
            initialize_table: 'Vuoi inizializzare la tabella?',
            unauthorized: 'L\'accesso a questa pagina non è consentito',
        },
        input: {
            file: {
                not_found: 'Documento non trovato o danneggiato',
                upload_several: 'Trascina i files da caricare, oppure clicca per selezionare',
                upload_single: 'Trascina il file da caricare, oppure clicca per selezionarlo',
                uploaded_several: 'Ok! Documenti caricati',
                uploaded_single: 'Ok! Documento caricato',
            },
            image: {
                upload_several: 'Trascina le immagini da caricare, oppure clicca per selezionarle.',
                upload_single: 'Trascina l\'immagine da caricare, oppure clicca per selezionarla.'
            },
            references: {
                all_missing: 'Impossibile trovare i riferimenti associati.',
                many_missing: 'Almeno uno dei riferimenti associati non sembra più disponibile.',
                single_missing: 'Il riferimento associato non sembra più disponibile.'
            },
            password: {
                toggle_visible: 'Nascondi password',
                toggle_hidden: 'Mostra password',
            },
            sortable: {
                all_selected: 'Selezionati tutti',
                selected: '%{count} selezionati',
                choices: 'Scelte',
                chosen: 'Selezionati',
            },
            copy: 'Copia testo',
            copied: 'Testo copiato',
            copy_custom: 'Copia testo \'%{name}\'',
            copied_custom: 'Testo \'%{name}\' copiato',
        },
        message: {
            about: 'Informazioni',
            are_you_sure: 'Sei sicuro ?',
            bulk_delete_content: 'Sei sicuro di voler cancellare questo elemento (%{name})? |||| Sei sicuro di voler eliminare questi %{smart_count} elementi?',
            bulk_delete_title: 'Cancella elemento selezionato |||| Cancella %{smart_count} elementi',
            clear_array_input: 'Sei sicuro di voler svuotare l\'intera lista?',
            delete_content: 'Sei sicuro di voler cancellare questo elemento?',
            delete_title: 'Cancella elemento selezionato',
            details: 'Dettagli',
            error: 'Si è verificato un errore e la richiesta non è stata completata.',
            empty: "Nessun dato disponibile",
            invalid_form: 'Il modulo non è valido. Si prega di verificare la presenza di errori.',
            loading: 'La pagina si sta caricando, solo un momento per favore',
            no: 'No',
            not_found: 'Hai inserito un URL errato, oppure hai cliccato un link errato',
            yes: 'Si',
            unsaved_changes: "Alcune modifiche non sono state salvate. Sei sicuro di volerle ignorare?",
            unauthorized: 'Permessi insufficienti',
            or: 'Oppure',
            soft_delete_content: 'Sei sicuro di voler cancellare temporaneamente questo elemento?',
            soft_delete_title: 'Cancella temporaneamente l\'elemento selezionato',
            delete_content_restore: 'Sei sicuro di voler ripristinare questo elemento?',
            delete_title_restore: 'Ripristina elemento selezionato',
        },
        navigation: {
            no_results: 'Nessun risultato trovato',
            no_more_results: 'La pagina numero %{page} è fuori dell\'intervallo. Prova la pagina precedente.',
            page_out_of_boundaries: 'Il numero di pagina %{page} è fuori dei limiti',
            page_out_from_end: 'Fine della paginazione',
            page_out_from_begin: 'Il numero di pagina deve essere maggiore di 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} di %{total}',
            partial_page_range_info: '%{offsetBegin}-%{offsetEnd} di oltre %{offsetEnd}',
            current_page: 'Pagina %{pagina}',
            page: 'Vai alla pagina %{pagina}',
            first: 'Vai alla prima pagina',
            last: "Vai all'ultima pagina",
            next: 'Successivo',
            previous: "Precedente",
            page_rows_per_page: 'Righe per pagina:',
            skip_nav: 'Passa al contenuto',
        },
        sort: {
            sort_by: 'Ordina per %{field} %{order}',
            ASC: 'crescente',
            DESC: 'decrescente',
        },
        auth: {
            auth_check_error: 'Effettua il login per continuare',
            user_menu: 'Profilo',
            username: 'Nome utente',
            password: 'Password',
            sign_in: 'Login',
            sign_in_error: 'Autenticazione fallita, riprovare.',
            reset_password: 'Reset Password',
            reset_password_title: 'Resetta la password',
            reset_password_content: 'Sei sicuro di voler resettare la password di \'%{email}\'?',
            reset_password_error: 'Reset della password fallito, riprovare.',
            reset_password_success: 'Reset della password completato.',
            logout: 'Disconnessione',
            forgot_password: 'Password dimenticata',
            back_to_homepage: 'Torna a homepage',
            back_to_loginpage: 'Torna a login',
            social_login_button: {
                google: 'Accedi con Google',
                microsoft: 'Accedi con Microsoft',
            }
        },
        notification: {
            updated: 'Record aggiornato |||| %{smart_count} records aggiornati',
            updated_more: 'Più record aggiornati',
            created: 'Record creato',
            deleted: 'Record eliminato |||| %{smart_count} records eliminati',
            bad_item: 'Record errato',
            item_doesnt_exist: 'Record inesistente',
            http_error: 'Errore di comunicazione con il server',
            data_provider_error: 'Errore del data provider. Controlla la console per i dettagli.',
            i18n_error: 'Impossibile caricare le traduzioni per la lingua specificata',
            canceled: 'Azione annullata',
            logged_out: 'La sessione è scaduta. Effettua nuovamente l\'accesso.',
            not_authorized: "Non siete autorizzati ad accedere a questa risorsa",
            application_update_available: "È disponibile una nuova versione",
            mode: {
                sortableListUpdated: 'Nuovo ordinamento elenco salvato',
                sortableListNotUpdated: 'Nuovo ordinamento elenco non salvato',
            },
            file: {
                not_found: 'Documento non trovato o danneggiato',
            },
            unique: 'Deve essere unico',
        },
        validation: {
            required: 'Campo obbligatorio',
            minLength: 'Deve essere lungo %{min} caratteri almeno',
            maxLength: 'Deve essere lungo %{max} caratteri al massimo',
            minValue: 'Deve essere almeno %{min}',
            maxValue: 'Deve essere al massimo %{max}',
            number: 'Deve essere un numero',
            email: 'Deve essere un indirizzo email valido',
            emailNotUnique: 'L\'indirizzo email è già stato utilizzato',
            tel: 'Deve essere un numero di telefono valido',
            oneOf: 'Deve essere uno di: %{options}',
            regex: 'Deve rispettare il formato (espressione regolare): %{pattern}',
            bool: 'Obbligatorio',
            iban: 'Deve essere un codice IBAN valido',
            dropZone: {
                'file-invalid-type': 'Il tipo di file deve essere %{messageSuffix} |||| Il tipo di file deve essere un tra: %{messageSuffix}',
                'file-too-large': 'File più grande di %{maxSize}',
                'file-too-small': 'File più piccolo di %{minSize}',
                'too-many-files': 'Troppi files caricati',
            }
        },
        saved_queries: {
            label: 'Query salvate',
            query_name: 'Nome della query',
            new_label: 'Salva la query corrente...',
            new_dialog_title: 'Salva la query corrente come',
            remove_label: 'Rimuovi la query salvata',
            remove_label_with_name: 'Rimuovi la query " % { nome }"',
            remove_dialog_title: "Rimuovere la query salvata?",
            remove_message: 'Sei sicuro di voler rimuovere questo elemento dall\'elenco delle query salvate?',
            help: 'Filtra l\'elenco e salva questa query per un secondo momento',
        },
        configurabile: {
            customize: 'Personalizza',
            configureMode: 'Configura questa pagina',
            inspector: {
                title: 'Ispettore',
                content: 'Passa il mouse sugli elementi dell\'interfaccia utente dell\'applicazione per configurarli',
                reset: 'Ripristina le impostazioni',
                hideAll: 'Nascondi tutto',
                showAll: 'Mostra tutto',
            },
            Datagrid: {
                title: 'Datagrid',
                unlabeled: 'Colonna non etichettata #%{colonna}',
            },
            SimpleForm: {
                title: 'Form',
                unlabeled: 'Input non etichettato #%{input}',
            },
            SimpleList: {
                title: 'Elenco',
                primaryText: 'Testo primario',
                secondaryText: 'Testo secondario',
                tertiaryText: 'Testo terziario',
            },
        },
        apiError: {
            DefaultError: "Errore",
            ConcurrencyFailure: "Fallimento di concomitanza",
            PasswordMismatch: "Password errata",
            InvalidToken: "Token non valido",
            RecoveryCodeRedemptionFailed: "Riscossione del codice di recupero fallita",
            LoginAlreadyAssociated: "Login già associato",
            InvalidUserName: "Nome utente non valido",
            InvalidEmail: "Email non valida",
            DuplicateUserName: "Nome utente duplicato",
            DuplicateEmail: "Email duplicata",
            InvalidRoleName: "Nome ruolo non valido",
            DuplicateRoleName: "Nome ruolo duplicato",
            UserAlreadyHasPassword: "L'utente ha già la password",
            UserLockoutNotEnabled: "Blocco utente non abilitato",
            UserAlreadyInRole: "Utente già inserito nel ruolo",
            UserNotInRole: "Utente non in ruolo",
            PasswordTooShort: "Password troppo corta",
            PasswordRequiresUniqueChars: "La password richiede caratteri unici",
            PasswordRequiresNonAlphanumeric: "La password richiede caratteri non alfanumerici",
            PasswordRequiresDigit: "La password richiede una cifra",
            PasswordRequiresLower: "La password richiede una carattere minuscolo",
            PasswordRequiresUpper: "La password richiede una carattere maiuscolo",
        },
        google: {
            maps: {
                noLocations: "Nessuna località",
                typeLocation: "Cerca località"
            }
        }
    },
};

export default italianMessages;