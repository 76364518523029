import { Utils } from 'admin/core';
import { useRecordContext, useResourceContext, useTranslate } from 'react-admin';

export default () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const translate = useTranslate();
    return (
        <span>
            {record ? translate(`${resource}.edit.title`, { date: Utils.FormatToDate(record.updatedAt, 'it', true) }) : ''}
        </span>
    );
};
