import { Children, useEffect, useState } from 'react';

import {
    AppBar,
    AppBarProps,
    Button,
    Logout,
    TitlePortal,
    usePermissions,
    useRedirect,
    useStore,
    useTranslate,
} from 'react-admin';

import { Divider, Typography } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CreateStudentIcon from '@mui/icons-material/Boy';

import { UserProfile } from './UserProfile';
import { Roles } from 'admin/types';
import { UserMenu } from './UserMenu';
import { useGetVersion } from 'admin/components';

const useStyles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textAlign: 'center'
    },
    role: {
        flex: '0 0 auto',
        marginRight: 1,
        textAlign: 'right'
    },
    studentButton: {
        flex: '0 0 auto',
        marginRight: 1,
        textAlign: 'right'
    },
    menuItem: {
        borderLeft: 'none',
    },
    spacer: {
        flex: 1,
    },
};

const CreateStudentButton = () => {
    const redirect = useRedirect();
    const translate = useTranslate();
    return (
        <Button sx={useStyles.studentButton} alignIcon='left' color="inherit" label={translate('students.action.add')} onClick={() => redirect('create', 'students')}>
            <CreateStudentIcon />
        </Button>
    )
};

const LogoutButton = (props: any) => <Logout {...props} icon={<ExitToAppIcon />} />;

const CustomUserMenu = ({ permissions, version, ...props }: any) => (
    <UserMenu {...props}>
        <UserProfile />
        <LogoutButton />
        <Divider />
        {
            version &&
            <Typography variant="body1" color="inherit" textAlign='center'>
                v. {version}
            </Typography>
        }
    </UserMenu>
);

export type HeaderProps = AppBarProps & {
    logo?: string
    logoStyle?: any
};

const Header: React.FC<HeaderProps> = (props) => {
    const {
        logo = '/logo.png',
        logoStyle,
        children
    } = props;

    const [userInfo] = useStore<any>('userinfo', null);
    const { isLoading, permissions, refetch } = usePermissions();
    const [roles, setRoles] = useState<any>(null);
    const [version, setVersion] = useState<any>(null);

    const translate = useTranslate();
    const getVersion = useGetVersion();

    useEffect(() => {
        getVersion().then(v => {
            setVersion(v);
        })
    }, [getVersion])

    useEffect(() => {
        if (!isLoading && permissions) {
            if (permissions.length === 1)
                setRoles(translate(`pos.enums.RoleType.${permissions[0]}`));
            else {
                let roles = permissions.filter(x => x !== Roles.User).map(x => translate(`pos.enums.RoleType.${x}`));
                setRoles(roles.join(' | '));
            }
        }
        else
            setRoles("");
    }, [isLoading, permissions])

    useEffect(() => {
        if (!isLoading) {
            refetch()
        }
    }, [userInfo])

    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu permissions={permissions} version={version} />}>
            {logo ? <img src={logo} alt="logo" style={{ ...logoStyle, height: '3vh' }} /> : null}
            {Children.count(children) === 0 ? (
                <Typography
                    sx={useStyles.title}
                    variant="h6"
                    color="inherit"
                />
            ) : (
                children
            )}
            <TitlePortal flex='auto' textAlign='center' />
            <CreateStudentButton />
            <Divider flexItem orientation='vertical' sx={{ borderColor: theme => theme.palette.common.white, my: 1, mr: 1 }} />
            {
                (!isLoading && permissions && permissions?.length > 0) &&
                <Typography
                    sx={useStyles.role}
                    variant="button"
                    color="inherit"
                >
                    {roles}
                </Typography>
            }
        </AppBar>
    );
};

export default Header;