import { Grid } from '@mui/material';

import {
    ArrayField,
    BooleanField,
    Datagrid,
    Labeled,
    TextField,
    useRecordContext,
} from 'react-admin';

const CustomShowExpand = () => {
    const record = useRecordContext();

    if (!record) return null;
    return (
        <Grid container>
            <Grid item xs={12}>
                <Labeled fullWidth>
                    <ArrayField label="bookings.menu.name" source="studentMiurYearCourses">
                        <Datagrid bulkActionButtons={false} optimized className='w-100'>
                            <TextField
                                label="resources.studentmiuryearcourse.fields.code"
                                source="code"
                            />
                            <TextField
                                label="resources.miurschools.fields.denominazioneScuola"
                                source="miurYearCourses.miurSchools.denominazioneScuola"
                            />
                            <TextField
                                label="resources.miuryearcourses.fields.annoCorso"
                                source="miurYearCourses.annoCorso"
                            />
                            <TextField
                                label="resources.miuryearcourses.fields.sezioneAnno"
                                source="miurYearCourses.sezioneAnno"
                            />
                            <BooleanField
                                label="resources.studentmiuryearcourse.fields.active"
                                source="active"
                            />
                        </Datagrid>
                    </ArrayField>
                </Labeled>
            </Grid>
            <Grid item xs={12}>
                <Labeled>
                    <TextField source="note" />
                </Labeled>
            </Grid>
        </Grid>
    );
};

export default CustomShowExpand;