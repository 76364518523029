import * as React from 'react';
import { useRecordContext, useResourceContext, useTranslate } from 'react-admin';

export default () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const translate = useTranslate();
    return (
        <span>
            {record ? translate(`${resource}.edit.title`, { title: `${record.codiceIsbn} - ${record.titolo}` }) : ''}
        </span>
    );
};
