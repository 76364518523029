export enum BookingStatusType {
    ToBook = "ToBook",
    NotToBook = "NotToBook",
    Booked = "Booked",
    PickedUp = "PickedUp",
    ToCover = "ToCover",
    Ready = "Ready",
    Completed = "Completed",
}

export enum BookingType {
    Scolastica = "Scolastica",
    Varia = "Varia",
}

export enum TipoGradoScuolaType {
    EE = "EE",
    MM = "MM",
    NO = "NO",
    NT = "NT",
} 

export enum ConsigliatoType {
    NO = "NO",
    AP = "AP",
    MO = "MO",
}

export enum Roles {
    Admin = "admin",
    User = "user",
}

export enum RoleGroups {
    Administrators = "administrators",
    Users = "users",
}

export enum UserStatusType {
    Active = "Active",
    NotActive = "NotActive",
}

export enum ProviderType {
    Local = "Local",
    Microsoft = "Microsoft",
    Google = "Google",
    Twitter = "Twitter",
    Instagram = "Instagram",
    Facebook = "Facebook",
    Unknown = "Unknown",
}

export enum MediaType {
    Generic = "Generic",
    DefaultProfilePicture = "DefaultProfilePicture",
}