export default {
    name: "miurdisciplines",
    resources: {
        name: 'Disciplina |||| Discipline',
        forcedCaseName: 'Disciplina |||| Discipline',
        fields: {
            disciplina: "Disciplina",
            
            miurAdoptions: "Adozioni",
        },
        groups: {
        },
    },
    global: {
        menu: {
            name: 'Discipline',
        },
        list: {
        },
        form: {
        },
        validation: {
        },
        edit: {
            title: 'Disciplina "%{title}"',
        },
        action: {
        },
        other: {
        },
    },
};
