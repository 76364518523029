import { Box, Chip, Divider, Grid, Stack, Typography } from '@mui/material';
import Icon from '@mui/icons-material/RemoveRedEyeOutlined';

import {
    InfiniteList,
    Button,
    useInfinitePaginationContext,
    useTranslate,
    WithListContext
} from 'react-admin';

import { BookingStatusType } from 'admin/types';
import { DashboardCard, DialogButton } from 'admin/components';
import { Utils } from 'admin/core';
import { ReadyBookingsDataGrid } from '../Common';
import { mapSchoolClassInfo, mapStudentDisplayName } from 'admin/mappers';

const LoadMore = () => {
    const {
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfinitePaginationContext();
    return hasNextPage ? (
        <Box mt={1} textAlign="center">
            <Button disabled={isFetchingNextPage} label="ra.action.load_more" onClick={() => fetchNextPage()} />
        </Box>
    ) : null;
};

interface Props {
    reloadDashboard?: () => void;
}

export type ReadyCardProps = Props;

const ReadyCard = (props: ReadyCardProps) => {
    const {
        reloadDashboard = () => { },
    } = props;
    const translate = useTranslate();

    const expand = [
        'bookings.studentMiurYearCourse.miurYearCourses.miurSchools',
        'bookings.studentMiurYearCourse.student'
    ];

    return (
        <InfiniteList
            title={" "}
            component="div"
            resource='bookingitems'
            actions={false}
            filter={{ currentStatus: BookingStatusType.Ready }}
            sort={{ field: 'updatedAt', order: 'ASC' }}
            queryOptions={{ meta: { expand: expand } }}
            perPage={25}
            storeKey={false}
            disableSyncWithLocation
            pagination={<LoadMore />}
            empty={false}
        >
            <WithListContext render={({ data }) => {
                if (!data || data.length === 0)
                    return null;

                return (
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Box sx={{ p: 0 }} display='flex' justifyContent='center'>
                                <Chip
                                    label={translate(`pos.enums.BookingStatusType.${BookingStatusType.Ready}`, { smart_count: data.length })}
                                    sx={{ fontSize: theme => theme.spacing(2.5) }}
                                    variant="outlined"
                                />
                                <Divider flexItem />
                            </Box>
                        </Grid>

                        {
                            Object.entries(Utils.GroupBy(data, "bookingsId")).map((item) => {
                                const key: string = item[0];
                                const values = item[1] as any[];

                                const bookingInfos = values[0].bookings;

                                const title = `${bookingInfos.studentMiurYearCourse.code} - ${mapStudentDisplayName(bookingInfos.studentMiurYearCourse.student)}`;
                                const schoolInfo = bookingInfos.studentMiurYearCourse.miurYearCourses.miurSchools.denominazioneScuola;
                                const classInfo = `${mapSchoolClassInfo(bookingInfos.studentMiurYearCourse.miurYearCourses)}`;

                                return (
                                    <Grid key={key} item xs={12} sm={3}>
                                        <DashboardCard useChildrenAsContent>
                                            <Stack spacing={1} sx={{ p: 1 }} alignItems="center">
                                                <Typography variant='subtitle2'>
                                                    {title}
                                                </Typography>
                                                <Typography variant='body2'>
                                                    {schoolInfo}
                                                </Typography>
                                                <Typography variant='body2'>
                                                    {classInfo}
                                                </Typography>

                                                <Typography variant='body2'>
                                                    {translate('resources.bookingitems.fields.quantity')}: {values.length}
                                                </Typography>
                                            </Stack>

                                            <Divider flexItem />

                                            <Box sx={{ p: 0 }} display='flex' justifyContent='center'>

                                                <DialogButton
                                                    icon={<Icon />}
                                                    label={"ra.action.open"}
                                                    dialogTitle={translate("bookings.other.readyToComplete", {
                                                        bookingCode: bookingInfos.studentMiurYearCourse.code,
                                                        studentName: mapStudentDisplayName(bookingInfos.studentMiurYearCourse.student)
                                                    })}
                                                    fullScreen
                                                    preventEscapeKeyDown
                                                >
                                                    <ReadyBookingsDataGrid rows={values} bookingInfos={bookingInfos} reloadDashboard={reloadDashboard} />
                                                </DialogButton>
                                            </Box>
                                        </DashboardCard>

                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            }} />
        </InfiniteList>
    )
};

export default ReadyCard;