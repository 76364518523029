import { ReactElement, ReactNode, cloneElement } from 'react';

import { Link, To } from 'react-router-dom';

import { alpha, useTheme } from '@mui/material/styles';
import { Card, Box, Typography, Divider, CircularProgress } from '@mui/material';

import { OverridableStringUnion } from '@mui/types';

interface Props {
    icon?: JSX.Element;
    to?: To;
    colorType?: OverridableStringUnion<
        'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
    >;
    color?: string;
    iconColor?: string;
    title?: string;
    subtitle?: ReactNode;
    loading?: boolean;
    useChildrenAsContent?: boolean;
    children?: ReactNode;
}

const DashboardCard = ({ icon, title, subtitle, to, colorType, color, iconColor, loading, useChildrenAsContent, children }: Props) => {
    const theme = useTheme();

    if (colorType) {
        iconColor = theme.palette[colorType].main;
        color = alpha(theme.palette[colorType].main, 0.3);
    }
    else {
        if (!iconColor)
            iconColor = theme.palette.primary.main;

        if (!color)
            color = alpha(iconColor, 0.3);
    }

    const content = (
        <Box
            sx={{
                height: '100%',
                overflow: 'inherit',
                position: 'relative',
                padding: theme => theme.spacing(2),
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .icon': {
                    color: iconColor,
                },
            }}
        >
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '5em',
                backgroundColor: color,
                borderRadius: '0 2.5em 2.5em 0'
            }}
            />
            {
                icon &&
                <Box width="3em" className="icon" sx={{ zIndex: 1 }}>
                    {
                        loading ?
                            <CircularProgress size="2em" sx={{ color: iconColor }} />
                            :
                            cloneElement(icon, { fontSize: 'large' })
                    }
                </Box>
            }
            <Box textAlign="right">
                <Typography color="textSecondary">{title}</Typography>
                {
                    subtitle &&
                    <Typography variant="h5" component="h2">
                        {subtitle || ' '}
                    </Typography>
                }
            </Box>
        </Box>
    )

    return (
        <Card
            sx={{
                minHeight: 60,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            }}
        >
            {
                useChildrenAsContent ?
                    children :
                    <>
                        {
                            to ?
                                <Link to={to}>
                                    {content}
                                </Link> :
                                content
                        }
                        {children && <Divider sx={{ borderColor: color }} />}
                        {children}
                    </>
            }
        </Card>
    )
};

export default DashboardCard;