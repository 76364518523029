import Icon from '@mui/icons-material/School';
import CustomCreate from './CustomCreate';
import CustomList from './CustomList';
import CustomShow from './CustomShow';

import { red } from '@mui/material/colors';

export default {
    list: CustomList,
    create: CustomCreate,
    show: CustomShow,
    icon: Icon,
    iconColor: red[400],
};