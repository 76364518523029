import { Theme, Tooltip, useMediaQuery } from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    NumberField,
    FunctionField,
    DateField,
} from 'react-admin';

import {
    ShowButton,
    ListRowActions,
    EditButton,
    DeleteWithConfirmButton,
} from 'admin/components';

import CustomShowExpand from './CustomShowExpand';
import FilterSidebar from './FilterSidebar';
import { Utils } from 'admin/core';
import { mapStudentDisplayName } from 'admin/mappers';

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    const expand = [
        'studentMiurYearCourse.miurYearCourses.miurSchools',
        'studentMiurYearCourse.student'
    ];

    return (
        <List
            hasCreate={false}
            actions={false}
            filterDefaultValues={{}}
            sort={{ field: 'studentMiurYearCourse.code', order: 'ASC' }}
            queryOptions={{
                meta: {
                    expand: expand
                }
            }}
            perPage={25}
            storeKey={false}
            aside={<FilterSidebar />}
            sx={{ marginTop: 2 }}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => mapStudentDisplayName(record.studentMiurYearCourse.student)}
                    secondaryText={record => `${record.studentMiurYearCourse.code}`}
                    linkType={false}
                />
            ) : (
                <Datagrid bulkActionButtons={false} expand={<CustomShowExpand />} expandSingle isRowExpandable={row => row.note} optimized>
                    <TextField
                        label="resources.studentmiuryearcourse.fields.code"
                        source="studentMiurYearCourse.code"
                    />
                    <TextField
                        label="resources.students.fields.familyName"
                        source="studentMiurYearCourse.student.familyName"
                    />
                    <TextField
                        label="resources.students.fields.givenName"
                        source="studentMiurYearCourse.student.givenName"
                    />
                    <TextField
                        label="resources.miurschools.fields.denominazioneScuola"
                        source="studentMiurYearCourse.miurYearCourses.miurSchools.denominazioneScuola"
                    />
                    <TextField
                        label="resources.miuryearcourses.fields.fullClassName"
                        source="studentMiurYearCourse.miurYearCourses.fullClassName"
                        sortable={false}
                    />
                    <NumberField source="advanceAmount" textAlign="center" options={{
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2
                    }} />
                    <DateField source="orderDate" />
                    <DateField source="closedAt" />
                    <ListRowActions>
                        <EditButton />
                        <DeleteWithConfirmButton disabledFn={(record) => record.closedAt !== null || record.cannotRemove} />
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
