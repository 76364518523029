import { Children, ReactNode } from 'react';
import PropTypes from 'prop-types';

import {
    TopToolbar,
    useEditContext,
    usePermissions,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { Utils } from 'admin/core';

import { BackButton, ListButton, ShowButton } from 'admin/components/buttons';

export interface EditActionsProps {
    children?: ReactNode | ReactNode[] | null;
    disabled?: boolean;
    hasList?: boolean;
    hasListFunc?: (data: any) => boolean;
    hasBack?: boolean;
    hasBackFunc?: (data: any) => boolean;
    hasShow?: boolean;
    hasShowFunc?: (data: any) => boolean;
}

const EditActions = (props: EditActionsProps) => {
    const {
        disabled = false,
        hasList = false,
        hasListFunc,
        hasBack = true,
        hasBackFunc,
        hasShow = true,
        hasShowFunc,
        children = [],
    } = props;

    const { permissions } = usePermissions();
    const editProps = useEditContext();

    const { isLoading, record } = editProps;

    return (
        <TopToolbar>
            {(hasBackFunc ? !isLoading && hasBackFunc(record) : hasBack) && <BackButton withText disabled={isLoading} />}
            {(hasListFunc ? !isLoading && hasListFunc(record) : hasList) && <ListButton withText disabled={isLoading} />}
            {(hasShowFunc ? !isLoading && hasShowFunc(record) : hasShow) && <ShowButton withText />}
            {Children.map(children, (child: any, index) => {
                const { disabled: disabledChild, ...childProps } = child.props

                const commonProps = {
                    key: index,
                    context: 'button',
                    disabled: disabled ||
                        isLoading ||
                        (disabledChild !== null && Utils.IsFunction(disabledChild) && disabledChild(record, isLoading, permissions)) ||
                        (Utils.IsBoolean(disabledChild) && disabledChild)
                }

                return Utils.CreateOrCloneElement(child, { ...commonProps, ...childProps })
            })}
        </TopToolbar>
    )
};

EditActions.propTypes = {
    disabled: PropTypes.bool,
    hasList: PropTypes.bool,
    hasBack: PropTypes.bool,
    hasShow: PropTypes.bool,
    children: PropTypes.node,
};

EditActions.displayName = 'EditActions';

export default EditActions;