import { Theme, useMediaQuery } from '@mui/material';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    ImageField,
    TextInput,
} from 'react-admin';

import {
    ShowButton,
    ListActions,
    ListRowActions,
    EditButton,
    DeleteWithConfirmButton,
} from 'admin/components';

const filters = [
    <TextInput source="codiceIsbn" alwaysOn />,
    <TextInput source="titolo" alwaysOn />
];

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            actions={<ListActions />}
            filters={filters}
            filterDefaultValues={{}}
            sort={{ field: 'titolo', order: 'ASC' }}
            storeKey={false}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.codiceIsbn} - ${record.titolo}`}
                    // secondaryText={record => `${record.id}`}
                    // tertiaryText={record => record.xxx}
                    linkType="edit"
                />
            ) : (
                <Datagrid bulkActionButtons={false} optimized>
                    <TextField source="codiceIsbn" />
                    <TextField source="autori" />
                    <TextField source="titolo" />
                    <TextField source="sottotitolo" />
                    <TextField source="categorie" />
                    <DateField source="dataPubblicazione" />
                    <TextField source="lingua" />
                    <ImageField source="image" sx={{ '& img': { maxWidth: 40, maxHeight: 40, objectFit: 'contain' } }} />
                    <NumberField source="prezzo" options={{
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2
                    }} />
                    <ListRowActions>
                        <EditButton />
                        <DeleteWithConfirmButton />
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
