import { useEffect, useState } from 'react';

import { Typography, Box } from '@mui/material';

import {
    DataGrid,
    GridColDef,
    GridSortDirection,
    GridToolbarQuickFilter,
    GridSlotsComponentsProps,
    GridSlotsComponent,
} from '@mui/x-data-grid';

import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

import {
    Loading,
    useDataProvider,
    useLoading,
    useRecordContext,
    useTranslate
} from 'react-admin';

import { BookingStatusTypeIcon, BookingTypeIcon } from 'admin/views/Common';
import { UncapitalizeObjectKeys } from '@mui/x-data-grid/internals';
import { BookingStatusType, BookingType } from 'admin/types';
import { mapStudentDisplayName } from 'admin/mappers';

interface Props {
    source?: string;
    filter?: any;
    sortField?: string;
    sortDirection?: GridSortDirection;
    buildFilter?: (record: any) => any,
    showFilter?: boolean;
    showStudentCode?: boolean;
}

export type BookingItemsDataGridProps = Props;

const BookingItemsDataGridField = (props: BookingItemsDataGridProps) => {
    const {
        source,
        filter = {},
        sortField = 'disciplina',
        sortDirection = 'asc',
        buildFilter,
        showFilter = false,
        showStudentCode = false,
    } = props;

    const [rows, setRows] = useState<any>([]);

    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const isLoading = useLoading();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [dataGridOptions, setDataGridOptions] = useState<any>({});
    const translate = useTranslate();

    useEffect(() => {
        const getTotalPrice = () => rows.reduce((total, row) => total + (row?.currentStatus !== BookingStatusType.NotToBook ? row?.prezzo : 0), 0);

        let initialState: GridInitialStateCommunity = {
            sorting: {
                sortModel: [{ field: sortField, sort: sortDirection }],
            },
        };

        let slots: UncapitalizeObjectKeys<Partial<GridSlotsComponent>> = {
            noRowsOverlay: () => (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}>
                    <Typography variant="h6" gutterBottom>
                        {translate("bookings.edit.noBookingitems")}
                    </Typography>
                </Box>
            )
        }

        let slotProps: GridSlotsComponentsProps = {};

        slots.footer = () => {
            const total = getTotalPrice().toFixed(2);
            return (
                <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }} gutterBottom>
                        {`${total} €`}
                    </Typography>
                </Box>
            )
        };

        if (showFilter) {
            initialState.filter = {
                filterModel: {
                    items: [],
                    quickFilterValues: [],
                },
            }

            slots.toolbar = () => (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <GridToolbarQuickFilter placeholder={translate("ra.action.search")} />
                </Box>
            );

            slotProps.toolbar = {
                showQuickFilter: true,
            };
        }

        let _dataGridOptions = {
            autoHeight: true,
            disableRowSelectionOnClick: true,
            disableColumnMenu: true,
            disableColumnFilter: true,
            disableColumnSelector: true,
            disableDensitySelector: true,
            initialState: initialState,
            slots: slots,
            slotProps: slotProps,
        }

        setDataGridOptions(_dataGridOptions);
    }, [rows, showFilter, sortDirection, sortField, translate]);

    const getData = () => {
        let _filter = filter;

        if (buildFilter) {
            _filter = buildFilter(record);
        }
        else if (record && source) {
            _filter = { [source]: record[source] };
        }

        if (_filter) {
            const expand = [
                'bookings.studentMiurYearCourse.miurYearCourses.miurSchools',
                'bookings.studentMiurYearCourse.student'
            ];
            dataProvider.getList(
                'bookingitems',
                {
                    pagination: { page: 1, perPage: 2000 },
                    sort: { field: 'updatedAt', order: 'DESC' },
                    filter: _filter,
                    meta: { expand: expand }
                })
                .then(({ data }) => {
                    setRows(data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error(error);
                    setError(error);
                    setLoading(false);
                })
        }
        else
            setLoading(false);
    }

    useEffect(() => {
        if (!isLoading || (isLoading && !loading)) {
            getData();
        }
    }, [isLoading]);

    const getHeaderName = (fieldName, resource = 'bookingitems') => translate(`resources.${resource}.fields.${fieldName}`);

    const columns: GridColDef[] = [
        {
            field: 'codiceIsbn',
            width: 160,
            headerName: getHeaderName('codiceIsbn'),
            valueGetter: (params) => params.row?.codiceIsbn,
            renderCell: (params) => {
                return (
                    <Typography variant="body2" component="span" display="flex" alignItems="center" justifyContent="start" gutterBottom>
                        {params.row?.codiceIsbn}
                    </Typography>
                )
            }
        },
        {
            field: 'titolo',
            flex: 2,
            headerName: getHeaderName('titolo'),
            valueGetter: (params) => params.row?.titolo
        },
        {
            field: 'sottotitolo',
            flex: 1,
            headerName: getHeaderName('sottotitolo'),
            valueGetter: (params) => params.row?.sottotitolo
        },
        {
            field: 'autori',
            flex: 1,
            headerName: getHeaderName('autori'),
            valueGetter: (params) => params.row?.autori
        },
        {
            field: 'editore',
            flex: 1,
            headerName: getHeaderName('editore'),
            valueGetter: (params) => params.row?.editore
        },
        {
            field: 'otherInfo',
            flex: 1,
            headerName: getHeaderName('otherInfo'),
            valueGetter: (params) => params.row?.bookingType === BookingType.Scolastica ? params.row?.disciplina : params.row?.categorie
        },
        {
            field: 'bookingType',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            headerName: getHeaderName('bookingType'),
            renderCell: (params) => <BookingTypeIcon value={params.row?.bookingType} />
        },
        {
            field: 'currentStatus',
            width: 80,
            headerAlign: 'center',
            align: 'center',
            headerName: getHeaderName('currentStatus'),
            renderCell: (params) => <BookingStatusTypeIcon value={params.row?.currentStatus} />
        },
        {
            field: 'statusDateTime',
            type: 'dateTime',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            headerName: getHeaderName('statusDateTime'),
            valueGetter: (params) => params.row?.statusDateTime ? new Date(params.row?.statusDateTime) : null
        },
        {
            field: 'prezzo',
            width: 100,
            headerAlign: 'right',
            align: 'right',
            headerName: getHeaderName('prezzo'),
            valueGetter: (params) => `${params.row?.prezzo.toFixed(2)} €`
        },
    ];

    if (showStudentCode) {
        columns.splice(0, 0, {
            field: 'studentCode',
            flex: 1,
            headerName: getHeaderName('studentName', 'bookings'),
            valueGetter: (params) => `${params.row?.bookings.studentMiurYearCourse.code} - ${mapStudentDisplayName(params.row?.bookings.studentMiurYearCourse.student)}`
        })
    }

    if (loading) return <Loading />;
    if (error) { return <p>ERROR</p>; }

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            density={'compact'}
            hideFooterPagination={true}
            loading={loading}
            {...dataGridOptions}
            sx={{
                '& .MuiDataGrid-cell:focus': {
                    outline: 'none !important'
                }
            }}
        />
    );
};

export default BookingItemsDataGridField;
