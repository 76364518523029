import { forwardRef, useImperativeHandle, ReactNode, useState } from 'react';
import { ReactElement } from 'react';

import Icon from '@mui/icons-material/Add';

import {
    Button,
} from 'react-admin';

import CreateFormDialog from './CreateFormDialog';
import { Utils } from 'admin/core';
import { FormDialogProps } from '../FormDialog';

export interface CreateDialogButtonActions {
    open(): void;
    close(): void;
    isOpen?: boolean;
}

interface Props {
    icon?: ReactElement;
    label?: string;
    resource: string;
    onSuccess?: (data: any) => void;
    children: ReactNode;
    dialogProps?: FormDialogProps;
    showLabel?: Boolean;
    disabled?: Boolean;
}

export type CreateDialogButtonProps = Props;

const CreateDialogButton = forwardRef<CreateDialogButtonActions, CreateDialogButtonProps>((props: CreateDialogButtonProps, ref) => {
    const {
        icon = defaultIcon,
        label = 'ra.action.create',
        resource,
        onSuccess = () => { },
        children,
        dialogProps,
        showLabel = false,
        disabled = false,
        ...rest
    } = props;

    const [innerOpen, setInnerOpen] = useState<boolean>(dialogProps?.open || false);

    useImperativeHandle(
        ref,
        () => ({
            open() {
                setInnerOpen(true);
            },
            close() {
                setInnerOpen(false);
            },
            isOpen: innerOpen
        }));

    const handleOpen = () => {
        if (!innerOpen) {
            setInnerOpen(true);
        }
    };

    const handleClose = () => {
        if (innerOpen) {
            setInnerOpen(false);
        }
    };

    return (
        <>
            <Button label={showLabel ? label : ''} onClick={handleOpen} disabled={disabled} {...(rest as any)}>
                {icon}
            </Button>
            <CreateFormDialog
                resource={resource}
                onCreateSuccess={onSuccess}
                dialogProps={{
                    open: innerOpen,
                    onClosed: handleClose,
                    ...dialogProps,
                }}
            >
                {Utils.CreateOrCloneElement(children)}
            </CreateFormDialog>
        </>
    );
});

const defaultIcon = <Icon />;

export default CreateDialogButton;
