import { Grid, Divider, Typography, } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    useTranslate,
    FunctionField,
    WrapperField,
    WithRecord,
} from 'react-admin';

import { ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';

import { Utils } from 'admin/core';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

import { GetBookingStatusTypeColor, BookingStatusTypeIcon } from 'admin/views/Common/BookingStatusTypeField';

interface BookingItemTimelineProps {
    bookingItemHistories: any[];
}

function BookingItemTimeline({ bookingItemHistories = [], ...props }: BookingItemTimelineProps) {
    const translate = useTranslate();

    if (!bookingItemHistories) return null;

    return (
        <Timeline position="alternate">
            {
                bookingItemHistories.sort(Utils.CompareValues("dateTime")).map((bookingItemHistory, key) => {
                    return (
                        <TimelineItem key={key}>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                variant="body2"
                                color="text.secondary"
                            >
                                {Utils.FormatToDate(bookingItemHistory.dateTime, "it", true)}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot variant="outlined" sx={{ color: GetBookingStatusTypeColor(bookingItemHistory.status).iconColor }}>
                                    <BookingStatusTypeIcon value={bookingItemHistory.status} />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ display: 'flex', alignItems: 'center', justifyContent: key % 2 === 0 ? 'flex-start' : 'flex-end' }}>
                                <Typography variant="h6" component="span">
                                    {translate(`pos.enums.BookingStatusType.${bookingItemHistory.status}`, { smart_count: 1 })}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })
            }
        </Timeline>
    );
}

const CustomShow = props => {
    const translate = useTranslate();

    const expand = [
        'bookingItemHistories',
        'bookings.studentMiurYearCourse.miurYearCourses.miurSchools',
        'bookings.studentMiurYearCourse.student'
    ];

    return (
        <Show {...props} actions={<ShowActions hasEdit={false} />} title={<CustomTitle />} queryOptions={{ meta: { expand: expand } }}>
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                            <Grid container item xs={12} justifyContent="center" alignItems="flex-start">
                                <Grid container item xs={12} justifyContent="center" alignItems="center" direction="column">
                                    <WrapperField>
                                        <Divider flexItem>
                                            {translate('resources.miuradoptions.name', { smart_count: 1 })}
                                        </Divider>
                                        <FunctionField render={record => <Typography variant="h4" gutterBottom>{record.titolo}</Typography>} />
                                        <FunctionField render={record => <Typography variant="h5" gutterBottom>{record.sottotitolo}</Typography>} />

                                        <Divider flexItem>
                                            {translate('resources.bookingitems.fields.volume')}
                                        </Divider>

                                        <FunctionField render={record => <Typography variant="h5" gutterBottom>{record.volume ?? "-"}</Typography>} />

                                        <Divider flexItem>
                                            {translate('resources.bookingitems.fields.codiceIsbn')}
                                        </Divider>

                                        <FunctionField render={record => <Typography variant="h5" gutterBottom>{record.codiceIsbn}</Typography>} />

                                        <Divider flexItem>
                                            {translate('resources.miurdisciplines.name', { smart_count: 1 })}
                                        </Divider>

                                        <FunctionField render={record => <Typography variant="h5" gutterBottom>{record.disciplina}</Typography>} />

                                        <Divider flexItem>
                                            {translate('resources.bookingitems.fields.editore')}
                                        </Divider>
                                        <FunctionField render={record => <Typography variant="h5" gutterBottom>{record.editore}</Typography>} />

                                        <Divider flexItem>
                                            {translate('resources.bookingitems.fields.prezzo')}
                                        </Divider>

                                        <FunctionField render={record => <Typography variant="h4" gutterBottom>{`${record.prezzo.toFixed(2)} €`}</Typography>} />
                                    </WrapperField>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <WithRecord label="" render={record => <BookingItemTimeline bookingItemHistories={record.bookingItemHistories} />} />
                    </Grid>
                </Grid>

            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;