import { useEffect, useState } from 'react';
import { ReactElement } from 'react';
import PropTypes from 'prop-types';

import { Grid, Stack, Chip, useTheme, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Mail';
import NoEmailIcon from '@mui/icons-material/MailOutline';

import {
    RaRecord,
    useRecordContext,
} from 'ra-core';

import {
    ButtonProps,
    DateField,
    Labeled,
    RichTextField,
    Show,
    SimpleShowLayout,
    useDataProvider,
    useNotify,
    useTranslate
} from 'react-admin';

import { DialogButton } from 'admin/components';
import { Utils } from 'admin/core';

const FromAddressesField = () => {
    const record = useRecordContext();
    return (
        <Stack direction="row" spacing={1}>
            {record.fromAddresses.map((fromAddress, key) => (
                <Chip key={key} label={fromAddress} variant="outlined" />
            ))}
        </Stack>
    )
};

const AttachmentsField = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const notify = useNotify();

    const download = (emailAttachment) => {
        const { id, fileName } = emailAttachment;

        if (id !== null) {
            dataProvider.getFile(`api/emailattachments/download/${id}`).then((blob) => {
                Utils.DownloadFile(blob, fileName);
            }).catch(error => {
                console.error(error);
                notify('Error', { type: 'warning' })
            });
        }
    };

    return (
        <Stack direction="row" spacing={1}>
            {
                record.emailAttachments.length > 0 ?
                    record.emailAttachments.map((emailAttachment, key) => (
                        <Chip key={key} label={emailAttachment.fileName} onClick={() => download(emailAttachment)} variant="outlined" />
                    )) :
                    translate("emailbooks.other.showEmail.noAttachments")
            }
        </Stack>
    )
};

const ShowEmailButton = <RecordType extends RaRecord = any>(
    props: ShowButtonProps<RecordType>
) => {
    const {
        emailIcon = defaultEmailIcon,
        noEmailIcon = defaultNoEmailIcon,
        source = null,
    } = props;

    const theme = useTheme();
    const record = useRecordContext(props);
    const translate = useTranslate();
    const [emailId, setEmailId] = useState(undefined);

    useEffect(() => {
        if (source && record) {
            setEmailId(record[source]);
        }
    }, [record, source]);

    return (
        <DialogButton
            color={emailId ? theme.palette.success.main : theme.palette.primary.main}
            icon={emailId ? emailIcon : noEmailIcon}
            label={emailId ? "emailbooks.action.showEmail" : "emailbooks.action.noEmail"}
            dialogTitle={translate("emailbooks.other.showEmail.dialogTitle")}
        >
            {
                emailId ?
                    <Show
                        resource="emailmessages"
                        component="div"
                        id={emailId}
                        actions={false}
                        queryOptions={{ meta: { expand: ['emailAttachments'] } }}
                        title=""
                        sx={{
                            marginTop: 0,
                            '& .RaShow-main': {
                                marginTop: 0,
                            },
                        }}
                        emptyWhileLoading
                    >
                        <SimpleShowLayout>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Labeled source={translate("emailbooks.other.showEmail.sentFrom")}>
                                        <FromAddressesField />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12}>
                                    <Labeled source={translate("emailbooks.other.showEmail.sentDate")}>
                                        <DateField source="date" showTime />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12}>
                                    <Labeled source={translate("emailbooks.other.showEmail.attachments")}>
                                        <AttachmentsField />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12}>
                                    <Labeled source={translate("emailbooks.other.showEmail.html")}>
                                        <RichTextField source="htmlBody" fullWidth />
                                    </Labeled>
                                </Grid>
                            </Grid>
                        </SimpleShowLayout>
                    </Show> :
                    <Typography variant="h6">
                        {translate("emailbooks.action.noEmail")}
                    </Typography>
            }
        </DialogButton>
    );
};

const defaultEmailIcon = <EmailIcon />;
const defaultNoEmailIcon = <NoEmailIcon />;

interface Props<RecordType extends RaRecord = any> {
    emailIcon?: ReactElement;
    noEmailIcon?: ReactElement;
    label?: string;
    record?: RecordType;
    source?: string;
}

export type ShowButtonProps<RecordType extends RaRecord = any> = Props<
    RecordType
> &
    ButtonProps;

ShowEmailButton.propTypes = {
    emailIcon: PropTypes.element,
    noEmailIcon: PropTypes.element,
    label: PropTypes.string,
    source: PropTypes.string,
};

export default ShowEmailButton;
