import { useState } from 'react';

import { Box, Chip, Grid, Tooltip } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    ReferenceArrayField,
    useRecordContext,
    WithListContext,
    useTranslate,
} from 'react-admin';

import ClassAdoptions from './ClassAdoptions';
import ClassBooking from './ClassBooking';
import { mapSchoolClassInfo } from 'admin/mappers';

const ClassAdoptionsButton = ({ miurYearCourse }) => {
    const [open, setOpen] = useState(false);
    const translate = useTranslate();

    const handleOpen = e => {
        e.stopPropagation();
        setOpen(true);
    };

    var hasAdoptions = miurYearCourse.miurAdoptionsIds.length > 0;

    return (
        <Box>
            {
                hasAdoptions ? (
                    <Tooltip title={translate("miuradoptions.other.adoptionsCount", { smart_count: miurYearCourse.miurAdoptionsIds.length })}>
                        <Chip onClick={handleOpen} color="success" label={mapSchoolClassInfo(miurYearCourse)} />
                    </Tooltip>
                ) : (
                    <Tooltip title={translate("miuradoptions.other.noAdoptions")}>
                            <Chip color="error" label={mapSchoolClassInfo(miurYearCourse)} />
                    </Tooltip>
                )
            }
            <ClassAdoptions open={open} setOpen={setOpen} miurYearCourse={miurYearCourse} />
            <ClassBooking miurYearCourse={miurYearCourse} />
        </Box>
    );
};

const CustomShowExpand = () => {
    const record = useRecordContext();

    if (!record) return null;
    return (
        <Show id={record.id} actions={false}>
            <SimpleShowLayout>
                <ReferenceArrayField source="miurYearCoursesIds" label={false} reference="miurYearCourses" >
                    <WithListContext render={({ data }) => (
                        <Grid container spacing={2}>
                            {data.map(miurYearCourse => (
                                <Grid key={miurYearCourse.id} item xs={12} sm={2} display='flex' alignItems='center' justifyContent='center'>
                                    <ClassAdoptionsButton miurYearCourse={miurYearCourse} />
                                </Grid>
                            ))}
                        </Grid>
                    )} />
                </ReferenceArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShowExpand;