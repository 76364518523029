const englishMessages = {
    ra: {
        action: {
            add_filter: 'Add filter',
            add: 'Add',
            add_custom: 'Add %{name}',
            back: 'Go Back',
            bulk_actions: '1 item selected |||| %{smart_count} items selected',
            cancel: 'Cancel',
            clear_filter: 'Clear filter',
            clear_array_input: 'Clear the list',
            clear_input_value: 'Clear value',
            clone: 'Clone',
            confirm: 'Confirm',
            create: 'Create',
            create_item: 'Create %{item}',
            delete: 'Delete',
            soft_delete: 'Soft Delete',
            edit: 'Edit',
            export: 'Export',
            import: 'Import',
            download: 'Download file',
            upload: 'Upload file',
            list: 'List',
            refresh: 'Refresh',
            remove_filter: 'Remove this filter',
            remove_all_filters: 'Remove all filters',
            remove: 'Remove',
            remove_custom: 'Remove %{name}',
            save: 'Save',
            save_and_edit: "Save and edit",
            save_and_show: "Save and show",
            save_and_add: "Save and add",
            bulkEnabled: 'Enabled all',
            search: 'Search',
            select_all: 'Select all',
            select_row: 'Select this row',
            show: 'Show',
            sort: 'Sort',
            undo: 'Undo',
            unselect: 'Unselect',
            expand: 'Expand',
            close: 'Close',
            close_all: 'Close All',
            open_menu: 'Open menu',
            close_menu: 'Close menu',
            update: 'Update',
            move_up: 'Move up',
            move_down: 'Move down',
            scroll_to_top: 'Scroll to top',
            mode: {
                sortableList: 'Sort List',
            },
            showHtmlPreview: 'Show HTML',
            getFile: 'Download',
            sync: 'Sync',
            manage: 'Manage',
            restore: 'Restore',
            open: 'Open',
            toggle_theme: 'Toggle light/dark mode',
            select_columns: 'Columns',
            update_application: 'Reload Application',
            load_more: 'Load more',
        },
        boolean: {
            true: 'Yes',
            false: 'No',
            null: 'All',
        },
        page: {
            create: 'Create %{name}',
            dashboard: 'Dashboard',
            edit: '%{name}',
            error: 'Something went wrong',
            list: '%{name}',
            loading: 'Loading',
            not_found: 'Not Found',
            show: '%{name}',
            empty: 'No %{name} yet.',
            invite: 'Do you want to add one?',
            invite_more: 'Do you want to add records?',
            initialize_table: 'Do you want to initialize the table?',
            unauthorized: 'Access to this page is not allowed',
        },
        input: {
            file: {
                not_found: 'Document not found or damaged',
                upload_several: 'Drop some files to upload, or click to select one',
                upload_single: 'Drop a file to upload, or click to select it',
                uploaded_single: 'Ok! Document uploaded',
                uploaded_several: 'Ok! Documents uploaded',
            },
            image: {
                upload_several:
                    'Drop some pictures to upload, or click to select one.',
                upload_single:
                    'Drop a picture to upload, or click to select it.',
            },
            references: {
                all_missing: 'Unable to find references data.',
                many_missing:
                    'At least one of the associated references no longer appears to be available.',
                single_missing:
                    'Associated reference no longer appears to be available.',
            },
            password: {
                toggle_visible: 'Hide password',
                toggle_hidden: 'Show password',
            },
            sortable: {
                all_selected: 'All items selected',
                selected: '%{count} selected',
                choices: 'Choices',
                chosen: 'Chosen',
            },
            copy: 'Copy text',
            copied: 'Text copied',
            copy_custom: 'Copy \'%{name}\' value',
            copied_custom: '\'%{name}\' value copied',
        },
        message: {
            about: 'About',
            are_you_sure: 'Are you sure?',
            bulk_delete_content: 'Are you sure you want to delete this item? |||| Are you sure you want to delete these %{smart_count} items?',
            bulk_delete_title: 'Delete selected item |||| Delete %{smart_count} elements',
            clear_array_input: 'Are you sure you want to clear the whole list?',
            delete_content: 'Are you sure you want to delete this item?',
            delete_title: 'Delete selected item',
            details: 'Details',
            error: "A client error occurred and your request couldn't be completed.",
            empty: "No data available",
            invalid_form: 'The form is not valid. Please check for errors',
            loading: 'The page is loading, just a moment please',
            no: 'No',
            not_found: 'Either you typed a wrong URL, or you followed a bad link.',
            yes: 'Yes',
            unsaved_changes: "Some of your changes weren't saved. Are you sure you want to ignore them?",
            unauthorized: 'Insufficient permissions',
            or: 'Or',
            soft_delete_content: 'Are you sure you want to soft delete this item?',
            soft_delete_title: 'Soft Delete selected item',
            delete_content_restore: 'Are you sure you want to restore this item?',
            delete_title_restore: 'Restore selected item',
        },
        navigation: {
            no_results: 'No results found',
            no_more_results:
                'The page number %{page} is out of boundaries. Try the previous page.',
            page_out_of_boundaries: 'Page number %{page} out of boundaries',
            page_out_from_end: 'Cannot go after last page',
            page_out_from_begin: 'Cannot go before page 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
            partial_page_range_info: '%{offsetBegin}-%{offsetEnd} of more than %{offsetEnd}',
            current_page: 'Page %{page}',
            page: 'Go to page %{page}',
            first: 'Go to first page',
            last: 'Go to last page',
            next: 'Next',
            previous: 'Prev',
            page_rows_per_page: 'Rows per page:',
            skip_nav: 'Skip to content',
        },
        sort: {
            sort_by: 'Sort by %{field} %{order}',
            ASC: 'ascending',
            DESC: 'descending',
        },
        auth: {
            auth_check_error: 'Please login to continue',
            user_menu: 'Profile',
            username: 'Username',
            password: 'Password',
            sign_in: 'Sign in',
            sign_in_error: 'Authentication failed, please retry',
            reset_password: 'Reset Password',
            reset_password_title: 'Reset password',
            reset_password_content: 'Are you sure you want to reset the password for \'%{email}\'?',
            reset_password_error: 'Password reset failed, please retry.',
            reset_password_success: 'Password resetted',
            logout: 'Logout',
            forgot_password: 'Forgot password',
            back_to_homepage: 'Back to homepage',
            back_to_loginpage: 'Back to login',
            social_login_button: {
                google: 'Sign in with Google',
                microsoft: 'Sign in with Microsoft',
            }
        },
        notification: {
            updated: 'Element updated |||| %{smart_count} elements updated',
            updated_more: 'More elements updated',
            created: 'Element created',
            deleted: 'Element deleted |||| %{smart_count} elements deleted',
            bad_item: 'Incorrect element',
            item_doesnt_exist: 'Element does not exist',
            http_error: 'Server communication error',
            data_provider_error: 'dataProvider error. Check the console for details.',
            i18n_error: 'Cannot load the translations for the specified language',
            canceled: 'Action cancelled',
            logged_out: 'Your session has ended, please reconnect.',
            not_authorized: "You're not authorized to access this resource.",
            application_update_available: 'A new version is available.',
            mode: {
                sortableListUpdated: 'New sorting list saved',
                sortableListNotUpdated: 'New sorting list not saved',
            },
            file: {
                not_found: 'Document not found or damaged',
            }
        },
        validation: {
            required: 'Required',
            minLength: 'Must be %{min} characters at least',
            maxLength: 'Must be %{max} characters or less',
            minValue: 'Must be at least %{min}',
            maxValue: 'Must be %{max} or less',
            number: 'Must be a number',
            email: 'Must be a valid email',
            emailNotUnique: 'Email is already taken',
            tel: 'Must be a valid telephone number',
            oneOf: 'Must be one of: %{options}',
            regex: 'Must match a specific format (regexp): %{pattern}',
            bool: 'Required',
            iban: 'Must be a valid IBAN code',
            dropZone: {
                'file-invalid-type': 'File type must be %{messageSuffix} |||| File type must be one of %{messageSuffix}',
                'file-too-large': 'File is larger than %{maxSize}',
                'file-too-small': 'File is smaller than %{minSize}',
                'too-many-files': 'Too many files',
            },
            unique: 'Must be unique',
        },
        saved_queries: {
            label: 'Saved queries',
            query_name: 'Query name',
            new_label: 'Save current query...',
            new_dialog_title: 'Save current query as',
            remove_label: 'Remove saved query',
            remove_label_with_name: 'Remove query "%{name}"',
            remove_dialog_title: 'Remove saved query?',
            remove_message: 'Are you sure you want to remove that item from your list of saved queries?',
            help: 'Filter the list and save this query for later',
        },
        configurable: {
            customize: 'Customize',
            configureMode: 'Configure this page',
            inspector: {
                title: 'Inspector',
                content: 'Hover the application UI elements to configure them',
                reset: 'Reset Settings',
                hideAll: 'Hide All',
                showAll: 'Show All',
            },
            Datagrid: {
                title: 'Datagrid',
                unlabeled: 'Unlabeled column #%{column}',
            },
            SimpleForm: {
                title: 'Form',
                unlabeled: 'Unlabeled input #%{input}',
            },
            SimpleList: {
                title: 'List',
                primaryText: 'Primary text',
                secondaryText: 'Secondary text',
                tertiaryText: 'Tertiary text',
            },
        },
        apiError: {
            DefaultError: "Error",
            ConcurrencyFailure: "Concurrency Failure",
            PasswordMismatch: "Password Mismatch",
            InvalidToken: "Invalid Token",
            RecoveryCodeRedemptionFailed: "Recovery Code Redemption Failed",
            LoginAlreadyAssociated: "Login Already Associated",
            InvalidUserName: "Invalid UserName",
            InvalidEmail: "Invalid Email",
            DuplicateUserName: "Duplicate UserName",
            DuplicateEmail: "Duplicate Email",
            InvalidRoleName: "Invalid Role Name",
            DuplicateRoleName: "Duplicate Role Name",
            UserAlreadyHasPassword: "User Already Has Password",
            UserLockoutNotEnabled: "User Lockout Not Enabled",
            UserAlreadyInRole: "User Already In Role",
            UserNotInRole: "User Not In Role",
            PasswordTooShort: "Password Too Short",
            PasswordRequiresUniqueChars: "Password Requires Unique Chars",
            PasswordRequiresNonAlphanumeric: "Password Requires Non Alphanumeric",
            PasswordRequiresDigit: "Password Requires Digit",
            PasswordRequiresLower: "Password Requires Lower",
            PasswordRequiresUpper: "Password Requires Upper",
        },
        google: {
            maps: {
                noLocations: "No locations",
                typeLocation: "Search location"
            }
        }
    },
};

export default englishMessages;