import { Card, CardContent, Divider } from '@mui/material';

import {
    FilterList,
    FilterListItem,
    useGetList,
    FilterLiveSearch,
    useTranslate,
} from 'react-admin';
import { BookingTypeIcon, BookingStatusTypeIcon } from 'admin/views/Common';

const FilterSidebar = () => {
    const translate = useTranslate();

    const { data: bookingStatusTypes } = useGetList('api/enums/bookingStatusType', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
    });
    const { data: bookingTypes } = useGetList('api/enums/bookingType', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
    });

    return (
        <Card
            sx={{
                display: { xs: 'none', md: 'block' },
                order: -1,
                width: '15em',
                mr: 2,
                alignSelf: 'flex-start',
                minWidth: 280
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterList label="bookingitems.filters.status" icon={false}>
                    {bookingStatusTypes &&
                        bookingStatusTypes.map((record) => (
                            <FilterListItem
                                icon={<BookingStatusTypeIcon value={record.id} />}
                                label={translate(`pos.enums.BookingStatusType.${record.value}`, { smart_count: 1 })}
                                key={record.id}
                                value={{
                                    currentStatus: record.id
                                }}
                            />
                        ))}
                </FilterList>

                <FilterList label="bookingitems.filters.type" icon={false}>
                    {bookingTypes &&
                        bookingTypes.map((record) => (
                            <FilterListItem
                                icon={<BookingTypeIcon value={record.id} />}
                                label={translate(`pos.enums.BookingType.${record.value}`, { smart_count: 1 })}
                                key={record.id}
                                value={{
                                    bookingType: record.id
                                }}
                            />
                        ))}
                </FilterList>

                <Divider flexItem sx={{ mt: 1 }} />

                <FilterList label="" icon={false}>
                    <FilterLiveSearch
                        label="resources.studentmiuryearcourse.fields.code"
                        source="bookings/studentMiurYearCourse/code"
                        variant='outlined'
                    />
                    <FilterLiveSearch
                        label="resources.students.fields.familyName"
                        source="bookings/studentMiurYearCourse/student/familyName"
                        variant='outlined'
                    />
                    <FilterLiveSearch
                        label="resources.students.fields.givenName"
                        source="bookings/studentMiurYearCourse/student/givenName"
                        variant='outlined'
                    />
                    <FilterLiveSearch
                        label="resources.miuradoptions.fields.codiceIsbn"
                        source="codiceIsbn"
                        variant='outlined'
                    />
                    <FilterLiveSearch
                        label="resources.miuradoptions.fields.titolo"
                        source="titolo"
                        variant='outlined'
                    />
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default FilterSidebar;