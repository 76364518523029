import { Grid, Divider, Typography, Stack } from '@mui/material';

import ImageIcon from '@mui/icons-material/Image';

import {
    Show,
    SimpleShowLayout,
    BooleanField,
    useTranslate,
    FunctionField,
    Labeled,
    TextField,
    ReferenceField
} from 'react-admin';

import { DialogButton, ShowActions } from 'admin/components';
import { BookingItemsDataGridField } from 'admin/views/Common';

import CustomTitle from './CustomTitle';

import CloseableButton from './CloseableButton';
import { mapStudentDisplayName } from 'admin/mappers';

const CustomShow = props => {
    const translate = useTranslate();

    const expand = [
        'studentMiurYearCourse.miurYearCourses.miurSchools',
        'studentMiurYearCourse.student'
    ];

    return (
        <Show {...props} actions={<ShowActions />} title={<CustomTitle />} queryOptions={{ meta: { expand: expand } }}>
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid container item xs={12} justifyContent="center" alignItems="flex-start">
                        <Grid container item xs={12} sm={6} justifyContent="center" alignItems="center" direction="column">
                            <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={0}>
                                <ReferenceField source="studentMiurYearCourseId" reference="studentmiuryearcourses" link={false}>
                                    <ReferenceField source="studentId" reference="students" link={false}>
                                        <FunctionField render={record =>
                                            <Typography variant="h3" textAlign='center' gutterBottom>{mapStudentDisplayName(record)}
                                                <DialogButton
                                                    icon={<ImageIcon sx={{ fontSize: theme => `${theme.spacing(4)} !important`, marginLeft: theme => theme.spacing(2) }} />}
                                                    label={translate("resources.students.fields.fiscalCode")}
                                                    dialogTitle={translate("resources.students.fields.fiscalCode")}
                                                >
                                                    <Typography variant="h4" textAlign='center' gutterBottom>{record.fiscalCode}</Typography>
                                                    {
                                                        record.fiscalCodeUrl &&
                                                        <img src={record.fiscalCodeUrl} alt="" style={{ maxHeight: '100%', objectFit: 'contain' }} />
                                                    }
                                                </DialogButton>
                                            </Typography>
                                        } />
                                    </ReferenceField>
                                    <FunctionField render={record => <Typography variant="h5" textAlign='center' gutterBottom>{record.code}</Typography>} />
                                </ReferenceField>
                                <CloseableButton iconProps={{ fontSize: 'large' }} />
                            </Stack>
                            <Grid container item xs={12} justifyContent="center" alignItems="center" sx={{ padding: theme => theme.spacing(2), }}>
                                <Grid item xs={12} sm={4} display="flex" justifyContent="center" alignItems="center">
                                    <Labeled source="advanceAmount">
                                        <FunctionField render={record => <Typography>{`${record.advanceAmount.toFixed(2)} €`}</Typography>} />
                                    </Labeled>
                                </Grid>

                                <Grid item xs={12} sm={4} display="flex" justifyContent="center" alignItems="center">
                                    <Labeled>
                                        <BooleanField source="religion" justifyContent='center' fullWidth />
                                    </Labeled>
                                </Grid>

                                <Grid item xs={12} sm={4} display="flex" justifyContent="center" alignItems="center">
                                    <Labeled>
                                        <BooleanField source="covered" justifyContent='center' fullWidth />
                                    </Labeled>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: theme => theme.spacing(2), paddingRight: 0 }}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12} sx={{ width: 200 }}>
                        <BookingItemsDataGridField buildFilter={(record) => { return { bookingsId: record.id } }} />
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: theme => theme.spacing(2), paddingRight: 0 }}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12}>
                        <Labeled>
                            <TextField source="note" />
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;