import MiurAdoptions from './MiurAdoptions';
import MiurSchools from './MiurSchools';

export default {
    MiurSchools: {
        name: "miurschools", permission: "admin", ...MiurSchools
    },
    MiurYearCourses: {
        name: "miuryearcourses", permission: "admin", noInSidebar: true
    },
    MiurAdoptions: {
        name: "miuradoptions", permission: "admin", ...MiurAdoptions
    },
    MiurDisciplines: {
        name: "miurdisciplines", permission: "admin", noInSidebar: true
    },
};