import users from './users';
import medias from './medias';
import roles from './roles';
import settings from './settings';
import settingtypes from './settingtypes';

export default {
    users,
    medias,
    roles,
    settings,
    settingtypes,
    
};