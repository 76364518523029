import miuradoptions from './miuradoptions';
import miurdisciplines from './miurdisciplines';
import miurschools from './miurschools';
import miuryearcourses from './miuryearcourses';
import miuradoptionsmiuryearcourses from './miuradoptionsmiuryearcourses';

export default {
    miuradoptions,
    miurdisciplines,
    miurschools,
    miuryearcourses,
    miuradoptionsmiuryearcourses
};