import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useWatch } from 'react-hook-form';

import Icon from '@mui/icons-material/ChangeCircleOutlined';

import {
    RaRecord,
    useRecordContext,
} from 'ra-core';

import {
    ButtonProps,
    SimpleForm,
    AutocompleteInput,
    useDataProvider,
    useGetList,
    useTranslate,
    useRefresh,
    useNotify,
} from 'react-admin';

import { CreateDialogButton } from 'admin/components';
import { mapStudentDisplayName } from 'admin/mappers';
import { Typography } from '@mui/material';
import { CreateDialogButtonActions } from 'admin/components/layout/Dialogs/Create/CreateDialogButton';

const BookingItemInput = () => {
    const translate = useTranslate();
    const isbn = useWatch({ name: 'isbn' }) || null;
    const status = useWatch({ name: 'status' }) || null;
    const { data: choices, isLoading } = useGetList('bookingitems',
        {
            filter: {
                codiceIsbn: isbn
            },
            pagination: {
                perPage: 500,
                page: 1
            },
            meta: {
                expand: [
                    'bookings.studentMiurYearCourse.miurYearCourses.miurSchools',
                    'bookings.studentMiurYearCourse.student',
                    'emailBooks'
                ]
            }
        });

    const optionText = choice => `${choice.bookings?.studentMiurYearCourse.code} - ${mapStudentDisplayName(choice.bookings?.studentMiurYearCourse.student)}`;

    const finalChoices = choices?.filter(x => x.emailBooks.filter(y => y.status === status).length === 0) || [];

    if (finalChoices?.length > 0) {
        return (
            <AutocompleteInput
                label=""
                source="bookingItemId"
                choices={choices?.filter(x => x.emailBooks.filter(y => y.status === status).length === 0)}
                optionText={optionText}
                disabled={isLoading}
                fullWidth
            />
        );
    }
    else {
        return (
            <Typography variant="h5" gutterBottom>
                {translate("emailbooks.other.notAssignableForIsbn", { isbn })}
            </Typography>
        );
    }
};

const ChangeBookingItemButton = <RecordType extends RaRecord = any>(
    props: ShowButtonProps<RecordType>
) => {

    const record = useRecordContext(props);
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();

    const dialogRef = useRef<CreateDialogButtonActions>(null);

    const onSave = (data) => {
        if (data?.bookingItemId === record?.bookingItemId) {
            dialogRef.current?.close();
        }
        else {
            delete data.status;
            delete data.isbn;

            dataProvider.post('api/bookingitems/change', data)
                .then(response => {
                    if (response) {
                        refresh();
                        notify('Assegnazione cambiata', { type: 'success' });
                        dialogRef.current?.close();
                    }
                    else {
                        notify('Assegnazione non cambiata', { type: 'warning' })
                    }
                })
                .catch(() => {
                    notify('Assegnazione non cambiata', { type: 'warning' })
                })
                .finally(() => {
                    refresh();
                    dialogRef.current?.close();
                });
        }
    };

    return (
        <CreateDialogButton
            resource=''
            ref={dialogRef}
            icon={<Icon />}
            label={"emailbooks.action.changeBookingItem"}
            dialogProps={{
                title: translate("emailbooks.other.changeBookingItem.dialogTitle"),
                minWidth: '25vw'
            }}
            disabled={record?.bookingItem?.currentStatus !== record.status}
        >
            <SimpleForm onSubmit={onSave} defaultValues={{ emailBookId: record.id, bookingItemId: record?.bookingItemId, status: record?.status, isbn: record?.isbn }}>
                <BookingItemInput />
            </SimpleForm>
        </CreateDialogButton>
    );
};

interface Props<RecordType extends RaRecord = any> {
    emailBook?: RecordType;
}

export type ShowButtonProps<RecordType extends RaRecord = any> = Props<
    RecordType
> &
    ButtonProps;

ChangeBookingItemButton.propTypes = {
    emailBook: PropTypes.object,
};

export default ChangeBookingItemButton;
