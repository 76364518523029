import * as React from 'react';
import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Icon from '@mui/icons-material/ArrowBack';
import {
    Button,
    ButtonProps
} from 'react-admin';

interface Props {
    className?: string;
    disabled?: boolean;
    icon?: ReactElement;
    invalid?: boolean;
    label?: string;
    variant?: string;
}

export type BackButtonProps = Props &
    ButtonProps & {
        alwaysEnable?: boolean;
    };

export const BackButton = (
    props: BackButtonProps
) => {
    const {
        icon = defaultIcon,
        label = 'ra.action.back',
        onClick,
        className,
        ...rest
    } = props;

    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        async event => {
            if (onClick) {
                onClick(event);
            }
            if (event.defaultPrevented) {
                return;
            }
            event.stopPropagation();
            window.history.go(-1);
        },
        [onClick]
    );

    return (
        <StyledButton
            label={label}
            onClick={handleClick}
            className={clsx(BackButtonClasses.root, className)}
            {...(rest as any)}
        >
            {icon}
        </StyledButton>
    );
};

const defaultIcon = <Icon />;

BackButton.propTypes = {
    className: PropTypes.string,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
    icon: PropTypes.element,
};

const PREFIX = 'RaBackButton';

export const BackButtonClasses = {
    root: `${PREFIX}-root`,
};

const StyledButton = styled(Button, {
    name: PREFIX,
    overridesResolver: (_props, styles) => styles.root,
})({});