import Icon from '@mui/icons-material/Book';
import CustomEdit from './CustomEdit';
import CustomList from './CustomList';
import CustomShow from './CustomShow';
import { lightGreen } from '@mui/material/colors';

export default {
    list: CustomList,
    edit: CustomEdit,
    show: CustomShow,
    icon: Icon,
    iconColor: lightGreen[800],
};