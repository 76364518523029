import { Grid, Divider } from '@mui/material';
import { useWatch, useFormContext } from 'react-hook-form';

import {
    Edit,
    SimpleForm,
    AutocompleteInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    NumberInput,
    required,
    useRedirect,
    useTranslate,
    useGetList,
} from 'react-admin';

import { EditActions, EditToolbar, FileInput } from 'admin/components';

import CustomTitle from './CustomTitle';

import { transformData } from './transformData';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { mapSchoolClassInfo } from 'admin/mappers';

const MiurSchoolTypeInput = () => {
    const bookingsId = useWatch({ name: 'bookingsId' });
    const miurSchoolType = useWatch({ name: 'miurSchoolType' }) || null;
    const { setValue } = useFormContext();
    const translate = useTranslate();

    const handleOnChange = (e) => {
        if (miurSchoolType && miurSchoolType !== e) {
            setValue('miurSchoolsId', null);
            setValue('miurYearCoursesId', null);
        }
    };

    const optionText = choice => translate(`pos.enums.TipoGradoScuolaType.${choice.value}`, { smart_count: 1 });

    return (
        <ReferenceInput
            source="miurSchoolType"
            reference="api/enums/tipoGradoScuolaType"
        >
            <SelectInput
                label="resources.miuryearcourses.fields.tipoGradoScuola"
                optionText={optionText}
                validate={required()}
                onChange={handleOnChange}
                disabled={bookingsId !== null}
                fullWidth
            />
        </ReferenceInput>
    );
};

const MiurSchoolInput = () => {
    const bookingsId = useWatch({ name: 'bookingsId' });
    const miurSchoolType = useWatch({ name: 'miurSchoolType' }) || null;
    const miurSchoolsId = useWatch({ name: 'miurSchoolsId' }) || null;
    const { setValue } = useFormContext();

    const handleOnChange = (e) => {
        if (miurSchoolsId && miurSchoolsId !== e)
            setValue('miurYearCoursesId', null);
    };

    const inputText = choice => `${choice.codiceScuola} - ${choice.denominazioneScuola}`;
    const optionText = choice => {
        return (
            <>
                {
                    choice.favorite ?
                        <StarIcon fontSize='small' sx={{ mr: 1 }} /> :
                        <StarBorderIcon fontSize='small' sx={{ mr: 1 }} />
                }
                {`${choice.codiceScuola} - ${choice.denominazioneScuola}`}
            </>
        );
    }

    const filterToQuery = searchText => ({ "[manual]": `(Contains(codiceScuola, '${searchText}') or Contains(denominazioneScuola, '${searchText}'))` });

    return (
        <ReferenceInput
            source="miurSchoolsId"
            reference="miurschools"
            filter={{ 'MiurYearCourses//tipoGradoScuola_eq': miurSchoolType }}
            sort={{ field: 'favorite', order: 'DESC' }}
        >
            <AutocompleteInput
                label="resources.miurschools.fields.denominazioneScuola"
                inputText={inputText}
                optionText={optionText}
                validate={required()}
                onChange={handleOnChange}
                filterToQuery={filterToQuery}
                disabled={bookingsId !== null}
                fullWidth
            />
        </ReferenceInput>
    );
};

const MiurYearCourseInput = () => {
    const bookingsId = useWatch({ name: 'bookingsId' });
    const miurSchoolsId = useWatch({ name: 'miurSchoolsId' }) || null;
    const { data: choices, isLoading } = useGetList('miuryearcourses',
        {
            filter: {
                miurSchoolsId: miurSchoolsId
            },
            pagination: {
                perPage: 200,
                page: 1
            }
        });

    const optionText = choice => `${mapSchoolClassInfo(choice)}`;

    return (
        <AutocompleteInput
            label="resources.miuryearcourses.fields.annoCorso"
            source="miurYearCoursesId"
            choices={choices}
            optionText={optionText}
            disabled={bookingsId !== null || isLoading}
        />
    );
};

const CustomEdit = () => {
    const redirect = useRedirect();
    return (
        <Edit
            actions={<EditActions />}
            title={<CustomTitle />}
            transform={transformData}
            mutationMode='pessimistic'
            mutationOptions={{
                onSuccess: (data) => {
                    if (data.bookingsId) {
                        redirect('edit', 'bookings', data.bookingsId);
                    }
                    else {
                        redirect('list', 'students');
                    }
                }
            }}
        >
            <SimpleForm
                toolbar={
                    <EditToolbar labelFn={(record, p) => record.bookingsId !== null ? 'ra.action.save' : 'students.action.saveAndBook'} />
                }>
                <Grid container spacing={2}>

                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} sm={4.5}></Grid>
                        <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center" sx={{ padding: theme => theme.spacing(2), marginTop: theme => theme.spacing(2) }}>
                            <FileInput source="fiscalCodeUrl" mediaGalleryText='students.other.selectFiscalCode' useMediaGallery />
                        </Grid>
                        <Grid item xs={12} sm={4.5} display="flex" justifyContent="flex-end" alignItems="center">
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextInput source="givenName" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput source="familyName" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="parentReference" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <NumberInput source="voucherAmount" min={0} fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextInput source="fiscalCode" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput source="phoneNumber" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput source="email" fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <MiurSchoolTypeInput />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <MiurSchoolInput />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <MiurYearCourseInput />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12}>
                        <TextInput source="note" multiline rows={5} maxRows={5} fullWidth />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};

export default CustomEdit;
