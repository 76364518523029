import Icon from '@mui/icons-material/LockReset';
import { Typography, useTheme } from '@mui/material';

import {
    useDataProvider,
    useNotify,
    useRecordContext,
} from 'react-admin';

import {
    CustomConfirmButton, Dialog
} from 'admin/components';

import { ApiException, User } from 'admin/types';
import { useState } from 'react';

export interface ResetPasswordButtonProps {
    email?: string | null;
    emailFn?: ((record: any) => string) | null;
}

export const ResetPasswordButton = (props: ResetPasswordButtonProps) => {
    const {
        emailFn = null,
    } = props;

    const theme = useTheme();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const record = useRecordContext<User>();
    const [newPassword, setNewPassword] = useState<string | null>(null);

    let emailAddress = emailFn !== null ? emailFn(record) : record.email;

    const handleClose = () => setNewPassword(null);

    const handleClick = () => {
        dataProvider
            .get(`api/auth/resetPassword/${emailAddress}`)
            .then(response => {
                const { data } = response || { data: null };
                if (data) {
                    notify('ra.auth.reset_password_success');
                }
                else
                    notify('ra.auth.reset_password_error', { type: 'warning' })

                setNewPassword(data);
            })
            .catch((apiException: ApiException) => {
                notify(`ra.apiError.${apiException?.errors[0].code}`, { type: 'warning' });
            });
    }

    return (
        <>
            <CustomConfirmButton
                label={'ra.auth.reset_password'}
                title={'ra.auth.reset_password_title'}
                content={'ra.auth.reset_password_content'}
                translateOptions={{ email: emailAddress }}
                onConfirm={handleClick}
                icon={<Icon sx={{ fontSize: 20 }} />}
                color={theme.palette.success.main}
            />
            <Dialog onClosed={handleClose} open={newPassword !== null} title='New Password' preventBackdropClick preventEscapeKeyDown>
                <Typography variant="body1" textAlign='center'>
                    {newPassword}
                </Typography>
            </Dialog>
        </>
    )
}