import { useState } from 'react';

import UploadIcon from '@mui/icons-material/Upload';

import {
    Confirm,
    Button,
    usePermissions,
    useDataProvider,
    useNotify,
    useRefresh,
    useTranslate,
} from 'react-admin';
import { Roles } from 'admin/types';

const ImportSchoolsButton = () => {
    const { permissions } = usePermissions<string[]>();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        setIsLoading(true);
        dataProvider
            .post('api/miur/import')
            .then(response => {
                if (response) {
                    notify('Imported');
                    setTimeout(refresh, 1500);
                }
                else {
                    notify('Not Imported', { type: 'warning' })
                }
            })
            .catch(() => {
                notify('Not Imported', { type: 'warning' })
            })
            .finally(() => {
                setIsLoading(false);
                setOpen(false);
            })
    };

    return (
        <>
            <Button
                disabled={!permissions.includes(Roles.Admin)}
                size="small"
                color="primary"
                onClick={handleClick}
                startIcon={<UploadIcon />}
                label='ra.action.import'
            />
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={translate('miurschools.action.importTitle')}
                content={translate('miurschools.action.importContent')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default ImportSchoolsButton;
