import { Utils } from 'admin/core';
import { useCallback } from 'react';

import {
    useDataProvider
} from 'react-admin';

export const useCheckUserEmailIsUnique = () => {
    const dataProvider = useDataProvider();

    const getCheckEmailIsUnique = useCallback(
        async (email, _defaultValue = false) => {
            var result = await dataProvider
                .get(`checkemailisunique/user/${email}`)
                .then(response => {
                    const { data } = response || { data: _defaultValue };
                    return new Promise((resolve, reject) => { resolve(data); });
                })
                .catch(error => {
                    console.error(error);
                    return new Promise((resolve, reject) => { resolve(_defaultValue); });
                });
            return result;
        },
        [dataProvider]
    );

    return dataProvider ? getCheckEmailIsUnique : () => Promise.resolve(false);
};

export const useGetVersion = () => {
    const dataProvider = useDataProvider();
    const webVersion = Utils.GetENV('WEB_VERSION');

    const getVersion = useCallback(
        async () => {
            var result = await dataProvider
                .get('api/utils/version')
                .then(({ data }) => {
                    return new Promise((resolve, reject) => { resolve(data ?? null); });
                })
                .catch(error => {
                    console.error(error);
                    return new Promise((resolve, reject) => { resolve(null); });
                });
            return `${result}.${webVersion}`;
        },
        [dataProvider, webVersion]
    );

    return dataProvider ? getVersion : () => Promise.resolve(null);
};