import { ReactNode, useState, useEffect } from 'react';

import {
    CreateBase,
    SaveButton,
    useGetResourceLabel,
    useLoading,
    useNotify,
    useTranslate
} from 'react-admin';

import { Utils } from 'admin/core';

import { Box, Dialog } from '@mui/material';
import { FormDialogActions, FormDialogContent, FormDialogProps, FormDialogTitle } from '../FormDialog';

export interface CreateFormDialogProps {
    children: ReactNode;
    resource: string;
    onCreateSuccess?: (data: any) => void;
    dialogProps?: FormDialogProps;
}

const CreateFormDialog = (props: CreateFormDialogProps) => {
    const {
        onCreateSuccess = () => { },
        children,
        resource = '',
        dialogProps = {} as FormDialogProps
    } = props;

    const {
        title = '',
        open = false,
        onOpen = () => { },
        onClosed = () => { },
        minWidth = 300,
        hideClose = false,
        showRefresh = false,
        preventClose = false,
        fullScreen,
        fullWidth
    } = dialogProps;

    const translate = useTranslate();
    const notify = useNotify();
    const getResourceLabel = useGetResourceLabel();

    const [innerOpen, setInnerOpen] = useState(open);
    const [isFirstOpen, setIsFirstOpen] = useState(false);
    const loading = useLoading();

    useEffect(() => {
        if (isFirstOpen && !loading) {
            setIsFirstOpen(false)
        }
    }, [isFirstOpen, loading]);

    useEffect(() => {
        if (open && !innerOpen) {
            setInnerOpen(true);
            onOpen();
        }
    }, [innerOpen, onOpen, open]);

    const onClose = (event: object, reason: string) => {
        if (!((reason === 'escapeKeyDown' && preventClose) || (reason === 'backdropClick' && preventClose))) {
            setInnerOpen(false);
            onClosed();
        }
    };

    const onSuccess = (data) => {
        notify('ra.notification.created', {
            type: 'success',
            messageArgs: { smart_count: 1 }
        });
        onCreateSuccess(data);
        onClosed();
    };

    let finalTitle = title;

    if (!title) {
        finalTitle = translate('ra.page.create', { name: getResourceLabel(resource, 1), });
    }
    else {
        finalTitle = Utils.IsString(title) ? translate(title) : title
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={"lg"}
            fullScreen={fullScreen}
            fullWidth={fullWidth}
            sx={{
                '& .MuiDialog-paper': {
                    backgroundColor: theme => theme.palette.background.default,
                    minWidth: minWidth,
                    maxWidth: '100%',
                }
            }}
        >
            {
                finalTitle && (
                    <FormDialogTitle onClose={onClose} hideClose={hideClose} showRefresh={showRefresh}>
                        {finalTitle}
                    </FormDialogTitle>
                )
            }
            <Box sx={
                fullScreen ? {
                    overflow: 'hidden',
                    height: 'calc(100vh - 64px)',
                    '& form': {
                        height: 'calc(100vh - 140px)',
                        '& .MuiDialogContent-root': {
                            height: '100%',
                            overflow: 'auto',
                        }
                    }
                } : { overflow: 'auto' }
            }>
                <CreateBase resource={resource} mutationOptions={{ onSuccess }}>
                    {Utils.CreateOrCloneElement(children, {
                        component: DialogContentComponent,
                        toolbar: false
                    })}
                </CreateBase>
            </Box>
        </Dialog>
    );
}

const DialogContentComponent = ({ children, loading, ...rest }) => (
    <>
        <FormDialogContent dividers >
            {children}
        </FormDialogContent>
        <FormDialogActions>
            <SaveButton disabled={loading} alwaysEnable />
        </FormDialogActions>
    </>
);

export default CreateFormDialog;