import { forwardRef, useImperativeHandle, ReactNode, useState } from 'react';
import { ReactElement } from 'react';

import Icon from '@mui/icons-material/Create';

import {
    Button,
} from 'react-admin';

import EditFormDialog from './EditFormDialog';
import { Utils } from 'admin/core';
import { FormDialogProps } from '../FormDialog';
import { useTheme } from '@mui/material';

export interface EditDialogButtonActions {
    open(): void;
    close(): void;
    isOpen?: boolean;
}

interface Props {
    id: any;
    icon?: ReactElement;
    label?: string;
    resource: string;
    onSuccess?: (data: any) => void;
    children: ReactNode;
    dialogProps?: FormDialogProps;
    showLabel?: Boolean;
}

export type EditDialogButtonProps = Props;

const EditDialogButton = forwardRef<EditDialogButtonActions, EditDialogButtonProps>((props: EditDialogButtonProps, ref) => {
    const {
        id,
        icon = defaultIcon,
        label = 'ra.action.edit',
        resource,
        onSuccess = () => { },
        children,
        dialogProps,
        showLabel = false,
        ...rest
    } = props;

    const theme = useTheme();
    const [innerOpen, setInnerOpen] = useState<boolean>(dialogProps?.open || false);

    useImperativeHandle(
        ref,
        () => ({
            open() {
                setInnerOpen(true);
            },
            close() {
                setInnerOpen(false);
            },
            isOpen: innerOpen
        }));

    const handleOpen = () => {
        if (!innerOpen) {
            setInnerOpen(true);
        }
    };

    const handleClose = () => {
        if (innerOpen) {
            setInnerOpen(false);
        }
    };

    return (
        <>
            <Button label={showLabel ? label : ''} onClick={handleOpen} {...(rest as any)}>
                {icon}
            </Button>
            <EditFormDialog
                id={id}
                resource={resource}
                onEditSuccess={onSuccess}
                dialogProps={{
                    open: innerOpen,
                    onClosed: handleClose,
                    ...dialogProps,
                }}
            >
                {Utils.CreateOrCloneElement(children)}
            </EditFormDialog>
        </>
    );
});

const defaultIcon = <Icon />;

export default EditDialogButton;
