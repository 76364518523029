import { Children, ReactNode } from 'react';
import PropTypes from 'prop-types';

import {
    TopToolbar,
    useCreateContext,
    usePermissions,
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { Utils } from 'admin/core';

import { BackButton, ListButton } from 'admin/components/buttons';

export interface CreateActionsProps {
    children?: ReactNode | ReactNode[] | null;
    disabled?: boolean;
    hasList?: boolean;
    hasBack?: boolean;
}

const CreateActions = (props: CreateActionsProps) => {
    const {
        disabled = false,
        hasList = false,
        hasBack = true,
        children = [],
    } = props;

    const { permissions } = usePermissions();
    const createProps = useCreateContext();

    const { isLoading } = createProps;

    return (
        <TopToolbar>
            {hasBack && <BackButton withText disabled={isLoading} />}
            {hasList && !hasBack && <ListButton withText disabled={isLoading} />}
            {Children.map(children, (child: any, index) => {
                const { disabled: disabledChild, ...childProps } = child.props

                const commonProps = {
                    key: index,
                    context: 'button',
                    disabled: disabled ||
                        isLoading ||
                        (disabledChild !== null && Utils.IsFunction(disabledChild) && disabledChild(isLoading, permissions)) ||
                        (Utils.IsBoolean(disabledChild) && disabledChild)
                }

                return Utils.CreateOrCloneElement(child, { ...commonProps, ...childProps })
            })}
        </TopToolbar>
    )
};

CreateActions.propTypes = {
    disabled: PropTypes.bool,
    hasList: PropTypes.bool,
    hasBack: PropTypes.bool,
    children: PropTypes.node,
};

CreateActions.displayName = 'CreateActions';

export default CreateActions;