import { useState } from 'react';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import {
    useDataProvider,
    useNotify,
    useRefresh,
    LoadingIndicator
} from 'react-admin';

const FavoriteButton = ({ record, ...props }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        setIsLoading(true);
        dataProvider
            .post(`api/miurschools/favorite/${record.id}`)
            .then(response => {
                const { data } = response || { data: false };
                if (data) {
                    refresh();
                }
                else
                    notify('Not updated', { type: 'warning' })
            })
            .catch(() => {
                notify('Not updated', { type: 'warning' })
            })
            .finally(() => {
                setIsLoading(false);
            })
    };

    if (isLoading) return <LoadingIndicator />

    return (
        record.favorite ? <StarIcon onClick={handleClick} style={{ cursor: 'pointer' }} /> : <StarBorderIcon onClick={handleClick} style={{ cursor: 'pointer' }} />
    );
};

export default FavoriteButton;
