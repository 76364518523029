import { Theme, useMediaQuery } from '@mui/material';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
} from 'react-admin';

import {
    ShowButton,
    ListRowActions,
} from 'admin/components';
import FilterSidebar from './FilterSidebar';

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            hasCreate={false}
            actions={false}
            filterDefaultValues={{}}
            sort={{ field: 'titolo', order: 'ASC' }}
            perPage={25}
            storeKey={false}
            aside={<FilterSidebar />}
            sx={{ marginTop: 2 }}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.codiceIsbn} - ${record.titolo}`}
                    // secondaryText={record => `${record.id}`}
                    // tertiaryText={record => record.xxx}
                    linkType="edit"
                />
            ) : (
                <Datagrid bulkActionButtons={false} optimized>
                    <ReferenceField source="miurDisciplinesId" reference="miurdisciplines" link={false}>
                        <TextField source="disciplina" />
                    </ReferenceField>
                    <TextField source="codiceIsbn" />
                    <TextField source="autori" />
                    <TextField source="titolo" />
                    {/* <TextField source="sottotitolo" /> */}
                    <TextField source="volume" />
                    <TextField source="editore" />
                    <NumberField source="prezzo" options={{
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2
                    }} />
                    <ListRowActions>
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
