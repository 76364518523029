import { useEffect, useState } from 'react';
import Book from '../types/Book';
import BookAPI from '../google-books';
import { TextField } from '@mui/material';
import { SearchOptions } from '../types/BookAPI';
import { useTranslate } from 'react-admin';

const api = new BookAPI(process.env.GOOGLE_BOOKS_API_KEY);

interface Props {
    label: string,
    defaultQuery?: string,
    searchOption: SearchOptions,
    onSearched?: (books: Book[]) => any,
}

const GoogleBookSearch: React.FC<Props> = (props: Props): JSX.Element => {
    const {
        label = "Search library for...",
        defaultQuery,
        searchOption,
        onSearched = (books) => { }
    } = props

    const translate = useTranslate();
    const [books, setBooks] = useState<Book[]>([]);

    const minUserInteractionMS = 500;
    let intervalId: NodeJS.Timeout;

    useEffect(() => {
        if (onSearched)
            onSearched(books);
    }, [books])

    const onTyping = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const query = event.currentTarget.value;
        clearTimeout(intervalId);
        intervalId = setTimeout((): void => {
            if (query && query.trim()) {
                // done typing
                api.search(query, searchOption)
                    .then(
                        (data: Book[]): void => {
                            setBooks(data);
                        },
                    )
                    .catch(
                        (): void => {
                            setBooks([]);
                        },
                    );
            }
            else {
                if (books.length > 0)
                    setBooks([]);

            }
        }, minUserInteractionMS);
    };

    return (
        <TextField
            label={translate(label)}
            defaultValue={defaultQuery}
            onChange={onTyping}
            margin="normal"
            fullWidth
        />
    );
};

export default GoogleBookSearch;