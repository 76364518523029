import { useRef } from 'react';
import { Grid, Divider, Box } from '@mui/material';

import {
    NumberInput,
    TextInput,
    useCreate,
    FormDataConsumer,
    DateInput,
    TabbedForm,
    useNotify,
    Identifier,
    useRefresh,
    useTranslate,
} from 'react-admin';

import { CreateDialogButton, EditToolbar } from 'admin/components';
import { BookingTypeIcon, SearchAdoptionInput, SearchBookInput } from 'admin/views/Common';
import { BookingStatusType, BookingType } from 'admin/types';
import { CreateDialogButtonActions } from 'admin/components/layout/Dialogs/Create/CreateDialogButton';

interface Props {
    bookingsId: Identifier;
    bookingType: BookingType;
}

const AddBookingItem = (props: Props) => {
    const {
        bookingsId,
        bookingType,
    } = props;

    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();
    const [create] = useCreate();

    const dialogRef = useRef<CreateDialogButtonActions>(null);

    const onSave = (data) => {
        delete data.adoptionsId;
        delete data.booksId;

        data.bookingsId = bookingsId;
        data.currenStatus = BookingStatusType.ToBook;
        data.bookingType = bookingType;
        data.daLista = false;

        create('bookingitems', { data }, {
            onSuccess: () => {
                refresh();
                notify('Libro aggiunto', { type: 'success' });
                dialogRef.current?.close();
            },
            onError: (error) => {
                console.error(error);
                notify('Error', { type: 'warning' })
            }
        });
    };

    return (
        <CreateDialogButton
            ref={dialogRef}
            showLabel
            label={translate('bookingitems.other.addToBooking', { type: bookingType })}
            resource="bookingitems"
            dialogProps={{
                title: "bookingitems.other.addToBookingDialogTitle",
            }}
        >
            <TabbedForm onSubmit={onSave} toolbar={<EditToolbar />} syncWithLocation={false} sx={{ width: '75vw' }}>
                <TabbedForm.Tab label={bookingType} icon={<BookingTypeIcon value={bookingType} />}>
                    {
                        bookingType === BookingType.Scolastica &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SearchAdoptionInput />
                            </Grid>

                            <Grid item xs={12} sx={{ padding: theme => theme.spacing(2), paddingRight: 0 }}>
                                <Divider flexItem />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <TextInput source="codiceIsbn" fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="titolo" fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="sottotitolo" fullWidth readOnly />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <NumberInput source="prezzo" min={0} fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="autori" fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="editore" fullWidth readOnly />
                            </Grid>

                            <Grid item xs={12} sx={{ padding: theme => theme.spacing(2), paddingRight: 0 }}>
                                <Divider flexItem />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <TextInput source="volume" fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                            </Grid>
                        </Grid>
                    }

                    {
                        bookingType === BookingType.Varia &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SearchBookInput />
                            </Grid>

                            <Grid item xs={12} sx={{ padding: theme => theme.spacing(2), paddingRight: 0 }}>
                                <Divider flexItem />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <TextInput source="codiceIsbn" fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="titolo" fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput source="sottotitolo" fullWidth readOnly />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <TextInput source="autori" fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextInput source="editore" fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <NumberInput source="prezzo" min={0} fullWidth readOnly />
                            </Grid>

                            <Grid item xs={12} sx={{ padding: theme => theme.spacing(2), paddingRight: 0 }}>
                                <Divider flexItem />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <TextInput source="categorie" fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <DateInput source="dataPubblicazione" fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Box display="flex" justifyContent="space-between" gap={2}>
                                    <TextInput source="image" fullWidth readOnly />
                                    <FormDataConsumer label="">
                                        {({ formData }) => <img src={formData.image} style={{ marginTop: 8, height: 48 }} alt="" />
                                        }
                                    </FormDataConsumer>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextInput source="lingua" fullWidth readOnly />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            </Grid>
                        </Grid>
                    }
                </TabbedForm.Tab>
            </TabbedForm>
        </CreateDialogButton>
    );
};

export default AddBookingItem;
