import { Card, CardContent, Divider } from '@mui/material';

import {
    FilterLiveSearch, useGetIdentity,
} from 'react-admin';
import { ReferenceSelectInput } from 'admin/components/layout/Filters';

const FilterSidebar = () => {
    const { isLoading } = useGetIdentity();

    if (isLoading) return null;

    return (
        <Card
            sx={{
                display: { xs: 'none', md: 'block' },
                order: -1,
                width: '15em',
                mr: 2,
                alignSelf: 'flex-start',
                minWidth: 280
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch label="resources.miuradoptions.fields.codiceIsbn" source="codiceIsbn" />
                <FilterLiveSearch label="resources.miuradoptions.fields.autori" source="autori" />
                <FilterLiveSearch label="resources.miuradoptions.fields.titolo" source="titolo" />
                <FilterLiveSearch label="resources.miuradoptions.fields.editore" source="editore" />

                <Divider flexItem sx={{ mt: 1 }} />

                <ReferenceSelectInput
                    label="resources.miuradoptions.fields.miurDisciplinesId"
                    source="miurDisciplinesId"
                    reference="miurdisciplines"
                    sort={{ field: 'disciplina', order: 'ASC' }}
                    perPage={1000}
                    optionText="disciplina"
                />

            </CardContent>
        </Card>
    );
};

export default FilterSidebar;