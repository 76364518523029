import { useMemo } from 'react';

import { Grid, Divider } from '@mui/material';
import { useWatch, useFormContext } from 'react-hook-form';

import {
    Create,
    SimpleForm,
    AutocompleteInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    NumberInput,
    required,
    useRedirect,
    useTranslate,
    useGetList,
    DateInput,
} from 'react-admin';

import { CreateActions, CreateToolbar, FileInput } from 'admin/components';
import { transformData } from './transformData';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { mapSchoolClassInfo } from 'admin/mappers';

const MiurSchoolTypeInput = () => {
    const miurSchoolType = useWatch({ name: 'miurSchoolType' }) || null;
    const { setValue } = useFormContext();
    const translate = useTranslate();

    const handleOnChange = (e) => {
        if (miurSchoolType && miurSchoolType !== e) {
            setValue('miurSchoolsId', null);
            setValue('miurYearCoursesId', null);
        }
    };

    const optionText = choice => translate(`pos.enums.TipoGradoScuolaType.${choice.value}`, { smart_count: 1 });

    return (
        <ReferenceInput
            source="miurSchoolType"
            reference="api/enums/tipoGradoScuolaType"
        >
            <SelectInput
                label="resources.miuryearcourses.fields.tipoGradoScuola"
                optionText={optionText}
                validate={required()}
                onChange={handleOnChange}
                fullWidth
            />
        </ReferenceInput>
    );
};

const MiurSchoolInput = () => {
    const miurSchoolType = useWatch({ name: 'miurSchoolType' }) || null;
    const miurSchoolsId = useWatch({ name: 'miurSchoolsId' }) || null;
    const { setValue } = useFormContext();

    const handleOnChange = (e) => {
        if (miurSchoolsId && miurSchoolsId !== e)
            setValue('miurYearCoursesId', null);
    };

    const inputText = choice => `${choice.codiceScuola} - ${choice.denominazioneScuola}`;
    const optionText = choice => {
        return (
            <>
                {
                    choice.favorite ?
                        <StarIcon fontSize='small' sx={{ mr: 1 }} /> :
                        <StarBorderIcon fontSize='small' sx={{ mr: 1 }} />
                }
                {`${choice.codiceScuola} - ${choice.denominazioneScuola}`}
            </>
        );
    }

    const filterToQuery = searchText => ({ "[manual]": `(Contains(codiceScuola, '${searchText}') or Contains(denominazioneScuola, '${searchText}'))` });

    return (
        <ReferenceInput
            source="miurSchoolsId"
            reference="miurschools"
            filter={{ 'MiurYearCourses//tipoGradoScuola_eq': miurSchoolType }}
            sort={{ field: 'favorite', order: 'DESC' }}
        >
            <AutocompleteInput
                label="resources.miurschools.fields.denominazioneScuola"
                inputText={inputText}
                optionText={optionText}
                validate={required()}
                onChange={handleOnChange}
                filterToQuery={filterToQuery}
                fullWidth
            />
        </ReferenceInput>
    );
};

const MiurYearCourseInput = () => {
    const miurSchoolsId = useWatch({ name: 'miurSchoolsId' }) || null;
    const { data: choices, isLoading } = useGetList('miuryearcourses',
        {
            filter: {
                miurSchoolsId: miurSchoolsId
            },
            pagination: {
                perPage: 200,
                page: 1
            }
        });

    const optionText = choice => `${mapSchoolClassInfo(choice)}`;

    return (
        <AutocompleteInput
            label="resources.miuryearcourses.fields.annoCorso"
            source="miurYearCoursesId"
            choices={choices}
            optionText={optionText}
            disabled={isLoading}
        />
    );
};

const CustomCreate = ({ permissions, options, ...props }) => {
    const defaultValues = useMemo(() => ({ voucherAmount: 0, advanceAmount: 0, religion: false, covered: true, orderDate: new Date() }), []);
    const redirect = useRedirect();
    return (
        <Create
            actions={<CreateActions />}
            transform={transformData}
            {...props}
            mutationOptions={{
                onSuccess: (data) => {
                    if (data.bookingsId) {
                        redirect('edit', 'bookings', data.bookingsId);
                    }
                    else {
                        redirect('list', 'students');
                    }
                }
            }}
        >
            <SimpleForm
                toolbar={
                    <CreateToolbar label='students.action.saveAndBook' hasSaveAndAdd={false} />
                }
                defaultValues={defaultValues} >
                <Grid container spacing={2}>

                    <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} sm={4.5}></Grid>
                        <Grid item xs={12} sm={3} display="flex" justifyContent="center" alignItems="center" sx={{ padding: theme => theme.spacing(2), marginTop: theme => theme.spacing(2) }}>
                            <FileInput source="fiscalCodeUrl" mediaGalleryText='students.other.selectFiscalCode' useMediaGallery />
                        </Grid>
                        <Grid item xs={12} sm={4.5} display="flex" justifyContent="flex-end" alignItems="center">
                            <DateInput source="orderDate" validate={required()} label="resources.bookings.fields.orderDate" />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextInput source="givenName" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput source="familyName" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="parentReference" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <NumberInput source="voucherAmount" min={0} fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <TextInput source="fiscalCode" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput source="phoneNumber" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput source="email" fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <MiurSchoolTypeInput />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <MiurSchoolInput />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <MiurYearCourseInput />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12}>
                        <TextInput source="note" multiline rows={5} maxRows={5} fullWidth />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default CustomCreate;
