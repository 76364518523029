import { Children, ReactNode } from 'react';
import PropTypes from 'prop-types';

import {
    SaveButton,
    Toolbar,
    useNotify,
    useCreateContext,
    usePermissions
} from 'react-admin'; // eslint-disable-line import/no-unresolved

import { useFormContext } from 'react-hook-form';

import ComposedIcon from '../ComposedIcon';

import Save from '@mui/icons-material/Save';
import Add from '@mui/icons-material/Add';

import { Utils } from 'admin/core';

export interface CreateToolbarProps {
    children?: ReactNode | ReactNode[] | null;
    disabled?: boolean;
    label?: string;
    hasSaveAndAdd?: boolean;
    saveAlwaysEnable?: boolean;
}

const CreateToolbar = (props: CreateToolbarProps) => {
    const {
        disabled = false,
        hasSaveAndAdd = true,
        label = 'ra.action.save',
        children = [],
        saveAlwaysEnable = false,
    } = props;

    const notify = useNotify();
    const { reset } = useFormContext();
    const { permissions } = usePermissions();
    const createProps = useCreateContext();

    const { isLoading } = createProps;

    return (
        <Toolbar sx={{ justifyContent: 'end' }}>
            <SaveButton
                label={label}
                mutationOptions={{
                    onSuccess: () => {
                        notify('ra.notification.created', {
                            type: 'info',
                            messageArgs: { smart_count: 1 }
                        });
                    }
                }}
                disabled={isLoading}
                alwaysEnable={saveAlwaysEnable}
            />
            {hasSaveAndAdd && <SaveButton
                icon={<ComposedIcon position="top-end" icon={<Save />} extraIcon={<Add />} />}
                label="ra.action.save_and_add"
                mutationOptions={{
                    onSuccess: () => {
                        notify('ra.notification.created', {
                            type: 'info',
                            messageArgs: { smart_count: 1 }
                        });
                        reset();
                    }
                }}
                type="button"
                variant="text"
                disabled={isLoading}
                alwaysEnable={saveAlwaysEnable}
            />}
            {Children.map(children, (child: any, index) => {
                const { disabled: disabledChild, ...childProps } = child.props

                const commonProps = {
                    key: index,
                    context: 'button',
                    disabled: disabled ||
                        isLoading ||
                        (disabledChild !== null && Utils.IsFunction(disabledChild) && disabledChild(isLoading, permissions)) ||
                        (Utils.IsBoolean(disabledChild) && disabledChild)
                }

                return Utils.CreateOrCloneElement(child, { ...commonProps, ...childProps })
            })}
        </Toolbar>
    )
};

CreateToolbar.propTypes = {
    disabled: PropTypes.bool,
    hasSaveAndAdd: PropTypes.bool,
    children: PropTypes.node,
};

CreateToolbar.displayName = 'CreateToolbar';

export default CreateToolbar;