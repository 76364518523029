import { forwardRef, useImperativeHandle, ReactNode, useState } from 'react';
import { ReactElement } from 'react';

import Icon from '@mui/icons-material/RemoveRedEye';

import {
    Button, ButtonProps, usePermissions, useRecordContext, useRefresh, useTranslate,
} from 'react-admin';

import ShowFormDialog from './ShowFormDialog';
import { Utils } from 'admin/core';
import { FormDialogProps } from '../FormDialog';
import { Box, Tooltip, alpha, useTheme } from '@mui/material';

export interface ShowDialogButtonActions {
    open(): void;
    close(): void;
    isOpen?: boolean;
}

interface Props {
    id: any;
    color?: string;
    icon?: ReactElement;
    label?: string;
    withText?: boolean;
    resource: string;
    children: ReactNode;
    dialogProps?: FormDialogProps
    disabled?: boolean;
    disabledFn?: ((record: any, permission: string) => boolean) | null;
    onOpen?: () => void;
    onClosed?: () => void;
}

export type ShowDialogButtonProps = Props;

const ShowDialogButton = forwardRef<ShowDialogButtonActions, ShowDialogButtonProps>((props: ShowDialogButtonProps, ref) => {
    const theme = useTheme();
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const record = useRecordContext();
    const refresh = useRefresh();

    const {
        id,
        color = theme.palette.primary.main,
        icon = defaultIcon,
        label = 'ra.action.Show',
        withText = false,
        resource,
        children,
        dialogProps,
        disabled = false,
        disabledFn = null,
        onOpen,
        onClosed,
        ...rest
    } = props;

    const [innerOpen, setInnerOpen] = useState<boolean>(dialogProps?.open || false);

    useImperativeHandle(
        ref,
        () => ({
            open() {
                setInnerOpen(true);
            },
            close() {
                setInnerOpen(false);
            },
            isOpen: innerOpen
        }));

    const handleOpen = () => {
        if (!innerOpen) {
            setInnerOpen(true);
            if (onOpen)
                onOpen()
        }
    };

    const handleClose = () => {
        if (innerOpen) {
            setInnerOpen(false);
            refresh()
            if (onClosed)
                onClosed()
        }
    };

    let isDisabled: boolean = disabledFn !== null ? disabledFn(record, permissions) : disabled;

    const finalProps = {
        disabled: isDisabled,
        label: withText ? label : "",
        sx: {
            color: withText ? color : color,
            "&.Mui-disabled .MuiSvgIcon-root": {
                color: alpha(theme.palette.common.black, 0.26)
            },
            px: withText ? theme.spacing(1) : 0,
            minWidth: theme.spacing(4)
        }
    } as ButtonProps

    return (
        <>
            {

                !withText ?
                    <Tooltip title={translate(label)}>
                        <Box>
                            <Button label={label} onClick={handleOpen} {...(finalProps as any)}>
                                {icon}
                            </Button>
                        </Box>
                    </Tooltip>
                    :
                    <Button label={label} onClick={handleOpen} {...(rest as any)}>
                        {icon}
                    </Button>
            }
            <ShowFormDialog
                id={id}
                resource={resource}
                dialogProps={{
                    open: innerOpen,
                    onClosed: handleClose,
                    ...dialogProps,
                }}
            >
                {Utils.CreateOrCloneElement(children)}
            </ShowFormDialog>
        </>
    );
});

const defaultIcon = <Icon />;

export default ShowDialogButton;
