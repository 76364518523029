import { useEffect } from 'react';

import Icon from '@mui/icons-material/Boy';

import { Button, useRedirect, useNotify, useTranslate, useGetList } from 'react-admin';

import { DashboardCard } from 'admin/components';
import AddIcon from '@mui/icons-material/Add';

import { lightBlue } from '@mui/material/colors';

const StudentCard = () => {
    const { total, isLoading, error } = useGetList('students', {
        pagination: { perPage: 1, page: 1 },
    });

    const translate = useTranslate();
    const redirect = useRedirect();
    const notify = useNotify();

    useEffect(() => {
        if (error) {
            console.error(error);
            notify('Error', { type: 'warning' })
        }
    }, [error]);

    return (
        <DashboardCard
            icon={<Icon />}
            iconColor={lightBlue[500]}
            title={translate('students.other.count', { smart_count: total || 0 })}
            loading={isLoading}
        >
            <Button alignIcon='left' label={translate('ra.action.create')} onClick={() => redirect('create', 'students')}>
                <AddIcon />
            </Button>
        </DashboardCard>
    )
};

export default StudentCard;