import { useMemo } from 'react';

import { Box, Divider, Grid, } from '@mui/material';

import {
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    DateInput,
    required,
    FormDataConsumer,
} from 'react-admin';

import { CreateActions, CreateToolbar } from 'admin/components';
import SearchGoogleBookInput from './SearchGoogleBookInput';

const CustomCreate = ({ permissions, options, ...props }) => {
    const defaultValues = useMemo(() => ({}), []);
    return (
        <Create redirect="list" actions={<CreateActions />} {...props}>
            <SimpleForm toolbar={<CreateToolbar saveAlwaysEnable />} defaultValues={defaultValues} >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SearchGoogleBookInput />
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: theme => theme.spacing(2), paddingRight: 0 }}>
                        <Divider flexItem />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <TextInput source="codiceIsbn" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput source="titolo" validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="sottotitolo" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="autori" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="editore" fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <NumberInput source="prezzo" validate={required()} fullWidth min={0} />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="categorie" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <DateInput source="dataPubblicazione" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Box display="flex" justifyContent="space-between" gap={2}>
                            <TextInput source="image" fullWidth />
                            <FormDataConsumer label="">
                                {({ formData }) => <img src={formData.image} style={{ marginTop: 8, height: 48 }} />
                                }
                            </FormDataConsumer>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextInput source="lingua" fullWidth />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default CustomCreate;
