import { useEffect, useState } from 'react';
import { ReactElement } from 'react';
import PropTypes from 'prop-types';

import { FormControl } from '@mui/material';
import Icon from '@mui/icons-material/UploadFileOutlined';

import {
    Button,
    ButtonProps,
    useDataProvider,
    useNotify,
    useTranslate
} from 'react-admin';

import { DropEvent, useDropzone } from 'react-dropzone';
import { Utils } from 'admin/core';

const PickedUpButton = (
    props: PickedUpButtonProps
) => {
    const {
        icon = defaultIcon,
        label = 'ra.action.upload',
        refreshStatus,
    } = props;

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const translate = useTranslate();

    const [isLoading, setIsLoading] = useState(false);

    const onDropAccepted = (files: File[], event: DropEvent) => {
        if (files.length > 0) {
            const formData = new FormData();
            formData.append("file", files[0]);

            setIsLoading(true);

            dataProvider.post(`api/bookingitems/checkstatus/PickedUp`, formData).then((response) => {
                refreshStatus();
            }).catch(error => {
                console.error(error);
                notify('Error', { type: 'warning' })
            }).finally(() => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
            });
        }
    };

    useEffect(() => {
    }, []);

    const acceptedExtensions = ['text/csv'];
    const maxSize = 1048576;

    const { fileRejections, getInputProps, open } = useDropzone({
        accept: acceptedExtensions,
        maxSize,
        noClick: true,
        onDropAccepted,
        noKeyboard: true,
    });

    useEffect(() => {
        if (fileRejections.length > 0) {
            let message: string;

            switch (fileRejections[0]?.errors[0]?.code) {
                case "file-invalid-type":
                    message = translate(`pos.fileupload.errors.${fileRejections[0]?.errors[0]?.code}`, { type: acceptedExtensions.join('|') })
                    break;
                case "file-too-large":
                    message = translate(`pos.fileupload.errors.${fileRejections[0]?.errors[0]?.code}`, { size: Utils.FormatBytes(maxSize) })
                    break;
                default:
                    message = fileRejections[0]?.errors[0]?.message;
                    break;
            }

            notify(message, { type: 'warning' })
        }
    }, [fileRejections]);

    return (
        <FormControl
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
            <input {...getInputProps()} />
            <Button alignIcon='left' label={label} onClick={open}>
                {icon}
            </Button>
        </FormControl>
    );
};

const defaultIcon = <Icon />;

interface Props {
    icon?: ReactElement;
    label?: string;
    refreshStatus: () => void;
}

export type PickedUpButtonProps = Props & ButtonProps;

PickedUpButton.propTypes = {
    icon: PropTypes.element,
    label: PropTypes.string,
    refreshStatus: PropTypes.func
};

export default PickedUpButton;
