import Icon from '@mui/icons-material/MenuBook';
import CustomList from './CustomList';
import CustomShow from './CustomShow';

import { blue } from '@mui/material/colors';

export default {
    list: CustomList,
    show: CustomShow,
    icon: Icon,
    iconColor: blue[400],
};