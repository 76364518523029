export const messages = {
    name: "students",
    resources: {
        name: 'Studente |||| Studenti',
        forcedCaseName: 'Studente |||| Studenti',
        fields: {
            familyName: "Cognome",
            givenName: "Nome",
            fiscalCode: "Codice Fiscale",
            phoneNumber: "Telefono",
            email: "Email",
            address: "Indirizzo",
            parentReference: "Riferimento",
            voucherAmount: "Importo Voucher",
            note: "Note",
            dob: 'Data di Nascita',
        },
    },
    global: {
        menu: {
            name: 'Studenti',
        },
        list: {
            search: 'Cerca',
        },
        form: {
        },
        edit: {
            title: 'Studente "%{title}"',
        },
        action: {
            add: 'Aggiungi Studente',
            saveAndBook: 'Salva e prenota',
        },
        other: {
            selectFiscalCode: 'Seleziona codice fiscale',
            count: '%{smart_count} studente |||| %{smart_count} studenti',
        },
    },
};

export default messages;