export const messages = {
    name: "studentmiuryearcourse",
    resources: {
        name: 'Prenotazione Studente |||| Prenotazioni Studenti',
        forcedCaseName: 'Prenotazione Studente |||| Prenotazioni Studenti',
        fields: {
            code: "Codice",
            active: 'Ordine Attivo',
        },
    },
    global: {
        menu: {
            name: 'Prenotazioni Studenti',
        },
        list: {
            search: 'Cerca',
        },
        form: {
        },
        edit: {
            title: 'Prenotazione Studente "%{title}"',
        },
        action: {
        },
        other: {
        },
    },
};

export default messages;