import { ReactElement, useState, forwardRef, useRef, useImperativeHandle } from 'react';

import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import Icon from '@mui/icons-material/AddPhotoAlternate';

import {
    Button,
    useNotify,
    useDataProvider,
    CreateBase,
    SimpleForm,
    FileInput,
    FileField,
    ImageInput,
    ImageField,
} from 'react-admin';

import { Utils } from 'admin/core';

import { Dialog } from './Dialogs';

const useStyles = makeStyles(theme => ({
    root: {
        width: '70vw'
    }
}));

export interface MediaUploaderActions {
    uploadMediaAsync(): Promise<any[]>;
}

interface MediaUploaderProps {
    open?: boolean;
    setOpen?: () => void;
    onSelected?: (src: string, selectedMedia: any) => void,
    accept?: string | string[];
    type?: string;
    multiple?: boolean;
}

export const MediaUploader = forwardRef<MediaUploaderActions, MediaUploaderProps>((props, ref) => {
    const {
        open = false,
        setOpen = () => { },
        onSelected = () => { },
        accept = 'image/*',
        type = "image",
        multiple = false,
        ...rest
    } = props;

    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [medias, setMedias] = useState<any[]>([]);

    useImperativeHandle(
        ref,
        () => ({
            async uploadMediaAsync() {
                const uploadedFiles = await Promise.all([...medias].map(async (media) => {
                    const formData = new FormData();
                    formData.append("file", media);

                    var objToUploaded = dataProvider.create('api/medias', { data: formData })
                        .then(({ data }) => {
                            return data;
                        })
                        .catch(error => {
                            console.error(error);
                            return null;
                        });

                    return objToUploaded;
                }).filter(x => x != null));

                if (medias.length == uploadedFiles.length)
                    notify(!multiple ? 'pos.media_manager.saved' : 'pos.media_manager.saved_many');

                return uploadedFiles;
            }
        }));

    let _accept;

    if (type) {
        switch (type) {
            case 'image':
                _accept = 'image/*'
                break;
            case 'file':
            default:
                _accept = ''
                break;
        }
    }
    else if (accept) {
        if (Array.isArray(accept)) {
            _accept = accept.join(',');
        }
        else {
            _accept = accept;
        }
    }

    const onlyImages = _accept.split(',').filter(x => x.startsWith('image/')).length == _accept.split(',').length;

    var onChange = (newFiles, rejectedFiles, event) => {
        const files = newFiles ? (Array.isArray(newFiles) ? newFiles : [newFiles]) : [];
        setMedias(files);

        if (onSelected && Utils.IsFunction(onSelected))
            onSelected("", files);

        if (rejectedFiles.length > 0) {
            notify(rejectedFiles[0].errors[0].message, { type: 'error' });
        }
    }

    return (
        <CreateBase resource='medias'>
            <SimpleForm toolbar={false}>
                <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2} className={classes.root}>
                    {
                        onlyImages ?
                            <ImageInput source="file" label={false} options={{ onDrop: onChange }} multiple={multiple} accept={_accept} {...rest}>
                                <ImageField source="src" title="title" />
                            </ImageInput> :
                            <FileInput source="file" label={false} options={{ onDrop: onChange }} multiple={multiple} accept={_accept} {...rest}>
                                <FileField source="src" title="title" />
                            </FileInput>
                    }
                </Grid>
            </SimpleForm>
        </CreateBase>
    );
});

interface MediaUploaderButtonProps {
    onUploaded?: (files: any[]) => void;
    icon?: ReactElement;
    accept?: string | string[];
    type?: string;
    multiple?: boolean;
}

export const MediaUploaderButton = (props: MediaUploaderButtonProps) => {
    const {
        onUploaded = () => { },
        icon = defaultIcon,
        accept = 'image/*',
        type = "image",
        multiple = false,
        ...rest
    } = props;

    const mediaUploaderRef = useRef<MediaUploaderActions>(null);

    const [open, setOpen] = useState(false);

    const handleOpenMediaGallery = e => {
        e.stopPropagation();
        setOpen(true);
    };

    const onConfirmed = async (e) => {
        e.stopPropagation();

        var uploadedFiles: any[] = [];

        if (mediaUploaderRef.current)
            uploadedFiles = await mediaUploaderRef.current.uploadMediaAsync();

        if (onUploaded && Utils.IsFunction(onUploaded)) {
            onUploaded(uploadedFiles);
        }

        setOpen(false);
    }

    const actions = [
        <Button onClick={onConfirmed} color="primary" label={'ra.action.confirm'} />
    ];

    return (
        <>
            <Button label={'ra.action.create'} onClick={handleOpenMediaGallery} {...rest}>
                {icon}
            </Button>
            <Dialog open={open} onOpen={() => setOpen(true)} onClosed={() => setOpen(false)} title={"pos.media_manager.title"} actions={actions}>
                <MediaUploader ref={mediaUploaderRef} accept={accept} type={type} multiple={multiple} {...rest} />
            </Dialog>
        </>
    );
};

const defaultIcon = <Icon />;

MediaUploaderButton.propTypes = {
    onUploaded: PropTypes.func,
    icon: PropTypes.element,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    type: PropTypes.oneOf(['image', 'file']),
    multiple: PropTypes.bool,
};