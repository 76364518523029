import { useMemo } from 'react';

import {
    useTranslate,
    ReferenceInput,
    AutocompleteInput,
    useChoicesContext,
    required,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';

function AutocompleteLoading(props) {
    const translate = useTranslate();
    const { isFetching } = useChoicesContext(props);
    const { setValue } = useFormContext();

    const handleChange = (value, record) => {
        if (value) {
            setValue('codiceIsbn', record?.codiceIsbn);
            setValue('titolo', record?.titolo);
            setValue('sottotitolo', record?.sottotitolo);
            setValue('autori', record?.autori);
            setValue('volume', record?.volume);
            setValue('editore', record?.editore);
            setValue('prezzo', record?.prezzo);
        }
        else {
            setValue('codiceIsbn', null);
            setValue('titolo', null);
            setValue('sottotitolo', null);
            setValue('autori', null);
            setValue('volume', null);
            setValue('editore', null);
            setValue('prezzo', null);
        }
    };

    const label = `${translate('ra.action.search')} ${translate('resources.miuradoptions.name', { smart_count: 1 })}`;

    const optionText = choice => `${choice.codiceIsbn} - ${choice.titolo} - ${choice.volume} (${choice.prezzo.toFixed(2)} €)`;
    const filterToQuery = searchText => ({ "[manual]": `(Contains(codiceIsbn, '${searchText}') or Contains(titolo, '${searchText}'))` });

    const memoizedAutocompleteInput = useMemo(
        () => (
            <AutocompleteInput
                label={label}
                optionText={optionText}
                filterToQuery={filterToQuery}
                onChange={handleChange}
                isLoading={isFetching}
                validate={required()}
                fullWidth />
        ), []
    );

    return (
        memoizedAutocompleteInput
    );
}

const SearchAdoptionInput = () => {
    return (
        <ReferenceInput
            source="adoptionsId"
            reference="miuradoptions"
            sort={{ field: 'titolo', order: 'ASC' }}
            enableGetChoices={({ q }) => q && q.length >= 3}
        >
            <AutocompleteLoading />
        </ReferenceInput>
    );
};

export default SearchAdoptionInput;