import system from './system'
import miur from './miur';

import students from './students';
import studentmiuryearcourse from './studentmiuryearcourse' ;
import bookings from './bookings';
import bookingitems from './bookingitems';
import books from './books';
import emailbooks from './emailbooks';

export default {
    ...system,
    ...miur,

    students,
    studentmiuryearcourse,
    bookings,
    bookingitems,
    books,
    emailbooks,
};