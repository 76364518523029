import GoogleBooksAPI from './GoogleBooksAPI';
import GoogleBook from './GoogleBook';
import Book from '../types/Book';
import { SearchFilters, SearchOptions, SearchOrders, SearchParameters } from '../types/BookAPI';

export default class GoogleBooks implements GoogleBooksAPI {
    public static ConvertGoogleBookToBook(book: GoogleBook): Book {
        // Not all books have image data, get highest res image possible
        // A more thorough demo would have small, medium, large, etc
        let image = 'https://via.placeholder.com/100x150';

        if (book.volumeInfo?.imageLinks) {
            image = book.volumeInfo?.imageLinks.extraLarge;
            if (typeof image === 'undefined') {
                image = book.volumeInfo?.imageLinks.large;
            }
            if (typeof image === 'undefined') {
                image = book.volumeInfo?.imageLinks.medium;
            }
            if (typeof image === 'undefined') {
                image = book.volumeInfo?.imageLinks.small;
            }
            if (typeof image === 'undefined') {
                image = book.volumeInfo?.imageLinks.smallThumbnail;
            }
            if (typeof image === 'undefined') {
                image = book.volumeInfo?.imageLinks.thumbnail;
            }
        }

        let isbn = '';

        if (book.volumeInfo?.industryIdentifiers && book.volumeInfo.industryIdentifiers.length > 0) {
            let ISBN_10 = book.volumeInfo.industryIdentifiers.find(x => x.type === "ISBN_10");
            let ISBN_13 = book.volumeInfo.industryIdentifiers.find(x => x.type === "ISBN_13");

            if (ISBN_13)
                isbn = ISBN_13.identifier;
            else if (ISBN_10) {
                isbn = ISBN_10.identifier;
            }
        }

        let price = 0;

        if (book.saleInfo) {
            let listPrice = book.saleInfo.listPrice?.amount;
            let retailPrice = book.saleInfo.retailPrice?.amount;

            if (listPrice)
                price = listPrice;
            else if (retailPrice) {
                price = retailPrice;
            }
        }

        return {
            authors: book.volumeInfo?.authors || [],
            title: book.volumeInfo.title,
            subtitle: book.volumeInfo?.subtitle || '',
            categories: book.volumeInfo?.categories || [],
            publisher: book.volumeInfo?.publisher || '',
            publishedDate: book.volumeInfo?.publishedDate || '',
            image,
            link: book.volumeInfo.infoLink,
            language: book.volumeInfo?.language || '',
            isbn,
            price
        };
    }

    public static ConvertGoogleBooksToBooks(books: GoogleBook[]): Book[] {
        const convertedBooks: Book[] = [];

        for (const b of books) {
            convertedBooks.push(GoogleBooks.ConvertGoogleBookToBook(b));
        }

        return convertedBooks;
    }

    private readonly apiUrl = 'https://www.googleapis.com/books/v1/volumes';
    private key: string | undefined;
    private lastQuery: string | null;

    private maxResults: number;
    private parameter: SearchParameters;
    private filter: SearchFilters;
    private order: SearchOrders;

    /**
     * @param key
     */
    public constructor(key?: string) {
        this.key = key;
        this.lastQuery = null;

        this.maxResults = 12;
        this.parameter = SearchParameters.Title;
        this.filter = SearchFilters.All;
        this.order = SearchOrders.Relevance;

        if (!this.hasKey()) {
            console.warn('Google Books API initiated without an API Key. Quota restrictions and errors may occur.');
        }
    }

    /**
     * @return {boolean}
     */
    public hasKey(): boolean {
        return typeof this.key === 'string';
    }

    /**
     * @return {string | undefined}
     */
    public getKey(): string | undefined {
        return this.key;
    }

    /**
     * @param props
     * @return {Promise<Book[] | never>}
     */
    public search(query: string, searchOptions?: SearchOptions): Promise<Book[] | never> {
        if (!searchOptions)
            searchOptions = {};

        if (!searchOptions.maxResults || searchOptions.maxResults <= 0)
            searchOptions.maxResults = this.maxResults;

        if (!searchOptions.parameter)
            searchOptions.parameter = this.parameter;

        if (!searchOptions.filter)
            searchOptions.filter = this.filter;

        if (!searchOptions.order)
            searchOptions.order = this.order;

        const url = `${this.apiUrl}?q=${searchOptions.parameter}${encodeURI(query.trim())}${searchOptions.filter}${searchOptions.order}&maxResults=${searchOptions.maxResults}${this.hasKey() ? '&key=' + this.getKey() : ''}`;

        console.debug(`Google Books API request ${url} is starting`);

        if (!query.trim())
            return Promise.reject();

        if (this.lastQuery !== null && this.lastQuery.trim() === query.trim())
            return Promise.reject();

        return fetch(url)
            .then((res) => res.json())
            .then((data) => {
                if (!data.error) {
                    this.lastQuery = query.trim();

                    if (data.totalItems === 0) {
                        return Promise.resolve([]);
                    } else if (data.totalItems === undefined) {
                        return Promise.reject("Sorry! There seems to be some networks issues. Please try again later.");
                    } else {
                        return GoogleBooks.ConvertGoogleBooksToBooks(data.items);
                    }
                }
                else {
                    if (data.error.status === "PERMISSION_DENIED")
                        return Promise.reject("Looks like you don't have permission to use this API");
                    else
                        return Promise.reject(data.error.message);
                }
            })
            .catch(error => {
                console.error(error);
                return Promise.reject(error);
            })
            .finally(() => {
                console.debug(`Google Books API request ${url} has finalised`);
            })
    }
}