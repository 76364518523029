import { ReactNode, useEffect, useState } from 'react';

import { Box, Grid, Paper } from '@mui/material';

import DownloadIcon from '@mui/icons-material/FileDownload';
import ShowIcon from '@mui/icons-material/RemoveRedEye';
import RefreshloadIcon from '@mui/icons-material/Mail';

import { grey } from '@mui/material/colors';

import { Button, Confirm, downloadCSV, useDataProvider, useRedirect, useNotify, useTranslate } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { Utils } from 'admin/core';

import { ConfirmButton, DashboardCard } from 'admin/components';
import { BookingStatusTypeIcon, GetBookingStatusTypeColor } from '../Common/BookingStatusTypeField';
import { PickedUpButton, ManageBookingsButton } from './Buttons';
import { BookingStatusType } from 'admin/types';

interface BookingsStatus {
    toBook: number;
    notToBook: number;
    booked: number;
    pickedUp: number;
    toCover: number;
    ready: number;
    completed: number;
}

const defaultValue: BookingsStatus = {
    toBook: 0,
    notToBook: 0,
    booked: 0,
    pickedUp: 0,
    toCover: 0,
    ready: 0,
    completed: 0
};

interface CommonDashboardCardProps {
    status: BookingStatusType | string;
    mainLoading?: boolean;
    refreshStatus: () => void;
    count?: number;
    hideShow?: boolean;
    hideRefresh?: boolean;
    hideManage?: boolean;
    children?: ReactNode | null;
}

interface Props {
    reloadDashboard?: () => void;
}

export type BookingStatusCardsProps = Props;

const CommonDashboardCard = (props: CommonDashboardCardProps) => {
    const {
        status,
        mainLoading = false,
        refreshStatus,
        count,
        hideShow = false,
        hideRefresh = false,
        hideManage = false,
        children,
    } = props;

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const translate = useTranslate();
    const redirect = useRedirect();

    useEffect(() => {
        setIsLoading(mainLoading);
    }, [mainLoading]);

    const handleCheckStatus = () => {
        setIsLoading(true);

        dataProvider.post(`api/bookingitems/checkstatus/${status}`).then((response) => {
            refreshStatus();
        }).catch(error => {
            console.error(error);
            notify('Error', { type: 'error' })
        }).finally(() => {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        });
    };

    const handleRedirect = () => redirect(`bookingitems?filter=${JSON.stringify({ currentStatus: status })}`);

    const statusColors = GetBookingStatusTypeColor(status);

    return (
        <>
            <DashboardCard
                icon={<BookingStatusTypeIcon value={status} />}
                iconColor={statusColors.iconColor}
                color={statusColors.color}
                title={translate(`bookings.other.statusCount.${status}`, { smart_count: count })}
                loading={isLoading}
            >
                <Grid container spacing={2}>
                    {
                        !hideShow &&
                        <Grid item flexGrow="1">
                            <Box display="flex" justifyContent="center">
                                <Button alignIcon='left' label={translate('ra.action.show')} onClick={handleRedirect} disabled={count === 0}>
                                    <ShowIcon />
                                </Button>
                            </Box>
                        </Grid>
                    }
                    {
                        !hideRefresh &&
                        <Grid item flexGrow="1">
                            <Box display="flex" justifyContent="center">
                                <ConfirmButton
                                    label={translate('ra.action.sync')}
                                    icon={<RefreshloadIcon />}
                                    title={translate('pos.dashboard.confirmDialogSync.title')}
                                    content={translate('pos.dashboard.confirmDialogSync.content', { status: translate(`pos.enums.BookingStatusType.${status}`, { smart_count: 1 }) })}
                                    onConfirm={handleCheckStatus}
                                />
                            </Box>
                        </Grid>
                    }
                    {children}
                    {
                        !hideManage &&
                        <Grid item flexGrow="1">
                            <Box display="flex" justifyContent="center">
                                <ManageBookingsButton refreshStatus={refreshStatus} currentStatus={status} disabled={count === 0} />
                            </Box>
                        </Grid>
                    }
                </Grid>
            </DashboardCard>
        </>
    )
}

export const BookingStatusCards = (props: BookingStatusCardsProps) => {
    const {
        reloadDashboard = () => { },
    } = props;

    const [bookingStatus, setBookingStatus] = useState<BookingsStatus>(defaultValue);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [isFirstOpen, setIsFirstOpen] = useState(true);

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const translate = useTranslate();

    const refreshStatus = () => {
        setIsLoading(true);

        dataProvider.get(`api/bookingitems/status`).then((response) => {
            const { data } = response || { data: [] };
            setBookingStatus(data);
        }).catch(error => {
            console.error(error);
            notify('Error', { type: 'warning' })
            setBookingStatus(defaultValue);
        }).finally(() => {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        });
    }

    useEffect(() => {
        if (isFirstOpen) {
            refreshStatus();
            setIsFirstOpen(false);
        }
    }, []);

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        dataProvider
            .get('api/bookingitems/csvList')
            .then(response => {
                const { data } = response || { data: [] };

                if (data.length > 0) {
                    jsonExport(data, {
                        includeHeaders: false,
                        headers: ['isbn', 'quantity'] // order fields in the export
                    }, (err: any, csv: any) => {

                        if (err)
                            notify(err, { type: 'warning' })

                        downloadCSV(csv, `centrolibri_${Utils.FormatToDate(new Date(), "it", false, false, '')}`);
                    });
                    setOpen(false);
                }
                else {
                    notify('Not Generated', { type: 'warning' })
                }
            })
            .catch(error => {
                console.error(error);
                notify('Not Generated', { type: 'warning' })
            })
            .finally(() => {
                setOpen(false);
            })
    };

    return (
        <Paper sx={{ padding: theme => theme.spacing(2), backgroundColor: grey[100] }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <CommonDashboardCard status={BookingStatusType.ToBook} mainLoading={isLoading} refreshStatus={refreshStatus} count={bookingStatus?.toBook} hideRefresh hideManage>
                        <Grid item flexGrow="1">
                            <Box display="flex" justifyContent="center">
                                <Button alignIcon='left' label={translate('bookingitems.action.generateFileButton')} onClick={handleClick} disabled={bookingStatus?.toBook === 0}>
                                    <DownloadIcon />
                                </Button>
                                <Confirm
                                    isOpen={open}
                                    loading={isLoading}
                                    title={translate('bookingitems.action.generateFileTitle')}
                                    content={translate('bookingitems.action.generateFileContent', { smart_count: bookingStatus?.toBook })}
                                    onConfirm={handleConfirm}
                                    onClose={handleDialogClose}
                                />
                            </Box>
                        </Grid>
                    </CommonDashboardCard>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CommonDashboardCard status={BookingStatusType.Booked} mainLoading={isLoading} refreshStatus={refreshStatus} count={bookingStatus?.booked} hideManage />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <CommonDashboardCard status={BookingStatusType.PickedUp} mainLoading={isLoading} refreshStatus={refreshStatus} count={bookingStatus?.pickedUp}>
                        <Grid item flexGrow="1">
                            <Box display="flex" justifyContent="center">
                                <PickedUpButton refreshStatus={refreshStatus} />
                            </Box>
                        </Grid>
                    </CommonDashboardCard>
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                    <CommonDashboardCard status={BookingStatusType.ToCover} mainLoading={isLoading} refreshStatus={reloadDashboard} count={bookingStatus?.toCover} hideRefresh />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                    <CommonDashboardCard status={BookingStatusType.Ready} mainLoading={isLoading} refreshStatus={reloadDashboard} count={bookingStatus?.ready} hideRefresh />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CommonDashboardCard status={BookingStatusType.Completed} mainLoading={isLoading} refreshStatus={reloadDashboard} count={bookingStatus?.completed} hideRefresh />
                </Grid>
            </Grid>
        </Paper>
    )
};