import { Grid, IconButton, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Cached';

import { Authenticated, useTranslate } from 'react-admin';

import { BookingStatusCards } from './BookingStatusCards';
import StudentCard from './StudentCard';
import SearchCard from './SearchCard';
import ReadyCard from './ReadyCard';

import { Utils } from 'admin/core';

const Dashboard = () => {
    const translate = useTranslate();

    const reloadDashboard = () => {
        window.location.reload();
    };

    return (
        <Authenticated>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <SearchCard />
                        </Grid>
                        <Grid item xs={2}>
                            <StudentCard />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <BookingStatusCards reloadDashboard={reloadDashboard} />
                </Grid>
                <Grid item xs={12}>
                    <ReadyCard reloadDashboard={reloadDashboard} />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Typography variant="body2" color="textSecondary">
                        {translate('pos.dashboard.lastUpdate', { date: Utils.FormatToDate(new Date(), "it", true) })}

                        <IconButton onClick={reloadDashboard}>
                            <RefreshIcon fontSize='small' />
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
        </Authenticated>
    )
};

export default Dashboard;