export default {
    name: "books",
    resources: {
        name: 'Libro |||| Libri',
        forcedCaseName: 'Libro |||| Libri',
        fields: {
            codiceIsbn: "Codice Isbn",
            autori: "Autori",
            titolo: "Titolo",
            sottotitolo: "Sottotitolo",
            editore: "Editore",
            prezzo: "Prezzo",
            categorie: "Categorie",
            dataPubblicazione: "Data Pubblicazione",
            image: "Image",
            lingua: "Lingua",
        },
    },
    global: {
        menu: {
            name: 'Libri',
        },
        list: {
            search: 'Cerca',
        },
        form: {
        },
        edit: {
            title: 'Libro "%{title}"',
        },
        action: {
        },
    },
};