import debounce from 'lodash/debounce';
import {
    useRef,
    useEffect,
    useCallback,
    useState,
} from 'react';
import Quill from 'quill';

import {
    useInput,
    FieldTitle,
} from 'react-admin';

import MediaGallery from '../layout/MediaGallery';

import InputHelperText from './InputHelperText';
import {
    FormHelperText,
    FormControl,
    InputLabel,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';

import styles from './RichTextStyle';
import ImageResize from 'quill-image-resize-module-react';
Quill.register('modules/imageResize', ImageResize)

const useStyles = makeStyles(styles, { name: 'RaRichTextInput' });

const defaultToolbar = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    //[{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    //[{ 'direction': 'rtl' }],                         // text direction

    //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    //[{ 'font': [] }],
    [{ 'align': [] }],

    //['link', 'image'],

    ['clean']                                         // remove formatting button
]

const RichTextInput = props => {
    const {
        options = {}, // Quill editor options
        toolbar,
        fullWidth = true,
        classes: classesOverride,
        configureQuill,
        helperText,
        label,
        source,
        resource,
        variant,
        margin = 'dense',
        validate,
        ...rest
    } = props;
    const classes = useStyles(props);
    const quillInstance = useRef();
    const divRef = useRef();
    const editor = useRef();
    const [openMediaGallery, setOpenMediaGallery] = useState(false);

    const {
        id,
        field: { value, onChange },
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted },
        isRequired,
    } = useInput({ source, ...rest });

    const lastValueChange = useRef(value);

    const onSelected = useCallback(
        debounce((src, media) => {
            if (src) {
                const selection = quillInstance.current.getSelection();
                quillInstance.current.insertEmbed(selection.index, 'image', src);

                if (selection && quillInstance.current.hasFocus()) {
                    quillInstance.current.setSelection(selection);
                }
            }
        }, 500),
        [onChange]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onTextChange = useCallback(
        debounce(() => {
            const value = editor.current.innerHTML === '<p><br></p>' ? '' : editor.current.innerHTML;

            if (lastValueChange.current !== value) {
                lastValueChange.current = value;
                onChange(value);
            }
        }, 500),
        [onChange]
    );

    useEffect(() => {
        quillInstance.current = new Quill(divRef.current, {
            modules: {
                toolbar,
                clipboard: {
                    matchVisual: false
                },
                imageResize: {
                    parchment: Quill.import('parchment')
                }
            },
            theme: 'snow',
            ...options,
        });

        if (configureQuill) {
            configureQuill(quillInstance.current);
        }

        quillInstance.current.getModule('toolbar').addHandler('image', () => setOpenMediaGallery(true));

        quillInstance.current.setContents(
            quillInstance.current.clipboard.convert(value)
        );

        editor.current = divRef.current.querySelector('.ql-editor');
        quillInstance.current.on('text-change', onTextChange);

        return () => {
            quillInstance.current.off('text-change', onTextChange);
            if (onTextChange.cancel) {
                onTextChange.cancel();
            }
            quillInstance.current = null;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (lastValueChange.current !== value) {
            const selection = quillInstance.current.getSelection();
            quillInstance.current.setContents(
                quillInstance.current.clipboard.convert(value)
            );
            if (selection && quillInstance.current.hasFocus()) {
                quillInstance.current.setSelection(selection);
            }
        }
    }, [value]);

    return (
        <FormControl
            error={!!((isTouched || isSubmitted) && error)}
            fullWidth={fullWidth}
            className="ra-rich-text-input"
            margin={margin}
        >
            {label !== '' && label !== false && (
                <InputLabel shrink htmlFor={id} className={classes.label}>
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                </InputLabel>
            )}
            <div data-testid="quill" ref={divRef} className={variant} />
            <MediaGallery open={openMediaGallery} setOpen={setOpenMediaGallery} onSelected={onSelected} />
            <FormHelperText
                error={!!error}
                className={!!error ? 'ra-rich-text-input-error' : ''}
            >
                <InputHelperText
                    error={error}
                    helperText={helperText}
                    touched={isTouched}
                />
            </FormHelperText>
        </FormControl>
    );
};

RichTextInput.propTypes = {
    label: PropTypes.string,
    options: PropTypes.object,
    source: PropTypes.string,
    fullWidth: PropTypes.bool,
    configureQuill: PropTypes.func,
    validate: PropTypes.object,
};

RichTextInput.defaultProps = {
    toolbar: defaultToolbar
}

export default RichTextInput;