import { useEffect } from 'react';
import { ReactElement } from 'react';

import Icon from '@mui/icons-material/BackHandOutlined';

import {
    useTranslate
} from 'react-admin';

import { DialogButton } from 'admin/components/buttons';
import { BookingStatusType } from 'admin/types';
import { BookingItemsDataGrid } from 'admin/views/Common';

const ManageBookingsButton = (
    props: ManageBookingsButtonProps
) => {
    const {
        icon = defaultIcon,
        label = 'ra.action.manage',
        currentStatus,
        refreshStatus = () => { },
        disabled
    } = props;
    const translate = useTranslate();

    useEffect(() => {
    }, []);

    const handleOpen = () => {
    };

    const handleClosed = () => {
        refreshStatus();
    };

    return (
        <DialogButton
            icon={icon}
            label={label}
            onOpen={handleOpen}
            onClosed={handleClosed}
            dialogTitle={translate("bookingitems.other.pickedUpDialogTitle")}
            disabled={disabled}
            fullScreen
            preventEscapeKeyDown
        >
            <BookingItemsDataGrid
                filter={{ currentStatus: currentStatus }}
                sortField="studentCode"
                sortDirection="asc"
                allowEditing
                hideTotal
                showFilter
                showStudentCode
            />
        </DialogButton>
    );
};

const defaultIcon = <Icon />;

interface Props {
    icon?: ReactElement;
    label?: string;
    currentStatus: BookingStatusType | string;
    refreshStatus: () => void;
    disabled?: boolean;
}

export type ManageBookingsButtonProps = Props;

export default ManageBookingsButton;
