import { useEffect, useState } from 'react';

import {
    useTranslate,
    useDataProvider,
    useNotify,
    FunctionField,
    Datagrid,
    Loading,
    TextField,
    ReferenceField,
    NumberField
} from 'react-admin';

import { Dialog } from 'admin/components';

import Typography from '@mui/material/Typography';

import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import PropTypes from 'prop-types';

import { Utils } from 'admin/core';
import { green, red } from '@mui/material/colors';
import { mapSchoolClassInfo } from 'admin/mappers';

const ClassAdoptions = ({ open, setOpen, miurYearCourse }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [isLoading, setIsLoading] = useState(true);
    const [miurAdoptionMiurYearCourses, setMiurAdoptionMiurYearCourses] = useState([]);

    const [innerOpen, setInnerOpen] = useState(open);
    const translate = useTranslate();

    useEffect(() => {
        setInnerOpen(open);

        if (open && miurAdoptionMiurYearCourses.length === 0) {
            setIsLoading(true);

            dataProvider.get(`api/miurYearCourses/adoptions/${miurYearCourse.id}`).then((response) => {
                const { data } = response || { data: [] };
                if (data.length > 0)
                    setMiurAdoptionMiurYearCourses(data.map(x => {
                        x.id = Utils.UUIDv4();
                        return x;
                    }));
            }).catch(error => {
                console.error(error);
                notify('Error', { type: 'warning' })
                setMiurAdoptionMiurYearCourses([]);
            }).finally(() => {
                setIsLoading(false);
            });
        }
        else
            setIsLoading(false);
    }, [open]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClosed = () => {
        setOpen(false);
    };

    if (!open) return <></>;

    if (isLoading) return <Loading loadingPrimary='' loadingSecondary='' />;

    return (
        <Dialog open={innerOpen} onOpen={handleOpen} onClosed={handleClosed} title={translate("miuradoptions.other.dialogTitle", { title: mapSchoolClassInfo(miurYearCourse) })}>
            {
                miurAdoptionMiurYearCourses.length > 0 ?
                    <Datagrid resource="miuradoptions" data={miurAdoptionMiurYearCourses} isLoading={isLoading} bulkActionButtons={false}>
                        <ReferenceField source="miurDisciplinesId" reference="miurDisciplines" link={false} sortable={false}>
                            <TextField source="disciplina" />
                        </ReferenceField>
                        <FunctionField source="nuovaAdoz" textAlign='center' render={record => record.nuovaAdoz ? <DoneIcon sx={{ color: green[500] }} /> : <ClearIcon sx={{ color: red[500] }} />} sortable={false} />
                        <FunctionField source="consigliato" textAlign='center' render={record => record.consigliato ? <DoneIcon sx={{ color: green[500] }} /> : <ClearIcon sx={{ color: red[500] }} />} sortable={false} />
                        <FunctionField source="daAcquist" textAlign='center' render={record => record.daAcquist ? <DoneIcon sx={{ color: green[500] }} /> : <ClearIcon sx={{ color: red[500] }} />} sortable={false} />
                        <TextField source="autori" sortable={false} />
                        <TextField source="titolo" sortable={false} />
                        <TextField source="volume" textAlign="center" sortable={false} />
                        <TextField source="editore" sortable={false} />
                        <NumberField source="prezzo" sortable={false} textAlign="center" options={{
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: 2
                        }} />
                    </Datagrid> :
                    <Typography variant="subtitle2" gutterBottom>
                        {translate("miuradoptions.other.noAdoptions")}
                    </Typography>
            }
        </Dialog>
    );
};

ClassAdoptions.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    miurYearCourse: PropTypes.object.isRequired,
};

ClassAdoptions.defaultProps = {
    open: false,
}

export default ClassAdoptions;