import { Route } from 'react-router-dom';
import { CustomRoutes } from 'react-admin';

import UserProfile from './Tables/System/UserProfile/UserProfile';
import AccessDenied from 'admin/components/rbac/AccessDenied';

//import Configurations from './Configurations';

export default <CustomRoutes>
    <Route path="/profile" element={<UserProfile />} />,
    <Route path="/access-denied" element={<AccessDenied />} />
    {/* <Route exact path="/settings" element={Configurations.page} /> */}
</CustomRoutes>