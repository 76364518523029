import * as React from 'react';

import { Grid } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    EmailField,
} from 'react-admin';

import { ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';

const CustomShow = props => {
    return (
        <Show {...props} actions={<ShowActions hasEdit={false} />} title={<CustomTitle />}>
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="annoScolastico">
                            <TextField source="annoScolastico" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="areaGeografica">
                            <TextField source="areaGeografica" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="regione">
                            <TextField source="regione" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="provincia">
                            <TextField source="provincia" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="codiceIstitutoRiferimento">
                            <TextField source="codiceIstitutoRiferimento" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="denominazioneIstitutoRiferimento">
                            <TextField source="denominazioneIstitutoRiferimento" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="codiceScuola">
                            <TextField source="codiceScuola" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="denominazioneScuola">
                            <TextField source="denominazioneScuola" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="indirizzoScuola">
                            <TextField source="indirizzoScuola" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="capScuola">
                            <TextField source="capScuola" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="codiceComuneScuola">
                            <TextField source="codiceComuneScuola" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="descrizioneComune">
                            <TextField source="descrizioneComune" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="descrizioneCaratteristicaScuola">
                            <TextField source="descrizioneCaratteristicaScuola" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="descrizioneTipologiaGradoIstruzioneScuola">
                            <TextField source="descrizioneTipologiaGradoIstruzioneScuola" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="indicazioneSedeDirettivo">
                            <TextField source="indicazioneSedeDirettivo" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="indicazioneSedeOmnicomprensivo">
                            <TextField source="indicazioneSedeOmnicomprensivo" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="indirizzoEmailScuola">
                            <EmailField source="indirizzoEmailScuola" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="indirizzoPecScuola">
                            <EmailField source="indirizzoPecScuola" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="sitoWebScuola">
                            <TextField source="sitoWebScuola" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="sedeScolastica">
                            <TextField source="sedeScolastica" />
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;
