import * as React from 'react';
import { Utils } from 'admin/core';
import { useRecordContext, useResourceContext, useTranslate } from 'react-admin';
import { mapStudentDisplayName } from 'admin/mappers';

export default () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const translate = useTranslate();
    return (
        <span>
            {
                record ? translate(`${resource}.edit.title`, {
                    student: mapStudentDisplayName(record?.bookings?.studentMiurYearCourse?.student),
                    date: Utils.FormatToDate(record.statusDateTime),
                    status: translate(`pos.enums.BookingStatusType.${record.currentStatus}`,
                        { smart_count: 1 })
                }) : ''}
        </span>
    );
};
