import Icon from '@mui/icons-material/ShoppingBasket';
import CustomList from './CustomList';
import CustomShow from './CustomShow';
import { brown } from '@mui/material/colors';

export default {
    list: CustomList,
    show: CustomShow,
    icon: Icon,
    iconColor: brown[400],
};