import miuradoptions from './miuradoptions'; 
import miuryearcourses from './miuryearcourses'; 

export default {
    name: "miuradoptionsmiuryearcourses",
    resources: {
        name: 'Adozione Classe |||| Adozioni Classi',
        forcedCaseName: 'Adozione Classe |||| Adozioni Classi',
        fields: {
            miurAdoptionsId: "Adozione",
            miurYearCoursesId: "Classe",
            nuovaAdoz: "Nuova Adozione",
            daAcquist: "Da Acquistare",
            consigliato: "Consigliato",

            miurAdoptions: { ...miuradoptions.resources.fields },
            miurYearCourses: { ...miuryearcourses.resources.fields },
        },
        groups: {
        },
    },
    global: {
        menu: {
            name: 'Adozioni Classi',
        },
        list: {
        },
        form: {
        },
        validation: {
        },
        edit: {
            title: 'Adozione Classe "%{title}"',
        },
        action: {
        },
        tabs: {
        },
        groups: {
        },
        other: {
        },
        status: {
        },
    },
};