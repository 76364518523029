import { cloneElement } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';

import ScolasticaIcon from '@mui/icons-material/School';
import VariaIcon from '@mui/icons-material/AccountBalance';
import Icon from '@mui/icons-material/Apps';

import { Box, Tooltip, alpha } from '@mui/material';
import { grey, blue, lightBlue } from '@mui/material/colors';

import { BookingType } from 'admin/types';

export const GetBookingTypeColor = (value: BookingType | string) => {
    let iconColor: any = null;

    switch (value) {
        case BookingType.Scolastica:
            iconColor = blue[700];
            break;
        case BookingType.Varia:
            iconColor = grey[800];
            break;
        default:
            iconColor = lightBlue[500];
            break;
    }

    return {
        iconColor,
        color: alpha(iconColor, 0.3)
    }
}

export const BookingTypeIcon = ({ value, fontSize = 'medium', ...props }) => {
    const translate = useTranslate();

    let typeIcon: any = null;

    switch (value) {
        case BookingType.Scolastica:
            typeIcon = <ScolasticaIcon />;
            break;
        case BookingType.Varia:
            typeIcon = <VariaIcon />;
            break;
        default:
            typeIcon = <Icon />;
            break;
    }

    return (
        <Tooltip title={translate(`pos.enums.BookingType.${value}`, { smart_count: 1 })}>
            {cloneElement(typeIcon, { fontSize: fontSize, sx: { color: GetBookingTypeColor(value).iconColor, }, ...props })}
        </Tooltip>
    )
}

export const BookingTypeField = (props) => {
    const record = useRecordContext(props);
    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <BookingTypeIcon value={record[props.source]} />
        </Box>
    )
}