export const messages = {
    name: "emailbooks",
    resources: {
        name: 'Ordine da email |||| Ordini da email',
        forcedCaseName: 'Ordine da email |||| Ordini da email',
        fields: {
            bookingItemId: 'Prenotazione',
            emailMessageId: 'Email',
            destinazione: "Destinazione",
            isbn: "Codice Isbn",
            titolo: "Titolo",
            autore: "Autore",
            prezzo: "Prezzo",
            sconto: "Sconto",
            bookingType: 'Tipologia',
            status: 'Stato',
        },
    },
    global: {
        menu: {
            name: 'Ordini da email',
        },
        list: {
            search: 'Cerca',
        },
        form: {
        },
        edit: {
            title: 'Ordine da email %{isbn}',
        },
        filters: {
            status: 'Stato',
            bookingType: 'Tipologia',
            notAssigned: 'Non assegnati',
        },
        action: {
            showEmail: 'Vedi Email',
            noEmail: 'No Email',
            changeBookingItem: 'Cambia assegnazione'
        },
        other: {
            notAssignableForIsbn: 'Nessuna prenotazione disponibile per ISBN %{isbn}',
            showEmail: {
                dialogTitle: "Email",
                sentFrom: "Inviata da:",
                sentDate: "Inviata in data:",
                attachments: "Allegati:",
                noAttachments: "Nessun allegato",
                html: "Contenuto",
            },
            changeBookingItem: {
                dialogTitle: 'Aggiornamento assegnazione libro',
            },
        }
    },
};

export default messages;