import bookingitems from './bookingitems';
import students from './students';

export const messages = {
    name: "bookings",
    resources: {
        name: 'Prenotazione |||| Prenotazioni',
        forcedCaseName: 'Prenotazione |||| Prenotazioni',
        fields: {
            studentsId: 'Studente',
            religion: "Religione",
            covered: 'Foderati',
            advanceAmount: "Acconto",
            orderDate: "Data ordine",
            closedAt: "Chiuso il",
            note: "Note",
            studentName: "Studente",
            closeable: "Chiudibile",

            students: { ...students.resources.fields },
            bookingItems: { ...bookingitems.resources.fields },

            total: 'Tot. Prenotazione',
            advanceAmountNow: 'Acc. Iniziale',
            totalAdvanceUsed: 'Acc. Utilizzato',
            totalAdvanceToUse: 'Acc. Da Usare',
            totalAdvanceRemain: 'Acc. Disponibile',
            totalToPay: 'Tot. Da Pagare',
            totalToPayIn: 'Tot. In Consegna',
            totalPaied: 'Tot. Pagato Finora',
        },
    },
    global: {
        menu: {
            name: 'Prenotazioni',
        },
        list: {
            search: 'Cerca',
        },
        form: {
        },
        edit: {
            title: 'Prenotazione del %{date}',
            noBookingitems: 'Nessun libro presente',
        },
        action: {
            ToBookAll: 'Tutti da prenotare',
            NotToBookAll: 'Tutti da non prenotare',
            confirmDialog: {
                title: 'Chiusura ordine',
                content: 'Conferma la chiusura dell\'ordine \'%{bookingCode}\' di \'%{studentName}\'?'
            }
        },
        other: {
            manage: 'Gestisci ritirati',
            manageUnmatched: 'Gestisci libri non associati',
            statusCount: {
                ToBook: 'Da prenotare %{smart_count}',
                Booked: 'Ordinato %{smart_count} |||| Ordinati %{smart_count}',
                PickedUp: 'Ritirato %{smart_count} |||| Ritirati %{smart_count}',
                ToCover: 'Da foderare %{smart_count}',
                Ready: 'Pronto per ritiro %{smart_count} |||| Pronti per ritiro %{smart_count}',
                Completed: 'Consegnato %{smart_count} |||| Consegnati %{smart_count}'
            },
            checkStatusResultOk: 'Associato %{smart_count} libri |||| Associati %{smart_count} libri',
            checkStatusResultKo: 'C\'è %{smart_count} libro non associato |||| Ci sono %{smart_count} libri non associati',
            confirmDialogCheckStatusResultKo: {
                title: 'Controlla libri non associati',
                content: 'C\'è %{smart_count} libro non associato |||| Ci sono %{smart_count} libri non associati. Vuoi vederli?'
            },
            readyToComplete: 'Consegna libri di %{studentName} (%{bookingCode})',
            closedAt: 'Chiuso il %{date}',
            closeable: 'Tutti i libri sono stati consegnati',
            confirmDialogDeleteBook: {
                title: 'Rimozione libro dall\'ordine',
                content: 'Confermi la rimozione del libro \'%{titolo}\' (%{codiceIsbn})?'
            }
        },
    },
};

export default messages;

