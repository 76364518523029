import { Card, CardContent } from '@mui/material';
import { FilterDateInput } from 'admin/components/layout/Filters';

import {
    FilterList,
    FilterLiveSearch,
} from 'react-admin';

const FilterSidebar = () => {
    return (
        <Card
            sx={{
                display: { xs: 'none', md: 'block' },
                order: -1,
                width: '15em',
                mr: 2,
                alignSelf: 'flex-start',
                minWidth: 280
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterList label="" icon={false}>
                    <FilterLiveSearch
                        label="resources.studentmiuryearcourse.fields.code"
                        source="studentMiurYearCourse/code"
                        variant='outlined'
                    />
                    <FilterLiveSearch
                        label="resources.students.fields.familyName"
                        source="studentMiurYearCourse/student/familyName"
                        variant='outlined'
                    />
                    <FilterLiveSearch
                        label="resources.students.fields.givenName"
                        source="studentMiurYearCourse/student/givenName"
                        variant='outlined'
                    />
                    <FilterLiveSearch
                        label="resources.miurschools.fields.denominazioneScuola"
                        source="studentMiurYearCourse/miurYearCourses/miurSchools/denominazioneScuola"
                        variant='outlined'
                    />
                    <FilterDateInput source="closedAt" />
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default FilterSidebar;