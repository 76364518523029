import { ChangeEvent, memo, useMemo } from 'react';
import { SxProps } from '@mui/system';
import { useListFilterContext, useResourceContext, useTranslateLabel } from 'ra-core';
import { FormProvider, useForm } from 'react-hook-form';

import { DateInput, DateInputProps } from 'react-admin';

export const FilterDateInput = memo((props: FilterDateInputProps) => {
    const { filterValues, setFilters } = useListFilterContext();
    const resource = useResourceContext();
    const translateLabel = useTranslateLabel();

    const {
        source = 'q',
        label,
        placeholder,
        ...rest
    } = props;

    const finalLabel = translateLabel({ source, label, resource }) as string;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setFilters({ ...filterValues, [source]: event.target.value }, null);
        } else {
            const { [source]: _, ...filters } = filterValues;
            setFilters(filters, null, false);
        }
    };

    const initialValues = useMemo(
        () => ({
            [source]: filterValues[source],
        }),
        [filterValues, source]
    );

    const form = useForm({ defaultValues: initialValues });

    const onSubmit = e => {
        e.preventDefault();
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit}>
                <DateInput
                    helperText={false}
                    source={source}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    label={rest.hiddenLabel ? false : finalLabel}
                    placeholder={
                        placeholder ?? (rest.hiddenLabel ? finalLabel : undefined)
                    }
                    {...rest}
                />
            </form>
        </FormProvider>
    );
});

export interface FilterDateInputProps extends Omit<DateInputProps, 'source'> {
    source?: string;
    sx?: SxProps;
    label?: string;
    fullWidth?: boolean;
    variant?: 'filled' | 'outlined';
}
