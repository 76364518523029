import { useCallback, useState } from 'react';

import { Checkbox, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Theme, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";

import SearchIcon from '@mui/icons-material/Search';
import Icon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/RestartAlt';
import BackIcon from '@mui/icons-material/ArrowBack';

import {
    Create,
    SimpleForm,
    TextInput,
    Toolbar,
    SaveButton,
    useDataProvider,
    useNotify,
    useRedirect,
    Loading,
    useTranslate,
} from 'react-admin';
import { CustomConfirmButton } from 'admin/components';
import { mapSchoolClassInfo } from 'admin/mappers';

const useStyles = makeStyles<Theme>((theme) => ({
    toolbar: {
        margin: 0,
        padding: 0,
        minHeight: 'auto',
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'end',
        paddingRight: 15,
        paddingLeft: 15,
    },
}));

const CustomSaveButton = props => {
    const redirect = useRedirect();
    const onSuccess = (response) => {
        redirect(false);
    };
    return <SaveButton {...props} className='m-0' icon={<SearchIcon />} label="ra.action.search" mutationOptions={{ onSuccess }} />;
}

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <CustomSaveButton />
    </Toolbar>
);

const CustomCreate = ({ staticContext, ...props }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const redirect = useRedirect();
    const [loading, setLoading] = useState(false);
    const [, setError] = useState(null);
    const defaultValues = () => ({ codiceScuola: null });
    const [schools, setSchools] = useState([] as any[]);
    const [selectedSchool, setSelectedSchool] = useState<any>(null);
    const [checkedClass, setCheckedClass] = useState([] as any);

    const search = useCallback(
        async (values) => {
            setLoading(true);

            dataProvider.get(`api/miur/searchSchoolByCode/${values.codiceScuola}`)
                .then(async ({ data }) => {
                    if (data && data.length > 0) {
                        setSchools(data)
                    }
                    else {
                        notify(`${values.codiceScuola} NON TROVATO`, { type: 'warning' })
                        setSchools([])
                    }
                })
                .catch(error => {
                    console.error(error);
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                })
        }, []);

    const handleClick = () => {
        if (selectedSchool && checkedClass.length > 0) {
            setLoading(true);

            dataProvider
                .create('miurschools', {
                    data: {
                        codiceScuola: selectedSchool.codiceScuola,
                        classi: checkedClass.join(",")
                    }
                })
                .then((response) => {
                    const { data } = response || { data: null };
                    if (data) {
                        redirect('list', 'miurschools');
                    }
                    else {
                        notify(`Scuola '${selectedSchool.codiceScuola}' non aggiunta/aggiornata`, { type: 'warning' })
                        setSchools([])
                    }
                })
                .catch(error => {
                    console.error(error);
                    notify(error.message, { type: 'error' })
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    }

    const handleToggle = (miurYearCourse: any) => () => {
        const className = mapSchoolClassInfo(miurYearCourse);

        const currentIndex = checkedClass.indexOf(className);
        const newChecked = [...checkedClass];

        if (currentIndex === -1) {
            newChecked.push(className);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedClass(newChecked);
    };

    if (loading) return <Loading loadingPrimary=' ' loadingSecondary=' ' />

    if (schools.length > 0) {
        return (
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid container item xs={12} sm={4} display='flex' direction='column' justifyContent="center" alignItems="center">

                    {
                        selectedSchool === null ?
                            <>
                                <Typography variant="h4" display="inline">
                                    {translate("miurschools.other.found", { smart_count: schools.length })}
                                </Typography>
                                <Divider flexItem />
                                <List>
                                    {
                                        schools.map(school =>
                                            <ListItem key={school.codiceScuola}>
                                                <ListItemButton role={undefined} onClick={() => setSelectedSchool(school)} dense>
                                                    <ListItemText primary={school.denominazioneScuola} />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    }
                                </List>
                            </> :
                            <>
                                <Typography variant="h4" display="inline">
                                    {selectedSchool.denominazioneScuola}
                                    <IconButton onClick={() => setSelectedSchool(null)}>
                                        <BackIcon fontSize='large' />
                                    </IconButton>
                                </Typography>
                                <Divider flexItem />
                                <Grid container spacing={1} justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
                                    {
                                        selectedSchool.miurYearCourses.map(miurYearCourse =>
                                            <Grid>
                                                <ListItemButton
                                                    key={mapSchoolClassInfo(miurYearCourse)}
                                                    role={undefined}
                                                    onClick={handleToggle(miurYearCourse)}
                                                >
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checkedClass.indexOf(mapSchoolClassInfo(miurYearCourse)) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': mapSchoolClassInfo(miurYearCourse) }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={mapSchoolClassInfo(miurYearCourse)} />
                                                </ListItemButton>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                <CustomConfirmButton
                                    disabled={checkedClass.length === 0}
                                    label={'ra.action.confirm'}
                                    title={'miurschools.action.confirmDialogImport.title'}
                                    content={'miurschools.action.confirmDialogImport.content'}
                                    translateOptions={{ nomeScuola: selectedSchool.denominazioneScuola }}
                                    onConfirm={handleClick}
                                    icon={<Icon color='success' sx={{ mt: 2, fontSize: '40px !important' }} />}
                                />
                            </>

                    }
                    <IconButton onClick={() => setSchools([])} sx={{ mt: 5 }}>
                        <ClearIcon fontSize='large' />
                    </IconButton>
                </Grid>
            </Grid>
        )
    }
    else {
        return (
            <Create title="" resource="miurschools" actions={false} className="w-100" {...props}>
                <SimpleForm toolbar={<CustomToolbar />} onSubmit={search} defaultValues={defaultValues} >
                    <TextInput source="codiceScuola" fullWidth formClassName="col-12" />
                </SimpleForm>
            </Create>
        );
    }
};

export default CustomCreate;