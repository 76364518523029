import { Grid } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    DateField,
    BooleanField,
    EmailField,
    ImageField,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    useRecordContext,
    useResourceContext,
} from 'react-admin';
import { User } from 'admin/types';

const CustomShowExpand = () => {
    const record = useRecordContext<User>();
    const resource = useResourceContext();

    if (!record) return null;
    return (
        <Show
            resource={resource}
            id={record.id}
            title=" "
            actions={false}>
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="firstName">
                            <TextField source="firstName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="lastName">
                            <TextField source="lastName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="userName">
                            <TextField source="userName" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="email">
                            <EmailField source="email" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="phoneNumber">
                            <DateField source="phoneNumber" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="providerType">
                            <ReferenceField source="providerType" reference="api/enums/providerTypes" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="profilePhoto">
                            <ImageField source="profilePhoto.src" label="resources.users.fields.profilePhoto" title="profilePhoto.title" sx={{ '& img': { width: 'auto !important', height: '40px !important', margin: ' 0 !important' } }} />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="changePassword">
                            <BooleanField source="changePassword" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="defaultCulture">
                            <TextField source="defaultCulture" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="status">
                            <ReferenceField source="status" reference="api/enums/userStatus" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Labeled>
                            <ReferenceArrayField source="rolesIds" reference="roles" >
                                <SingleFieldList linkType={false}>
                                    <ChipField source="name" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShowExpand;