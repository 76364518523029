import { useMediaQuery, Theme } from '@mui/material';

import GoogleIcon from '@mui/icons-material/Google';
import LocalIcon from '@mui/icons-material/Email';
import UnknownIcon from '@mui/icons-material/DeviceUnknown';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    EmailField,
    ImageField,
    FunctionField,
    SearchInput,
    ChipField,
    useGetIdentity,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

import {
    EditButton,
    ShowButton,
    ListActions,
    ListBulkActions,
    ListRowActions,
    SoftDeleteWithConfirmButton,
    IncludeIsDeleted
} from 'admin/components';

import CustomShowExpand from './CustomShowExpand';
import { ResetPasswordButton } from 'admin/views/Common';

const filters = [
    <SearchInput source="q" alwaysOn />,
    <IncludeIsDeleted source="isDeleted" alwaysOn />,
    <ReferenceInput source="status" reference="api/enums/userStatus">
        <SelectInput optionText="value" fullWidth />
    </ReferenceInput>
];

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const { data: identity, isLoading } = useGetIdentity();

    return (
        <List
            actions={<ListActions />}
            filters={filters}
            filterDefaultValues={{ isDeleted: false, }}
            sort={{ field: 'firstName', order: 'ASC' }}
            perPage={25}
            storeKey={false}
        >
            {isSmall ? (
                <SimpleList
                    primaryText="%{displayName}"
                    secondaryText={<ChipField component="span" source="roleNames" size='small' />}
                    tertiaryText="%{email}"
                    linkType="edit"
                />
            ) : (
                <Datagrid bulkActionButtons={<ListBulkActions />} expand={<CustomShowExpand />} optimized sx={{ '& .column-profilePhotoUrl': { padding: ' 0 !important', textAlign: 'center' } }}>
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    {/* <TextField source="userName" /> */}
                    <EmailField source="email" />
                    <DateField source="phoneNumber" />
                    <FunctionField label="resources.users.fields.providerType" textAlign="center" render={record => {
                        switch (record.providerType) {
                            case "Google":
                                return <GoogleIcon />
                            case "Local":
                                return <LocalIcon />
                            default:
                                return <UnknownIcon />
                        }
                    }} />
                    <ImageField source="profilePhotoUrl" sx={{ '& img': { width: 'auto !important', height: '30px !important', margin: ' 0 !important' } }} />
                    <BooleanField source="changePassword" textAlign="center" />
                    <TextField source="status" />
                    <ListRowActions>
                        <ResetPasswordButton emailFn={record => record.email} />
                        <EditButton disabledFn={record => isLoading || record.deletedAt !== null} />
                        <SoftDeleteWithConfirmButton disabledFn={record => isLoading || record.identityId === identity?.identityId} toRestoreFn={record => record.deletedAt !== null} />
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
