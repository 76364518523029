export default {
    name: "miuryearcourses",
    resources: {
        name: 'Classe |||| Classi',
        forcedCaseName: 'Classe |||| Classi',
        fields: {
            tipoGradoScuola: "Tipo Grado Scuola",
            annoCorso: "Classe",
            sezioneAnno: "Sezione",
            combinazione: "Combinazione",
            annoScolastico: "Anno Scolastico",
            fullClassName: "Classe",
            schoolName: "Scuola",
            miurSchoolsId: "Scuola",
        },
    },
    global: {
        menu: {
            name: 'Classi',
        },
        list: {
        },
        form: {
        },
        edit: {
            title: 'Classe',
        },
        action: {
        },
    },
};