import * as React from 'react';

import { Grid } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    NumberField,
    DateField,
} from 'react-admin';

import { ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';

const CustomShow = props => {
    return (
        <Show {...props} actions={<ShowActions hasEdit={false} />} title={<CustomTitle />}>
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="codiceIsbn">
                            <TextField source="codiceIsbn" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="autori">
                            <TextField source="autori" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="titolo">
                            <TextField source="titolo" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="collana">
                            <TextField source="collana" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="anno">
                            <DateField source="anno" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="dataPubblicazione">
                            <TextField source="dataPubblicazione" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="lingua">
                            <TextField source="lingua" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="prezzo">
                            <NumberField source="prezzo" options={{
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 2
                            }} />
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;
