export default {
    name: "miuradoptions",
    resources: {
        name: 'Adozione |||| Adozioni',
        forcedCaseName: 'Adozione |||| Adozioni',
        fields: {

            codiceIsbn: "Codice Isbn",
            autori: "Autori",
            titolo: "Titolo",
            sottotitolo: "Sottotitolo",
            volume: "Volume",
            editore: "Editore",
            prezzo: "Prezzo",
            nuovaAdoz: "N.Ad.",
            daAcquist: "Acq.",
            consigliato: "Cons.",

            miurDisciplinesId: "Disciplina",
        },
        groups: {
        },
    },
    global: {
        menu: {
            name: 'Adozioni',
        },
        list: {
        },
        form: {
        },
        validation: {
        },
        edit: {
            title: 'Adozione "%{title}"',
        },
        action: {
        },
        tabs: {
        },
        groups: {
        },
        other: {
            dialogTitle: 'Adozioni classe %{title}',
            noAdoptions: 'Nessuna adozione presente',
            adoptionsCount: 'Presente 1 adozione |||| Presenti %{smart_count} adozioni',
        },
        status: {
        },
    },
};