import { useRef } from 'react';

import {
    useTranslate,
    useDataProvider,
    useNotify,
    SimpleForm,
    TextInput,
    required,
    NumberInput,
    ArrayInput,
    SimpleFormIterator,
    DateInput,
    useRedirect,
} from 'react-admin';

import { CheckboxInput, CreateDialogButton } from 'admin/components';

import Icon from '@mui/icons-material/Book';
import { CreateDialogButtonActions } from 'admin/components/layout/Dialogs/Create/CreateDialogButton';
import { mapSchoolClassInfo, mapStudentDisplayName } from 'admin/mappers';


export interface Props {
    miurYearCourse: any;
}

const ClassBooking = (props: Props) => {
    const {
        miurYearCourse,
    } = props;

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const dialogRef = useRef<CreateDialogButtonActions>(null);

    const translate = useTranslate();

    const onSave = async (data) => {
        const created: string[] = [];
        const notCreated: string[] = [];

        for (const student of (data.students || [])) {
            student.miurYearCoursesId = miurYearCourse.id;

            const response = await dataProvider.create('students', { data: student, }).catch(error => {
                console.error(error);
                notCreated.push(`'${mapStudentDisplayName(data)}'`);
            });

            const { data } = response || { data: null };

            if (data) {
                created.push(`'${mapStudentDisplayName(data)}'`);
            }
        }

        if (created.length > 0) {
            notify(`Prenotazione per ${created.join(',')} creata`, { type: 'success' });
        }

        if (notCreated.length > 0) {
            setTimeout(() => {
                notify(`Prenotazione per ${notCreated.join(',')} non creata`, { type: 'error' });
            }, 3000);
        }

        dialogRef.current?.close();

        setTimeout(() => {
            if (created.length > 0) {
                redirect('list', 'bookings');
            }
        }, 2000);
    };

    return (
        <CreateDialogButton
            ref={dialogRef}
            resource='students'
            icon={<Icon />}
            label='miurschools.other.classBooking'
            dialogProps={{
                title: translate("miurschools.other.classBookingDialogTitle", { className: mapSchoolClassInfo(miurYearCourse) }),
                fullScreen: true,
                preventClose: true,
                showRefresh: false,
            }}
        >
            <SimpleForm onSubmit={onSave} defaultValues={{ miurYearCoursesId: miurYearCourse.id, students: [] }}>
                <ArrayInput label="students.menu.name" source="students">
                    <SimpleFormIterator inline>
                        <TextInput label="resources.students.fields.givenName" source="givenName" validate={required()} />
                        <TextInput label="resources.students.fields.familyName" source="familyName" validate={required()} />
                        <TextInput label="resources.students.fields.parentReference" source="parentReference" />
                        <NumberInput label="resources.students.fields.voucherAmount" source="voucherAmount" min={0} />
                        <TextInput label="resources.students.fields.fiscalCode" source="fiscalCode" />
                        <TextInput label="resources.students.fields.phoneNumber" source="phoneNumber" />
                        <TextInput label="resources.students.fields.email" source="email" />
                        <TextInput label="resources.students.fields.note" source="note" />
                        <DateInput label="resources.bookings.fields.orderDate" source="orderDate" defaultValue={new Date()} />
                        <NumberInput label="resources.bookings.fields.advanceAmount" source="advanceAmount" min={0} defaultValue={0} />
                        <CheckboxInput label="resources.bookings.fields.religion" source="religion" labelOptions={{ labelPlacement: "start" }} defaultValue={true} />
                        <CheckboxInput label="resources.bookings.fields.covered" source="covered" labelOptions={{ labelPlacement: "start" }} defaultValue={true} />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </CreateDialogButton>
    );
};

export default ClassBooking;