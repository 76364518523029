import { Theme, useMediaQuery } from '@mui/material';

import { Link } from '@mui/material';

import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    SearchInput,
    FunctionField,
} from 'react-admin';

import {
    ShowButton,
    ListActions,
    ListRowActions,
    EditButton,
    DeleteWithConfirmButton,
} from 'admin/components';

import CustomShowExpand from './CustomShowExpand';
import { mapStudentDisplayName } from 'admin/mappers';

const filters = [
    <SearchInput source="q" alwaysOn />
];

const CustomList = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const expand = [
        'studentMiurYearCourses.miurYearCourses.miurSchools'
    ];
    return (
        <List
            actions={<ListActions />}
            filters={filters}
            filterDefaultValues={{}}
            sort={{ field: 'familyName', order: 'ASC' }}
            queryOptions={{
                meta: { expand: expand }
            }}
            perPage={25}
            storeKey={false}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => mapStudentDisplayName(record)}
                    secondaryText={record => record.fiscalCode}
                    tertiaryText={record => record.phoneNumber}
                    linkType="edit"
                />
            ) : (
                <Datagrid bulkActionButtons={false} expand={<CustomShowExpand />} expandSingle isRowExpandable={row => row.note || row.studentMiurYearCourses?.length > 0} optimized>
                    <TextField source="familyName" />
                    <TextField source="givenName" />
                    <TextField source="fiscalCode" />
                    <TextField source="phoneNumber" />
                    <FunctionField source="email" textAlign='center' render={record => record.email && <Link href={`mailto:${record.email}`}>{record.email}</Link>} />
                    <ListRowActions>
                        <EditButton />
                        <DeleteWithConfirmButton disabledFn={(record) => record.bookingsId !== null} />
                        <ShowButton />
                    </ListRowActions>
                </Datagrid>
            )}
        </List>
    );
};

export default CustomList;
