import settingsIcon from '@mui/icons-material/Settings';
import systemIcon from '@mui/icons-material/Settings';
import tablesMIUR from '@mui/icons-material/AccountBalance';

import { green } from '@mui/material/colors';

const groups = {
    settings: { id: "settings", name: "pos.menu.settings", icon: settingsIcon },
    system: { id: "system", name: "pos.menu.system", icon: systemIcon },
    tablesMIUR: {
        id: "tablesMIUR",
        name: "pos.menu.tablesMIUR",
        icon: tablesMIUR,
        iconColor: green[800]
    }
}

export default groups;
