import * as React from 'react';

import { Grid } from '@mui/material';

import {
    Show,
    SimpleShowLayout,
    Labeled,
    TextField,
    NumberField,
    ReferenceField,
} from 'react-admin';

import { ShowActions } from 'admin/components';

import CustomTitle from './CustomTitle';

const CustomShow = props => {
    return (
        <Show {...props} actions={<ShowActions hasEdit={false} />} title={<CustomTitle />}>
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="miurDisciplinesId">
                            <ReferenceField source="miurDisciplinesId" reference="miurDisciplines" link={false}>
                                <TextField source="disciplina" />
                            </ReferenceField>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="codiceIsbn">
                            <TextField source="codiceIsbn" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="autori">
                            <TextField source="autori" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="titolo">
                            <TextField source="titolo" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="sottotitolo">
                            <TextField source="sottotitolo" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="volume">
                            <TextField source="volume" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="editore">
                            <TextField source="editore" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="volume">
                            <TextField source="volume" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Labeled source="prezzo">
                            <NumberField source="prezzo" options={{
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 2
                            }} />
                        </Labeled>
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};

export default CustomShow;
