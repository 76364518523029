export default {
    name: "miurschools",
    resources: {
        name: 'Scuola |||| Scuole',
        forcedCaseName: 'Scuola |||| Scuole',
        fields: {
            annoScolastico: 'Anno Scolastico',
            areaGeografica: 'Area Geografica',
            regione: 'Regione',
            provincia: 'Provincia',
            codiceIstitutoRiferimento: 'Codice Istituto Riferimento',
            denominazioneIstitutoRiferimento: 'Denominazione Istituto Riferimento',
            codiceScuola: 'Codice',
            denominazioneScuola: 'Nome Scuola',
            indirizzoScuola: 'Indirizzo',
            capScuola: 'Cap',
            codiceComuneScuola: 'Codice Comune',
            descrizioneComune: 'Comune',
            descrizioneCaratteristicaScuola: 'Caratteristica',
            descrizioneTipologiaGradoIstruzioneScuola: 'Grado Istruzione',
            indicazioneSedeDirettivo: 'Sede Direttivo',
            indicazioneSedeOmnicomprensivo: 'Sede Omnicomprensivo',
            indirizzoEmailScuola: 'Email',
            indirizzoPecScuola: 'Pec',
            sitoWebScuola: 'Sito Web',
            sedeScolastica: 'Sede Scolastica',
            favorite: 'Preferito',
            
            miurYearCourses: "Corsi Anno",
        },
        groups: {
        },
    },
    global: {
        menu: {
            name: 'Scuole',
        },
        list: {
        },
        form: {
        },
        validation: {
        },
        edit: {
            title: 'Scuola "%{title}"',
        },
        action: {
            importTitle: 'Importa scuole',
            importContent: 'I dati delle scuole già esistenti verranno sovrascritti',
            confirmDialogImport: {
                title: 'Importa scuola \'%{nomeScuola}\'',
                content: 'I dati delle scuola se già esistenti verranno sovrascritti'
            },
        },
        groups: {
        },
        other: {
            found: 'Trovata 1 scuola |||| Trovate %{smart_count} scuole',
            classBooking: 'Prenota per classe',
            classBookingDialogTitle: 'Prenotazione per classe %{className}',
        },
    },
};